const base = "api/seminars";

export const seminars = (instance) => ({
    async getSeminarsMy(payload) {
        const { data } = await instance.get(`${base}/my?${payload?.query || ""}`);

        return data;
    },
    async getSeminarsMyAvailableToInstructor(instructorId) {
        const { data } = await instance.get(`${base}//available-invite/${instructorId}`);

        return data;
    },
    async getSeminarsById(seminarId) {
        const { data } = await instance.get(`${base}/${seminarId}`);

        return data;
    },
    async createSeminar(payload) {
        const { data } = await instance.post(`${base}`, payload);

        return data;
    },
    async updateSeminar(payload) {
        const { data } = await instance.put(`${base}/${payload.seminarId}`, payload.body);

        return data;
    },
    async uploadSeminarPhotos({ body, seminarId }) {
        const { data } = await instance.post(`${base}/photos/${seminarId}`, body);

        return data;
    },
    async deleteSeminarPhoto(photoId) {
        const { data } = await instance.delete(`${base}/photos/${photoId}`);

        return data;
    },
    async addDatesToSeminar({ seminarId, body }) {
        const { data } = await instance.post(`${base}/dates/${seminarId}`, body);

        return data;
    },
    async updateSeminarDate({ dateId, body }) {
        const { data } = await instance.put(`${base}/dates/${dateId}`, body);

        return data;
    },
    async deleteSeminarDate({ dateId, body }) {
        const { data } = await instance.delete(`${base}/dates/${dateId}`, body);

        return data;
    },
    async getAllSeminarsWithFilters(qwery) {
        const { data } = await instance.get(`${base}?${qwery}`);

        return data;
    },
    async sendSeminarRequest({ seminarId }) {
        const { data } = await instance.get(`${base}/request-hosting/${seminarId}`);

        return data;
    },
    async sendSeminarRequestToInstructor({ seminarId, instructorId }) {
        const { data } = await instance.get(`${base}/instructor-invite/${seminarId}/${instructorId}`);

        return data;
    },
    async sendSeminarRequestByInstructor({ seminarId }) {
        const { data } = await instance.get(`${base}/instructor-join/${seminarId}`);

        return data;
    },
    async acceptSeminarRequest({ seminarId, requestId, body = null }) {
        const { data } = await instance.post(`${base}/request-accept/${seminarId}/${requestId}`, body);

        return data;
    },
    async acceptInstructorRequest({ requestInstructorId }) {
        const { data } = await instance.get(`${base}/instructor-accept/${requestInstructorId}`);

        return data;
    },
    async cancelSeminarRequest({ requestId }) {
        const { data } = await instance.get(`${base}/instructor-reject/${requestId}`);

        return data;
    },
    async getSeminarContract({ seminarId }) {
        const { data } = await instance.get(`${base}/contract/${seminarId}`);

        return data;
    },
    async deactivateSeminar({ seminarId }) {
        const { data } = await instance.get(`${base}/deactivate/${seminarId}`);

        return data;
    },
    async getActiveSeminarsByInstructorId({ instructorId }) {
        const { data } = await instance.get(`${base}/by-instructor/${instructorId}`);

        return data;
    }

});
