import React from "react";
const includs = [
    {
        id: 1,
        label: "3 Meals a Day",
        imgSrc: require("../../../assets/img/included-decor-1.svg").default
    },
    {
        id: 2,
        label: "Parties",
        imgSrc: require("../../../assets/img/included-decor-2.svg").default
    },
    {
        id: 3,
        label: "Seminars",
        imgSrc: require("../../../assets/img/included-decor-3.svg").default
    },
    {
        id: 4,
        label: "Family Friendly",
        imgSrc: require("../../../assets/img/included-decor-4.svg").default
    },
    {
        id: 5,
        label: "Fitness",
        imgSrc: require("../../../assets/img/included-decor-5.svg").default
    },
    {
        id: 6,
        label: "Yoga",
        imgSrc: require("../../../assets/img/included-decor-6.svg").default
    },
    {
        id: 7,
        label: "Theaters",
        imgSrc: require("../../../assets/img/included-decor-7.svg").default
    },
    {
        id: 8,
        label: "Disco",
        imgSrc: require("../../../assets/img/included-decor-8.svg").default
    },
    {
        id: 9,
        label: "Kids Club",
        imgSrc: require("../../../assets/img/included-decor-9.svg").default
    }
];

const WhatsIncluded = ({ data = [] }) => {
    return (
        <div className="border-bottom mb-42 pb-42">
            <h4 className="heading mb-32">
                Whats Included
            </h4>

            {
                data?.length
                    ? (
                        <ul className="row gx-32 gy-28">
                            {
                                data.map((item, index) => (
                                    <li
                                        className="col-md-4"
                                        key={index}
                                    >
                                        <div className="flex items-center gap-12">
                                            <div className="ico ico-24">
                                                <img
                                                    src={includs.find((inculud) => inculud.id === item?.id)?.imgSrc}
                                                    alt={data?.name}
                                                />
                                            </div>

                                            <span className="color-grey">
                                                {item?.name}
                                            </span>
                                        </div>
                                    </li>
                                )



                                )
                            }

                        </ul>
                    )


                    : (
                        <p>
                            Nothing Included
                        </p>
                    )


            }
        </div>
    );
};

export default WhatsIncluded;
