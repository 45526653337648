import React, { useState } from "react";
import Accordeon from "../ui/accordeons/Accordeon";
import Button from "../ui/buttons/Button";

const FQA = ({ blockData }) => {
    const [showMore, setShowMore] = useState(false);
    const fqaData = blockData?.blockPresets?.[1]?.value.data || [
        {
            title: "How do I book a seminar as an academy?",
            description: (
                <ul className="text-list-decimal">
                    <li>
                        Explore Seminar Options: Begin by reviewing the available seminar topics and instructors. Consider what aligns best with your academy's goals and the interests of your students.
                    </li>

                    <li>
                        Contact the Organizer: Use the provided contact form or details on our website to reach out to our seminar coordinator. Include your academy's name, location, and preferred seminar topics or instructors.
                    </li>

                    <li>
                        Discuss Details: Our coordinator will respond to your inquiry to discuss available dates, instructor availability, and specific requirements or customizations you need for your seminar.
                    </li>

                    <li>
                        Confirm the Booking: Once all details are agreed upon, including date, time, instructor, and any specific content requests, you will receive a booking confirmation. This might include a contract outlining the terms of the seminar, costs, and cancellation policies.
                    </li>
                </ul>
            )
        }, {
            title: "How do i get my tickets?",
            description: (
                <ul className="text-list-decimal">
                    <li>
                        Explore Seminar Options: Begin by reviewing the available seminar topics and instructors. Consider what aligns best with your academy's goals and the interests of your students.
                    </li>

                    <li>
                        Contact the Organizer: Use the provided contact form or details on our website to reach out to our seminar coordinator. Include your academy's name, location, and preferred seminar topics or instructors.
                    </li>

                    <li>
                        Discuss Details: Our coordinator will respond to your inquiry to discuss available dates, instructor availability, and specific requirements or customizations you need for your seminar.
                    </li>

                    <li>
                        Confirm the Booking: Once all details are agreed upon, including date, time, instructor, and any specific content requests, you will receive a booking confirmation. This might include a contract outlining the terms of the seminar, costs, and cancellation policies.
                    </li>
                </ul>
            )
        }, {
            title: "How do instructors get on the site?",
            description: (
                <ul className="text-list-decimal">
                    <li>
                        Explore Seminar Options: Begin by reviewing the available seminar topics and instructors. Consider what aligns best with your academy's goals and the interests of your students.
                    </li>

                    <li>
                        Contact the Organizer: Use the provided contact form or details on our website to reach out to our seminar coordinator. Include your academy's name, location, and preferred seminar topics or instructors.
                    </li>

                    <li>
                        Discuss Details: Our coordinator will respond to your inquiry to discuss available dates, instructor availability, and specific requirements or customizations you need for your seminar.
                    </li>

                    <li>
                        Confirm the Booking: Once all details are agreed upon, including date, time, instructor, and any specific content requests, you will receive a booking confirmation. This might include a contract outlining the terms of the seminar, costs, and cancellation policies.
                    </li>
                </ul>
            )
        }, {
            title: "How do I book a seminar as an academy?",
            description: (
                <ul className="text-list-decimal">
                    <li>
                        Explore Seminar Options: Begin by reviewing the available seminar topics and instructors. Consider what aligns best with your academy's goals and the interests of your students.
                    </li>

                    <li>
                        Contact the Organizer: Use the provided contact form or details on our website to reach out to our seminar coordinator. Include your academy's name, location, and preferred seminar topics or instructors.
                    </li>

                    <li>
                        Discuss Details: Our coordinator will respond to your inquiry to discuss available dates, instructor availability, and specific requirements or customizations you need for your seminar.
                    </li>

                    <li>
                        Confirm the Booking: Once all details are agreed upon, including date, time, instructor, and any specific content requests, you will receive a booking confirmation. This might include a contract outlining the terms of the seminar, costs, and cancellation policies.
                    </li>
                </ul>
            )
        }
    ];
    const filteredData = showMore
        ? fqaData
        : fqaData.slice(0, 7);
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index
            ? null
            : index);
    };

    return (
        <section className="section faq">
            <div className="container">
                <h2
                    className="heading text-center mb-44"
                    dangerouslySetInnerHTML={{ __html: blockData?.blockPresets?.[0]?.value || "frequently asked questions" }}
                />

                {filteredData.map((item, index) => (
                    <Accordeon
                        className="w-full"
                        index={index}
                        isActive={activeIndex === index}
                        key={index}
                        onClick={() => toggleAccordion(index)}
                        onlyOneIsOpen={true}
                        title={item.title}
                    >
                        {item?.content || item?.description}
                    </Accordeon>
                )



                )}

                {!showMore && (
                    <Button
                        className="btn btn--primary btn--md show-more__button"
                        onClick={() => setShowMore(true)}
                    >
                        <span className="info">
                            Show more
                        </span>
                    </Button>
                )}
            </div>

        </section>
    );
};

export default FQA;
