import React from "react";

const HotelsPart = ({ data = [] }) => {
    return (
        <div className="border-bottom mb-42 pb-42">
            <h4 className="heading mb-32">
                Hotels Nearby
            </h4>

            {
                data?.length
                    ? data.map((item, index) => (
                        <a
                            key={index}
                            href={item}
                            className="color-secondary text--lg underline"
                        >
                            {item}
                        </a>
                    )



                    )
                    : (
                        <p>
                            No Hotels Nearby
                        </p>
                    )


            }
        </div>
    );
};

export default HotelsPart;
