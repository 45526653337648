import React, {
    useEffect, useRef, useState
} from "react";
import {
    Swiper, SwiperSlide
} from "swiper/react";
import {
    Navigation, Pagination
} from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import CardSeminar from "./cards/CardSeminar";
import api from "../../api";
import { Link } from "react-router-dom";

const SeminarsSliderSection = ({ sectionTitle }) => {
    const nextRef = useRef(null);
    const prevRef = useRef(null);
    const [seminars, setSeminars] = useState([]);

    useEffect(() => {
        api.seminars.getAllSeminarsWithFilters("status=active").then((res) => {
            setSeminars(res.data);
        });
    }, []);

    return (
        <section className="section">
            <div className="container">
                <div className="flex items-center justify-between flex-wrap mb-60 max-md-mb-40">
                    <h2 className="heading max-sm-text-center max-sm-w-full">
                        {sectionTitle}
                    </h2>

                    <div className=" items-center gap-12 hidden sm-flex">
                        <div className="swiper-btns-group">
                            <div
                                className="swiper-btn swiper-arrow-prev"
                                ref={prevRef}
                            >
                                <svg
                                    fill="none"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    width="20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g>
                                        <path
                                            d="M7.5 3.75L13.75 10L7.5 16.25"
                                            stroke="currentColor"
                                            strokeLinecap="square"
                                            strokeWidth="1.2"
                                        />
                                    </g>
                                </svg>
                            </div>

                            <div
                                className="swiper-btn swiper-arrow-next"
                                ref={nextRef}
                            >
                                <svg
                                    fill="none"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    width="20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g>
                                        <path
                                            d="M7.5 3.75L13.75 10L7.5 16.25"
                                            stroke="currentColor"
                                            strokeLinecap="square"
                                            strokeWidth="1.2"
                                        />
                                    </g>
                                </svg>
                            </div>
                        </div>
                        {
                            !!seminars?.length && (
                                <Link
                                    className="btn btn--md"
                                    to="/seminars"
                                >
                                    Show All
                                </Link>
                            )
                        }
                    </div>
                </div>
            </div>

            <div className="swiper-upcoming-seminars">
                <Swiper
                    breakpoints={{
                        320: {
                            slidesPerView: 1.15,
                            spaceBetween: 16,
                            centeredSlides: true
                        },
                        520: {
                            slidesPerView: 1.5,
                            spaceBetween: 16,
                            centeredSlides: false
                        },
                        600: {
                            slidesPerView: 1.7,
                            spaceBetween: 16
                        },
                        768: {
                            slidesPerView: 2.2,
                            spaceBetween: 16
                        },
                        1024: {
                            slidesPerView: 2.8,
                            spaceBetween: 24
                        },
                        1200: {
                            slidesPerView: 3.3,
                            spaceBetween: 24
                        }
                    }}
                    className="swiper-current-seminars"
                    modules={[Navigation, Pagination]}
                    navigation={{
                        nextEl: ".swiper-arrow-next",
                        prevEl: ".swiper-arrow-prev"
                    }}
                    pagination={{ clickable: true }}
                >
                    {seminars.map((seminar) => (
                        <SwiperSlide key={seminar.id}>
                            <CardSeminar
                                // linkPrefix="/seminars/"
                                data={seminar}
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>

            {
                !!seminars?.length && (
                    <div className="container">
                        <Link
                            className="btn btn--md btn--secondary-100 w-full mt-40"
                            to="/seminars"
                        >
                            Show All
                        </Link>
                    </div>
                )}
        </section>
    );
};

export default SeminarsSliderSection;
