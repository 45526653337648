import { Country } from "country-state-city";
import {
    useEffect, useState
} from "react";
import {
    useDispatch, useSelector
} from "react-redux";
import {
    Link, useNavigate, useParams
} from "react-router-dom";
import { ReactComponent as LocationIcon } from "../../assets/img/icon-decor-city.svg";
import LoginForm from "../../components/parts/LoginForm";
import Button from "../../components/ui/buttons/Button";
import CheckboxSwitch from "../../components/ui/formElements/CheckboxSwitch";
import Input from "../../components/ui/formElements/Input";
import SelectOne from "../../components/ui/formElements/SelectOne";
import PageLoader from "../../components/ui/PageLoader";
import { belts, trainingStyle as trainingStyles } from "../../constants";
import {
    checkErrors, getDateRange, getImageSrc
} from "../../helpers/utils";
import { getUser } from "../../redux/slices/applicationSlice";
import { getSingleCampFetch } from "../../redux/thunks/planCampThuncks";
import { buyTicketFetch } from "../../redux/thunks/ticketsThunks";

const BuyCampPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isBuying, setIsBuying] = useState(false)
    const [agree, setAgree] = useState(true);
    const { campId, chatId, requestId } = useParams();
    const user = useSelector(getUser);
    const { campDetails, isLoading } = useSelector((state) => state.planCamps);
    const [formData, setFormData] = useState({
        fullName: "",
        email: "",
        belt: ""
    });
    const [errors, setErrors] = useState({});
    const [isErrorsShown, setIsErrorsShown] = useState(false);
    const specificBeltOptions = belts.map(({ key, title }) => ({
        value: key,
        label: title
    }));

    const handleChange = (name, value) => {
        setFormData((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = async () => {
        setIsErrorsShown(true);
        console.log(errors);

        if (errors?.hasRequiredError) return;

        setIsBuying(true)
        const res = await dispatch(buyTicketFetch({
            eventId: campId,
            eventType: "plancamp",
            fullName: formData.fullName,
            email: formData.email,
            belt: formData.belt
        })).unwrap()
        const paymentUrl = res?.data?.session?.url

        if (paymentUrl) {
            window.location.href = paymentUrl
        }

        setTimeout(() => setIsBuying(false), 100)
    };

    useEffect(() => {
        if (!campId) return;

        dispatch(getSingleCampFetch(campId));
    }, [campId]);

    useEffect(() => {
        setErrors(checkErrors(formData, {}));
    }, [formData]);

    useEffect(() => {
        setFormData({
            fullName: user?.fullName || user?.profile?.fullName || "",
            email: user?.email || "",
            belt: user?.belt || ""
        });
    }, [user]);

    if (isLoading) {
        return <PageLoader />;
    }

    if (!campDetails) {
        return (
            <section className="section-42">
                <div className="container">
                    <h2 className="heading">
                        Camp is not found
                    </h2>
                </div>
            </section>

        );
    }

    return (
        <section className="section-42">
            <div className="container">
                <div className="double-section no-border flex gap-80">
                    <div className="double-section__content">
                        {
                            user
                                ? (
                                    <>
                                        <div className="col-group gap--sm">
                                            <div className="pill pill--secondary">
                                                Limited tickets left
                                            </div>

                                            <h6 className="heading">
                                                {campDetails.name}
                                            </h6>

                                            <p className="text--xs color-text">
                                                {getDateRange([campDetails.startAt, campDetails.endAt], true)?.split(",") || []}
                                            </p>
                                        </div>

                                        <hr className="hr-horisontal" />

                                        <div className="col-group gap-30">
                                            <h4 className="heading">
                                                {campDetails.name}
                                            </h4>

                                            <p className="color-text">
                                                {campDetails.summary}
                                            </p>

                                            <div className="row-group gap-30">
                                                <div className="price-group fw-700 ttu">
                                                    <span className="price-group__price">
                                                        $

                                                        {campDetails.minimalPriceTicket || 0}
                                                    </span>

                                                    {" "}

                                                    / ticket
                                                </div>

                                                <div className="price-group fw-700 ttu">
                                                    <span className="price-group__price">
                                                        $

                                                        {campDetails.minimalPriceTicket * campDetails?._ticketFee}
                                                    </span>

                                                    {" "}

                                                    / fee
                                                </div>
                                            </div>
                                        </div>

                                        <hr className="hr-horisontal" />

                                        <div className="col-group gap-30">
                                            <p className="color-text">
                                                Ticket and receipt are sent to the info below:
                                            </p>

                                            <div className="row gx-16 gutters-form">
                                                <div className="col-md-6 col-sm-6 col-12">
                                                    <Input
                                                        error={isErrorsShown && errors.fullName}
                                                        inputClasses="input--solid"
                                                        label="Full Name"
                                                        onChange={(e) => handleChange("fullName", e.target.value)}
                                                        placeholder="Type your full name..."
                                                        type="text"
                                                        value={formData.fullName}
                                                        wrapperClasses="form-group flex-auto input--lg"
                                                    />
                                                </div>

                                                <div className="col-md-6 col-sm-6 col-12">
                                                    <Input
                                                        error={isErrorsShown && errors.email}
                                                        inputClasses="input--solid"
                                                        label="Email"
                                                        onChange={(e) => handleChange("email", e.target.value)}
                                                        placeholder="Type your email..."
                                                        type="text"
                                                        value={formData.email}
                                                        wrapperClasses="form-group flex-auto input--lg"
                                                    />
                                                </div>

                                                <div className="col-md-6 col-sm-6 col-12">
                                                    <SelectOne
                                                        error={isErrorsShown && errors?.belt}
                                                        label="Belt Type"
                                                        onChange={(option) => handleChange("belt", option.value)}
                                                        options={specificBeltOptions}
                                                        placeholder="Select your belt"
                                                        value={formData.belt}
                                                        wrapperClasses="select--outline select--outline-bg input--lg w-full"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <hr className="hr-horisontal" />

                                        <div className="option-group">
                                            <CheckboxSwitch
                                                checked={agree}
                                                onChange={() => setAgree(!agree)}
                                                text="I want to receive newsletters"
                                            />
                                        </div>

                                        <div className="w-full text-center">
                                            <Button
                                                isLoading={isBuying}
                                                className="btn btn--primary btn--lg mb-20 w-full"
                                                onClick={handleSubmit}
                                            >
                                                <span className="info">
                                                    Continue
                                                </span>
                                            </Button>

                                            <p className="color-text text-14">
                                                When you click “Continue” you accept our

                                                {" "}

                                                <Link
                                                    className="color-secondary"
                                                    to="/terms"
                                                >
                                                    terms
                                                </Link>

                                                {" "}

                                                and

                                                {" "}

                                                <Link
                                                    className="color-secondary"
                                                    to="/privacy"
                                                >
                                                    privacy policy
                                                </Link>
                                            </p>
                                        </div>
                                    </>
                                )


                                : (
                                    <LoginForm
                                        wrapperClasses="form-login"
                                        description="Login or sign up to purchase on Global Seminars"
                                    />
                                )


                        }
                    </div>

                    <div className="double-section__aside">
                        <OrderSummary data={campDetails} />
                    </div>
                </div>
            </div>
        </section>
    );
};

function OrderSummary({ data }) {
    const user = useSelector(getUser);

    const getLocation = (data) => {
        if (!data?.location) {
            return "Location not specified";
        }

        const location = data?.location
            ? Country.getCountryByCode(data?.location)?.name
            : data?.location;

        return `${location}, ${data?.address} `;
    };

    const traningStyle = trainingStyles.find(({ title }) => title?.toLowerCase() === data?.traningStyle?.toLowerCase())?.title;

    return (
        <div className="card-shadow px-36 py-32 mb-16">
            <h6 className="heading mb-32">
                Order Summary
            </h6>

            <div className="row-group gap--xxl pb-24 border-bottom mb-24 flex-lg-nowrap flex-wrap">
                <div className="image-wrapper --medium-thumb">
                    <img
                        alt={data?.name}
                        src={getImageSrc(data?.photos?.find((photo) => photo.id === data.coverId)?.url)}
                    />
                </div>

                <div className="col-group gap--xxl justify-between">
                    {data.adress}

                    <div className="row-group color-secondary fw-500 text-13 gap-4">
                        <div className="ico">
                            <LocationIcon />
                        </div>

                        <span className="truncate">
                            {getLocation(data)}
                        </span>
                    </div>

                    <div className="seminar-label-info">
                        <h6 className="heading">
                            {data?.name}
                        </h6>
                    </div>
                </div>
            </div>

            <div className="ticket-info-body ticket-info-body--no-border mb-32">
                <div className="ticket-info-body__item justify-between text--lg">
                    <span className="color-text">
                        Date:
                    </span>

                    <span className="fw-500">
                        {getDateRange([data.startAt, data.endAt], true)?.split(",") || []}
                    </span>
                </div>

                <div className="ticket-info-body__item justify-between text--lg">
                    <span className="color-text">
                        Style:
                    </span>

                    <span className="fw-500">
                        {traningStyle}
                    </span>
                </div>

                <div className="ticket-info-body__item justify-between text--lg">
                    <span className="color-text">
                        Academy:
                    </span>

                    <span className="fw-500">
                        {data.userAcademy?.academyProfile?.name}
                    </span>
                </div>

                <div className="ticket-info-body__item justify-between text--lg">
                    <span className="color-text">
                        Instructors:
                    </span>

                    <span className="fw-500">
                        {data.instructors?.map((item) => item?.user?.instructorProfile?.fullName).join(", ")}
                    </span>
                </div>
            </div>

            {
                false && (
                    <Button className="btn--default btn--md w-full mb-32">
                        Apply Coupon
                    </Button>
                )}

            <hr className="hr-horisontal mb-32" />

            <div className="row-group justify-between gap--md">
                <div className="price-group">
                    <span className="color-grey mr-8">
                        Ticket
                    </span>

                    <span className="price-group__amount">
                        {(data?.maxGymCapacity || 0) - (data?.ticketsPurchased || 0)} left
                    </span>
                </div>

                <span className="price-group__price">
                    ${data?.minimalPriceTicket}
                </span>
            </div>
        </div>
    );
}

export default BuyCampPage;
