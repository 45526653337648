import React from "react";

const CheckboxEl = ({ wrapperClasses, defaultChecked, label, ...props }) => {
    return (
        <label className={`check-btn ${wrapperClasses}`}>
            <input
                defaultChecked={defaultChecked}
                hidden={true}
                type="checkbox"
                {...props}
            />

            <span className="check order-1" />

            {label && (
                <p className="check-text">
                    {label}
                </p>
            )}
        </label>
    );
};

export default CheckboxEl;
