import { useState } from "react";
import {
    Link, NavLink, useLocation
} from "react-router-dom";
import { getImageSrc } from "../../helpers/utils";
import PlanningModal from "../modals/PlanningModal";
import Button from "../ui/buttons/Button";
import DropDown from "../ui/DropDown";
import { useSelector } from "react-redux";
import { getUnreadMessages } from "../../redux/slices/chatsSlice";
import { getUnreadNotificationsCount } from "../../redux/slices/notificationsSlice";

export const UserPart = ({ handelLoginModal, handelSingUp, user, handleLogout }) => {
    const [planningModalOpen, setPlanningModalOpen] = useState(false);
    const { pathname } = useLocation();
    const unreadedMessages = useSelector(getUnreadMessages);
    const unreadedNotifications = useSelector(getUnreadNotificationsCount);
    const role = user?.role || "";
    const userIs = {
        instructor: user?.role === "instructor",
        academy: user?.role === "academy",
        student: user?.role === "student"
    };
    const canCreateSeminar = userIs.academy || userIs.instructor;
    const profileLink = userIs.instructor
        ? `/instructors/${user.id}`
        : userIs.academy
            ? `/academies/${user.id}`
            : "#";
    const buttonPaths = [
        "/",
        "/about-us",
        "instructors",
        "/seminars",
        "/camps",
        "/planed-by-insctructor",
        "/planed-by-academy",
        "/planed-by-insctructor/camps",
        "/planed-by-academy/camps"
    ];

    return (
        <>
            {!user && (
                <>
                    <Button
                        className="btn--md"
                        onClick={handelLoginModal}
                    >
                        Login
                    </Button>

                    <Button
                        className="btn--primary btn--md header__actions--desktop"
                        onClick={handelSingUp}
                    >
                        Sign Up
                    </Button>
                </>
            )}

            {user && (
                <>
                    {" "}

                    {
                        role && (
                            <>
                                <NavLink
                                    className="btn btn--square btn--md relative"
                                    to="/chat"
                                >
                                    <svg
                                        fill="none"
                                        height="22"
                                        viewBox="0 0 22 22"
                                        width="22"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <g clipPath="url(#clip0_131_215)">
                                            <path
                                                d="M8.25 9.625H13.75"
                                                stroke="currentColor"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="1.2"
                                            />

                                            <path
                                                d="M8.25 12.375H13.75"
                                                stroke="currentColor"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="1.2"
                                            />

                                            <path
                                                d="M6.86893 18.1423C8.60189 19.1452 10.6405 19.4837 12.6046 19.0947C14.5687 18.7056 16.3242 17.6155 17.544 16.0277C18.7637 14.4398 19.3644 12.4625 19.234 10.4645C19.1035 8.4665 18.2509 6.5841 16.8351 5.16829C15.4193 3.75247 13.5369 2.89987 11.5389 2.76944C9.54086 2.63901 7.5636 3.23966 5.97574 4.4594C4.38788 5.67915 3.29779 7.43472 2.90872 9.39883C2.51965 11.3629 2.85816 13.4015 3.86112 15.1345L2.78604 18.3442C2.74565 18.4654 2.73978 18.5953 2.76911 18.7196C2.79844 18.8439 2.8618 18.9576 2.9521 19.0479C3.04239 19.1382 3.15605 19.2015 3.28032 19.2308C3.4046 19.2602 3.5346 19.2543 3.65573 19.2139L6.86893 18.1423Z"
                                                stroke="currentColor"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="1.2"
                                            />
                                        </g>

                                        <defs>
                                            <clipPath id="clip0_131_215">
                                                <rect
                                                    fill="currentColor"
                                                    height="22"
                                                    width="22"
                                                />
                                            </clipPath>
                                        </defs>
                                    </svg>

                                    {unreadedMessages && unreadedMessages.length > 0 && <div className="chat-notification" />}
                                </NavLink>

                                <NavLink
                                    className="btn btn--square btn--md"
                                    to="/notifications"
                                >
                                    <svg
                                        fill="none"
                                        height="22"
                                        viewBox="0 0 22 22"
                                        width="22"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <g clipPath="url(#clip0_131_221)">
                                            <path
                                                d="M8.25 16.5C8.25 17.2293 8.53973 17.9288 9.05546 18.4445C9.57118 18.9603 10.2707 19.25 11 19.25C11.7293 19.25 12.4288 18.9603 12.9445 18.4445C13.4603 17.9288 13.75 17.2293 13.75 16.5"
                                                stroke="currentColor"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="1.2"
                                            />

                                            <path
                                                d="M4.81252 8.9375C4.81252 7.29647 5.46441 5.72266 6.62479 4.56228C7.78517 3.4019 9.35899 2.75 11 2.75C12.641 2.75 14.2149 3.4019 15.3752 4.56228C16.5356 5.72266 17.1875 7.29647 17.1875 8.9375C17.1875 12.0158 17.9008 14.4891 18.468 15.4688C18.5282 15.5731 18.56 15.6914 18.5601 15.8119C18.5602 15.9324 18.5286 16.0508 18.4686 16.1552C18.4085 16.2596 18.3221 16.3465 18.2179 16.407C18.1137 16.4675 17.9955 16.4996 17.875 16.5H4.12502C4.00469 16.4993 3.88665 16.467 3.78271 16.4063C3.67878 16.3457 3.59257 16.2589 3.53272 16.1545C3.47287 16.0501 3.44147 15.9318 3.44165 15.8115C3.44183 15.6911 3.4736 15.573 3.53376 15.4688C4.10009 14.4891 4.81252 12.0149 4.81252 8.9375Z"
                                                stroke="currentColor"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="1.2"
                                            />
                                        </g>

                                        <defs>
                                            <clipPath id="clip0_131_221">
                                                <rect
                                                    fill="currentColor"
                                                    height="22"
                                                    width="22"
                                                />
                                            </clipPath>
                                        </defs>
                                    </svg>

                                    {unreadedNotifications > 0 && <div className="chat-notification" />}

                                </NavLink>
                            </>
                        )}

                    {canCreateSeminar && (
                        <button
                            className="btn btn--md btn--primary ml-12 hidden lg-flex"
                            onClick={() => {
                                setPlanningModalOpen(true);
                            }}
                        >
                            <span>
                                Plan Seminar
                            </span>
                        </button>
                    )}

                    <DropDown
                        buttonClasses="header__avatar"
                        closeYourSelf={true}
                        dropDownClasses="hidden xl-flex"
                        dropDownListClasses="dropdown-menu"
                        labelButton={<img
                            alt={user?.profile?.fullName || "User"}
                            className="ico ico--md"
                            src={getImageSrc(user?.profile?.photo, require("../../assets/img/avatar-placeholder.svg").default)}
                            style={{
                                borderRadius: "50%",
                                overflow: "hidden",
                                background: "#F5F5F5"
                            }}
                        />}
                        position="right"
                    >
                        <div className="dropdown-menu__header">
                            <Link to={profileLink}>
                                <h6 className="heading">
                                    {user?.profile?.fullName || user?.profile?.name || user?.fullName || "User"}
                                </h6>
                            </Link>

                            <p className="desc-heading">
                                {user?.email || "your@email.com"}
                            </p>
                        </div>

                        {
                            role && role !== 'guest' && (
                                <div className="dropdown-menu__body">
                                    {canCreateSeminar && (
                                        <div className="dropdown-menu__body--item">
                                            <button
                                                className="btn btn--md btn--primary justify-between"
                                                onClick={() => {
                                                    setPlanningModalOpen(true);
                                                }}
                                            >
                                                <span>
                                                    Plan Seminar / Camp
                                                </span>

                                                <span className="ico">
                                                    <svg
                                                        fill="none"
                                                        height="14"
                                                        viewBox="0 0 14 14"
                                                        width="14"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M5.25 2.625L9.625 7L5.25 11.375"
                                                            stroke="#02020A"
                                                            strokeLinecap="square"
                                                            strokeWidth="1.6"
                                                        />
                                                    </svg>
                                                </span>
                                            </button>
                                        </div>
                                    )}

                                    <div className="dropdown-menu__body--item">
                                        <NavLink
                                            className="btn btn--md btn--thin"
                                            to="/my-seminars"
                                        >
                                            <span className="ico">
                                                <svg
                                                    fill="none"
                                                    height="22"
                                                    viewBox="0 0 22 22"
                                                    width="22"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        clipRule="evenodd"
                                                        d="M2.75 2.75H9.16667V9.16667H2.75V2.75Z"
                                                        fillRule="evenodd"
                                                        stroke="currentColor"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="1.2"
                                                    />

                                                    <path
                                                        clipRule="evenodd"
                                                        d="M12.8334 2.75H19.25V9.16667H12.8334V2.75Z"
                                                        fillRule="evenodd"
                                                        stroke="currentColor"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="1.2"
                                                    />

                                                    <path
                                                        clipRule="evenodd"
                                                        d="M2.75 12.833H9.16667V19.2497H2.75V12.833Z"
                                                        fillRule="evenodd"
                                                        stroke="currentColor"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="1.2"
                                                    />

                                                    <path
                                                        clipRule="evenodd"
                                                        d="M12.8334 12.833H19.25V19.2497H12.8334V12.833Z"
                                                        fillRule="evenodd"
                                                        stroke="currentColor"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="1.2"
                                                    />
                                                </svg>
                                            </span>

                                            <span>
                                                {"My Seminars "}

                                                &amp;
                                                {" Camps"}
                                            </span>
                                        </NavLink>

                                        <NavLink
                                            className="btn btn--md btn--thin"
                                            to="/settings/my-profile"
                                        >
                                            <span className="ico">
                                                <svg
                                                    fill="none"
                                                    height="22"
                                                    viewBox="0 0 22 22"
                                                    width="22"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M12.7501 9.2503C13.7167 10.2169 13.7167 11.7839 12.7501 12.7505C11.7836 13.717 10.2165 13.717 9.24994 12.7505C8.28339 11.7839 8.28339 10.2169 9.24994 9.2503C10.2165 8.28375 11.7836 8.28375 12.7501 9.2503"
                                                        stroke="currentColor"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="1.2"
                                                    />

                                                    <path
                                                        clipRule="evenodd"
                                                        d="M4.81246 11C4.81246 11.2723 4.83721 11.5445 4.87021 11.8085L3.41455 12.947C3.09188 13.2 3.00388 13.6519 3.20921 14.0067L4.50355 16.2461C4.70796 16.6008 5.14246 16.7503 5.52288 16.5981L6.82638 16.0747C7.08396 15.9711 7.37088 16.0123 7.60188 16.1654C7.80355 16.2993 8.01255 16.4221 8.22888 16.5321C8.47638 16.6577 8.65605 16.8823 8.69546 17.1573L8.89438 18.5442C8.95213 18.9493 9.29955 19.25 9.70838 19.25H12.2906C12.6995 19.25 13.0469 18.9493 13.1046 18.5442L13.3035 17.1582C13.343 16.8832 13.5245 16.6568 13.7729 16.5321C13.9883 16.4239 14.1964 16.302 14.3971 16.1691C14.63 16.0151 14.9178 15.9711 15.1763 16.0756L16.477 16.5981C16.8565 16.7503 17.291 16.6008 17.4964 16.2461L18.7907 14.0067C18.996 13.6519 18.908 13.1991 18.5854 12.947L17.1297 11.8085C17.1627 11.5445 17.1875 11.2723 17.1875 11C17.1875 10.7278 17.1627 10.4555 17.1297 10.1915L18.5854 9.053C18.908 8.8 18.996 8.34808 18.7907 7.99333L17.4964 5.75392C17.292 5.39917 16.8575 5.24975 16.477 5.40192L15.1763 5.92442C14.9178 6.028 14.63 5.98492 14.3971 5.83092C14.1964 5.698 13.9883 5.57608 13.7729 5.46792C13.5245 5.34325 13.343 5.11683 13.3035 4.84183L13.1055 3.45583C13.0478 3.05067 12.7004 2.75 12.2915 2.75H9.7093C9.30046 2.75 8.95305 3.05067 8.8953 3.45583L8.69546 4.84367C8.65605 5.11775 8.47546 5.34325 8.22888 5.46883C8.01255 5.57883 7.80355 5.70258 7.60188 5.8355C7.36996 5.98767 7.08305 6.02892 6.82546 5.92533L5.52288 5.40192C5.14246 5.24975 4.70796 5.39917 4.50355 5.75392L3.20921 7.99333C3.00388 8.34808 3.09188 8.80092 3.41455 9.053L4.87021 10.1915C4.83721 10.4555 4.81246 10.7278 4.81246 11V11Z"
                                                        fillRule="evenodd"
                                                        stroke="currentColor"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="1.2"
                                                    />
                                                </svg>
                                            </span>

                                            <span>
                                                Account Settings
                                            </span>
                                        </NavLink>
                                    </div>
                                </div>
                            )}

                        <div className="dropdown-menu__footer">
                            <a
                                className="btn btn--md btn--thin"
                                href="#"
                                onClick={handleLogout}
                            >
                                <span className="ico">
                                    <svg
                                        fill="none"
                                        height="22"
                                        viewBox="0 0 22 22"
                                        width="22"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M11.9167 11.0003H2.75"
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="1.2"
                                        />

                                        <path
                                            d="M5.95833 7.79199L2.75 11.0003L5.95833 14.2087"
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="1.2"
                                        />

                                        <path
                                            d="M11 2.75C15.5563 2.75 19.25 6.44365 19.25 11C19.25 15.5563 15.5563 19.25 11 19.25"
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="1.2"
                                        />
                                    </svg>
                                </span>

                                <span>
                                    Log Out
                                </span>
                            </a>
                        </div>
                    </DropDown>
                </>
            )}

            {
                planningModalOpen && (
                    <PlanningModal
                        handleClose={() => setPlanningModalOpen(false)}
                        open={planningModalOpen}
                    />
                )}
        </>
    );
};
