import React, { useEffect } from "react";
import {
    Link, useLocation
} from "react-router-dom";

const TermsAndConditions = () => {
    const { hash } = useLocation();

    useEffect(() => {
        const el = document.getElementById(decodeURI(hash.substring(1)));

        if (!el) {
            window.scrollTo(0, 0);

            return;
        }

        el.scrollIntoView({ behavior: "smooth" });
    }, [hash]);

    return (
        <section className="section">
            <div className="container">
                <div className="card-shadow document-card mb-42 terms-doc">
                    <div className="document-card__header">
                        <h3 className="heading text-center mb-44">
                            Terms And Conditions of Service
                        </h3>
                    </div>

                    {/* <ol className="document-card__body gap--sm flex flex-col">
                        <li>

                        </li>
                        <h2>Agreement Overview</h2>
                        <p>This Terms of Service ("Agreement") is entered into on July 29, 2024, between the Host and the Instructor.</p>

                        <h2>Seminar Details</h2>
                        <ul>
                            <li><strong>Title:</strong> Brazilian Jiu-Jitsu Seminar</li>
                            <li><strong>Date and Time:</strong> August 15, 2024, 10:00 AM</li>
                            <li><strong>Location:</strong> Centro de Convenções Rebouças, Avenida Rebouças, 600, Pinheiros, São Paulo, SP, 05402-000, Brasil</li>
                        </ul>

                        <h2>Compensation</h2>
                        <p>The Instructor agrees to provide a Brazilian Jiu-Jitsu (BJJ) seminar at the agreed-upon date, time, and location. The Host agrees to compensate the Instructor the sum of $1,000 for the seminar. Payment will be made half upfront and half upon completion.</p>

                        <h2>Travel and Accommodation</h2>
                        <p>The Host agrees to cover reasonable travel expenses for the Instructor, including airfare, accommodation, and meals. Any additional expenses must be pre-approved by the Host.</p>

                        <h2>Marketing and Promotion</h2>
                        <p>The Host will be responsible for marketing and promoting the seminar. The Instructor agrees to provide promotional materials such as photos, bios, and any other necessary content.</p>

                        <h2>Cancellation</h2>
                        <p>In the event of a cancellation by either party, a notice must be provided at least 14 days before the scheduled seminar date. If the Instructor cancels, any advanced payment made by the Host will be refunded promptly. If the Host cancels, the Instructor will receive compensation for any non-refundable expenses incurred.</p>

                        <h2>Liability</h2>
                        <p>The Instructor is responsible for their actions during the seminar. The Host is not liable for any injuries or damages incurred by participants during the seminar.</p>

                        <h2>Intellectual Property</h2>
                        <p>Any materials, techniques, or intellectual property shared by the Instructor during the seminar remain the property of the Instructor.</p>

                        <h2>Confidentiality</h2>
                        <p>Both parties agree to keep any confidential information shared during the seminar confidential.</p>

                        <h2>Governing Law</h2>
                        <p>This Agreement shall be governed by and construed in accordance with the laws of São Paulo, Brazil.</p>

                        <h2>Execution</h2>
                        <p>IN WITNESS WHEREOF, the parties hereto have executed this Agreement as of the date first above written.</p>
                    </ol> */}
                    <ol type="1">
                        <li>
                            <p >
                                <span >
                                    <span >
                                        <strong>
                                            INTRODUCTION

                                            {" "}
                                        </strong>
                                    </span>
                                </span>
                            </p>
                        </li>
                    </ol>

                    <ol type="a">
                        <li>
                            <p >
                                <span >
                                    <span >
                                        <u>
                                            <strong>
                                                YOUR ATTENTION IS HEREBY DRAWN TO

                                                <a href="#3(i)" >
                                                    SECTION 3(i)
                                                </a>

                                                ,

                                                <a href="#9(l)" >
                                                    SECTION 9(l)
                                                </a>

                                                {" "}

                                                and

                                                <a href="#9(n)" >
                                                    SECTION 9(n)
                                                </a>

                                                .
                                            </strong>
                                        </u>
                                    </span>
                                </span>
                            </p>
                        </li>

                        <li>
                            <p >
                                <span >
                                    <span >
                                        These Terms and Conditions of Service govern and regulate your use of

                                        {" "}
                                    </span>
                                </span>

                                <span >
                                    <u>
                                        <a href="http://www.BJJSeminars.com/">
                                            <span >
                                                www.BJJSeminars.com
                                            </span>
                                        </a>
                                    </u>
                                </span>

                                <span >
                                    <span >
                                        {" "}

                                        (our Website) and services overed on our Website. It constitutes a binding legal agreement between a user of our Website (

                                        &ldquo;
                                        you

                                        &rdquo;
                                        {" "}

                                        &ldquo;
                                        User

                                        &rdquo;
                                        {" or "}

                                        &ldquo;
                                        your

                                        &rdquo;
                                        ) and Plan Seminars ApS., a company whose registered address is at Svanemoellevej 41 2900 Hellerup, Copenhagen, Denmark (

                                        &ldquo;
                                        Plan Seminars

                                        &rdquo;
                                        {" "}

                                        &ldquo;
                                        our

                                        &rdquo;
                                        {" or "}

                                        &ldquo;
                                        us

                                        &rdquo;
                                        .
                                    </span>
                                </span>
                            </p>
                        </li>

                        <li>
                            <p >
                                <span >
                                    <span >
                                        By using our Website, you consent to these terms and are bound by them. Please kindly read these terms carefully. These Terms override any previous communication or agreement with Plan Seminars ApS in relation to any of our services and Website. If you do not agree to these terms, please do not use our Website or Services in any way. We offer our Website, including all information, tools and services available on it, to you, conditioned upon your acceptance of all terms, conditions, policies and notices stated here.
                                    </span>
                                </span>
                            </p>
                        </li>

                        <li>
                            <p >
                                <span >
                                    <span >
                                        Regarding any issue related to and connected to these Terms and Conditions of Service, we can be contacted on our

                                        <Link to="/contact-us">
                                            contact page
                                        </Link>

                                        {" "}

                                        . We would endeavour to respond to your email as soon as possible. Please be informed that we are only able to give clarifications and explanations, we are not able to provide professional or legal advice.
                                    </span>
                                </span>
                            </p>
                        </li>
                    </ol>

                    <ol
                        start="2"
                        type="1"
                    >
                        <li>
                            <p >
                                <span >
                                    <span >
                                        <strong>
                                            DEFINITIONS AND INTERPRETATIONS.

                                            {" "}
                                        </strong>
                                    </span>
                                </span>
                            </p>
                        </li>
                    </ol>

                    <p className="ml-20">
                        <span >
                            <span >
                                As used in these Terms and Conditions of Service, the following words and terms shall have the meanings ascribed to them below with words in the singular deemed to include those in the plural and vice versa:
                            </span>
                        </span>
                    </p>

                    <ol type="a">
                        <li>
                            <p >
                                <span >
                                    <span >
                                        <strong>
                                            Academy - means
                                        </strong>
                                    </span>
                                </span>

                                <span >
                                    <span >
                                        {" "}

                                        a corporate body and training center who signs up as an Academy on our Website and who represents themselves as a body training, teaching Brazilian jiu-jitsu and/or related services
                                    </span>
                                </span>

                                <span >
                                    <span >
                                        <strong>
                                            .
                                        </strong>
                                    </span>
                                </span>
                            </p>
                        </li>

                        <li>
                            <p >
                                <span >
                                    <span >
                                        <strong>
                                            {"Academy Services "}
                                            &ndash;

                                            {" "}
                                        </strong>
                                    </span>
                                </span>

                                <span >
                                    <span >
                                        means the hosting and organization of Seminars as offered by the Academy on our Website
                                    </span>
                                </span>

                                <span >
                                    <span >
                                        <strong>
                                            .
                                        </strong>
                                    </span>
                                </span>
                            </p>
                        </li>

                        <li>
                            <p >
                                <span >
                                    <span >
                                        <strong>
                                            Agreement or Contract -

                                            {" "}
                                        </strong>
                                    </span>
                                </span>

                                <span >
                                    <span >
                                        means these Terms and Conditions of Service, your contract for the use of our services and/or the use of our Website. As regarding your use of our Services, more details are provided in section 3.
                                    </span>
                                </span>
                            </p>
                        </li>

                        <li>
                            <p >
                                <span >
                                    <span >
                                        <strong>
                                            Account -

                                            {" "}
                                        </strong>
                                    </span>
                                </span>

                                <span >
                                    <span >
                                        {" "}

                                        means a registered profile needed for access and use of our Services.
                                    </span>
                                </span>
                            </p>
                        </li>

                        <li>
                            <p >
                                <span >
                                    <span >
                                        <strong>
                                            Plan Seminars
                                        </strong>
                                    </span>
                                </span>

                                <span >
                                    <span >
                                        {" "}

                                        - means Plan Seminars ApS, a company registered and located in the Denmark which can be contacted through our

                                        <Link to="/contact-us">
                                            contact us page
                                        </Link>

                                        .
                                    </span>
                                </span>
                            </p>
                        </li>

                        <li>
                            <p >
                                <span >
                                    <span >
                                        <strong>
                                            Booking

                                            {" "}
                                        </strong>
                                    </span>
                                </span>

                                <span >
                                    <span >
                                        - means an Order for

                                        {" "}
                                    </span>
                                </span>
                            </p>

                            <ol type="i">
                                <li>
                                    <p >
                                        <span >
                                            <span >
                                                an Instructor Service by a Student, which has been accepted by the Instructor; or
                                            </span>
                                        </span>
                                    </p>
                                </li>

                                <li>
                                    <p >
                                        <span >
                                            <span >
                                                an Instructor Service by an Academy, which has been accepted by the Academy; or
                                            </span>
                                        </span>
                                    </p>
                                </li>

                                <li>
                                    <p >
                                        <span >
                                            <span >
                                                attendance to a Seminar offered by an Academy by the Student, which has been accepted by an Academy.
                                            </span>
                                        </span>
                                    </p>
                                </li>
                            </ol>
                        </li>

                        <li>
                            <p >
                                <span >
                                    <span >
                                        <strong>
                                            Booking Confirmation

                                            {" "}
                                        </strong>
                                    </span>
                                </span>

                                <span >
                                    <span >
                                        - means a confirmation sent to the Service User showing an acceptance of an Order.
                                    </span>
                                </span>
                            </p>
                        </li>

                        <li>
                            <p >
                                <span >
                                    <span >
                                        <strong>
                                            Instructor
                                        </strong>
                                    </span>
                                </span>

                                <span >
                                    <span >
                                        {" "}

                                        means an individual who signs up as Instructors on our Website and represents himself/herself as a trainer or person who teaches Brazilian jiu-jitsu and/or related services.

                                        {" "}
                                    </span>
                                </span>
                            </p>
                        </li>

                        <li>
                            <p >
                                <span >
                                    <span >
                                        <strong>
                                            Instructor Services

                                            {" "}
                                        </strong>
                                    </span>
                                </span>

                                <span >
                                    <span >
                                        means the Service(s) offered by an Instructor as offered on our Website.
                                    </span>
                                </span>
                            </p>
                        </li>

                        <li>
                            <p >
                                <span >
                                    <span >
                                        <strong>
                                            Instructor

                                            &rsquo;
                                            s Service Fee

                                            {" "}
                                        </strong>
                                    </span>
                                </span>

                                <span >
                                    <span >
                                        - means the amount charged to Service Users for Instructor Services.

                                        {" "}
                                    </span>
                                </span>
                            </p>
                        </li>

                        <li>
                            <p >
                                <span >
                                    <span >
                                        <strong>
                                            Order
                                        </strong>
                                    </span>
                                </span>

                                <span >
                                    <span >
                                        - means

                                        {" "}
                                    </span>
                                </span>
                            </p>

                            <ol type="i">
                                <li>
                                    <p >
                                        <span >
                                            <span >
                                                a request to use an Instructor

                                                &rsquo;
                                                s Service on our Website;
                                            </span>
                                        </span>
                                    </p>
                                </li>

                                <li>
                                    <p >
                                        <span >
                                            <span >
                                                a request to attend a Seminar by a Student.

                                                {" "}
                                            </span>
                                        </span>
                                    </p>
                                </li>
                            </ol>
                        </li>

                        <li>
                            <p >
                                <span >
                                    <span >
                                        <strong>
                                            Order Confirmation
                                        </strong>
                                    </span>
                                </span>

                                <span >
                                    <span >
                                        {" "}

                                        - means an acceptance of an Order or request to use our Service.

                                        {" "}
                                    </span>
                                </span>
                            </p>
                        </li>

                        <li>
                            <p >
                                <span >
                                    <span >
                                        <strong>
                                            Our Content
                                        </strong>
                                    </span>
                                </span>

                                <span >
                                    <span >
                                        {" "}

                                        - includes those contents provided to Users by Us, which is related to or connected with our products, services and activities on our Website.

                                        {" "}
                                    </span>
                                </span>
                            </p>
                        </li>

                        <li>
                            <p >
                                <span >
                                    <span >
                                        <strong>
                                            Our Services -
                                        </strong>
                                    </span>
                                </span>

                                <span >
                                    <span >
                                        {" "}

                                        includes anything related to and connected with the introduction of Users to each other in the manner described in section 3 and these Terms and Conditions of Service.

                                        {" "}
                                    </span>
                                </span>
                            </p>
                        </li>

                        <li>
                            <p >
                                <span >
                                    <span >
                                        <strong>
                                            Payment -

                                            {" "}
                                        </strong>
                                    </span>
                                </span>

                                <span >
                                    <span >
                                        includes any amount of money paid to us by any User in respect of the Services.
                                    </span>
                                </span>
                            </p>
                        </li>

                        <li>
                            <p >
                                <span >
                                    <span >
                                        <strong>
                                            Party

                                            {" "}
                                        </strong>
                                    </span>
                                </span>

                                <span >
                                    <span >
                                        - means either User as defined in 2(w) or Plan Seminars as defined in 2(e).
                                    </span>
                                </span>
                            </p>
                        </li>

                        <li>
                            <p >
                                <span >
                                    <span >
                                        <strong>
                                            Proposal

                                            {" "}
                                        </strong>
                                    </span>
                                </span>

                                <span >
                                    <span >
                                        &ndash;
                                        {" means an invitation from a Service Provider to a User, inviting the User to make an offer. Proposals can be in form of preset projects on a Service Provider page or a customised offer after initial conversation with the User interested in purchasing the Service Provider"}
                                        &rsquo;

                                        s Services.

                                        {" "}
                                    </span>
                                </span>
                            </p>
                        </li>

                        <li>
                            <p >
                                <span >
                                    <span >
                                        <strong>
                                            {"Booking Specific Terms "}
                                            &ndash;

                                            {" "}
                                        </strong>
                                    </span>
                                </span>

                                <span >
                                    <span >
                                        These are specific terms agreed by the Parties and attached to a booking for services which has been previously agreed by the Parties or displayed with a project or seminar advertisement.

                                        {" "}
                                    </span>
                                </span>
                            </p>
                        </li>

                        <li>
                            <p >
                                <span >
                                    <span >
                                        <strong>
                                            Service Provider

                                            {" "}
                                        </strong>
                                    </span>
                                </span>

                                <span >
                                    <span >
                                        &ndash;
                                        {" means either the Instructor or Academy depending on the services being offered. Instructors are Service Providers when offering their services to Students and Academies, and Academies are Service Providers when offering their Services to Students."}

                                        {" "}
                                    </span>
                                </span>
                            </p>
                        </li>

                        <li>
                            <p >
                                <span >
                                    <span >
                                        <strong>
                                            Service Provider Terms
                                        </strong>
                                    </span>
                                </span>

                                <span >
                                    <span >
                                        {" "}

                                        - means the Service Provider

                                        &rsquo;
                                        s Service Terms available on the Service Provider

                                        &rsquo;
                                        s page and
                                    </span>
                                </span>

                                <span >
                                    <span >
                                        {" "}

                                        provided to Users at the ti
                                    </span>
                                </span>

                                <span >
                                    <span >
                                        me of making an Order.
                                    </span>
                                </span>
                            </p>
                        </li>

                        <li>
                            <p >
                                <span >
                                    <span >
                                        <strong>
                                            Service Provider Service

                                            {" "}
                                        </strong>
                                    </span>
                                </span>

                                <span >
                                    <span >
                                        &ndash;
                                        {" means services offered by either Instructors or Academics on our Website."}
                                    </span>
                                </span>
                            </p>
                        </li>

                        <li>
                            <p >
                                <span >
                                    <span >
                                        <strong>
                                            Terms and Conditions of Service

                                            {" "}
                                        </strong>
                                    </span>
                                </span>

                                <span >
                                    <span >
                                        {"- means this "}

                                        &ldquo;
                                        Terms and Conditions of Service

                                        &rdquo;
                                        {" together with the terms incorporated by reference, all as may be amended, varied or supplemented from time to time by us in accordance with the provisions of this Terms and Conditions of Service."}

                                        {" "}
                                    </span>
                                </span>
                            </p>
                        </li>

                        <li>
                            <p >
                                <span >
                                    <span >
                                        <strong>
                                            User

                                            {" "}
                                        </strong>
                                    </span>
                                </span>

                                <span >
                                    <span >
                                        &ndash;
                                        {" means anyone using our Website including but not limited to Website visitors, Academy, Instructor, Student or any person using our Services or Website for whatsoever reason."}

                                        {" "}
                                    </span>
                                </span>
                            </p>
                        </li>

                        <li>
                            <p >
                                <span >
                                    <span >
                                        <strong>
                                            Website

                                            {" "}
                                        </strong>
                                    </span>
                                </span>

                                <span >
                                    <span >
                                        &ndash;
                                        {" means"}

                                        {" "}
                                    </span>
                                </span>

                                <span >
                                    <u>
                                        <a href="http://www.BJJSeminars.com/">
                                            <span >
                                                www.BJJSeminars.com
                                            </span>
                                        </a>
                                    </u>
                                </span>

                                {" "}

                                <span >
                                    <span >
                                        and all of our services.
                                    </span>
                                </span>
                            </p>
                        </li>

                        <li>
                            <p >
                                <span >
                                    <span >
                                        <strong>
                                            You or Your

                                            {" "}
                                        </strong>
                                    </span>
                                </span>

                                <span >
                                    <span >
                                        {"- means the same thing as "}

                                        &ldquo;
                                        User

                                        &rdquo;
                                        {" as described in 2(w). It can individually mean any of the Users or collectively mean all of the Users. It is accepted in all contexts."}

                                        {" "}
                                    </span>
                                </span>
                            </p>
                        </li>

                        <li>
                            <p >
                                <span >
                                    <span >
                                        <strong>
                                            Miscellaneous Fees

                                            {" "}
                                        </strong>
                                    </span>
                                </span>

                                <span >
                                    <span >
                                        - any other charges including but not limited to transfer fees, bank charges and all other charges related and connected to financial activities (payment or withdrawal by Users, as applicable) on our Website. If there is any question or enquiry on miscellaneous fees, we advise that you contact your bank for more information.
                                    </span>
                                </span>
                            </p>
                        </li>
                    </ol>

                    <p >
                        <span >
                            <span >
                                <strong>
                                    2.1 References.
                                </strong>
                            </span>
                        </span>
                    </p>

                    <p className="ml-20">
                        <span >
                            <span >
                                Any reference to a statutory provision shall be construed as a reference to:

                                {" "}
                            </span>
                        </span>
                    </p>

                    <ol type="a">
                        <li>
                            <p >
                                <span >
                                    <span >
                                        Any statutory modification or re-enactment thereof (whether before or after the date hereof) for the time being in force;
                                    </span>
                                </span>
                            </p>
                        </li>

                        <li>
                            <p >
                                <span >
                                    <span >
                                        All statutory instruments or orders made pursuant thereto; and
                                    </span>
                                </span>
                            </p>
                        </li>

                        <li>
                            <p >
                                <span >
                                    <span >
                                        Any statutory provisions of which that statutory provision is a re-enactment or modification.
                                    </span>
                                </span>
                            </p>
                        </li>

                        <li>
                            <p >
                                <span >
                                    <span >
                                        Any reference to a document or this Terms and Conditions of Service shall include a reference to any amendment, replacement, notation or supplement to that document or this Terms and Conditions of Service but excluding any amendment replacement, notation or supplement made in breach of this Terms and Conditions of Service.
                                    </span>
                                </span>
                            </p>
                        </li>

                        <li>
                            <p >
                                <span >
                                    <span >
                                        Paragraph headings are inserted for ease of reference and convenience and shall not be construed as forming part of this Terms and Conditions of Service or used in the interpretation of any Article hereof.
                                    </span>
                                </span>
                            </p>
                        </li>

                        <li>
                            <p >
                                <span >
                                    <span >
                                        Words denoting the singular shall include the plural and vice versa.
                                    </span>
                                </span>
                            </p>
                        </li>

                        <li>
                            <p >
                                <span >
                                    <span >
                                        Words denoting persons shall include corporations, partnerships, sole traders firms and organisations and vice versa.
                                    </span>
                                </span>
                            </p>
                        </li>

                        <li>
                            <p >
                                <span >
                                    <span >
                                        Words denoting any gender shall include all genders.
                                    </span>
                                </span>
                            </p>
                        </li>
                    </ol>

                    <p >
&nbsp;
                    </p>

                    <ol
                        start="3"
                        type="1"
                    >
                        <li>
                            <p >
                                <span >
                                    <span >
                                        <strong>
                                            OUR SERVICES.
                                        </strong>
                                    </span>
                                </span>
                            </p>
                        </li>
                    </ol>

                    <ol type="a">
                        <li>
                            <p >
                                <span >
                                    <span >
                                        {"We are an online introductory platform that connects Users to each other. We connect students, Instructors and Academies within the Brazilian jiu-jitsu community to each other. We allow Instructors and Academies to professionally advertise, market, sell, promote, and offer their services for order, purchase, reservation, and hire. Access to Our Website is on an "}

                                        &ldquo;
                                        as available

                                        &rdquo;
                                        {" basis."}

                                        {" "}
                                    </span>
                                </span>
                            </p>
                        </li>

                        <li>
                            <p >
                                <span >
                                    <span >
                                        In providing the Services, Plan Seminars is only an introducing agent who acts as the agent of Service Providers. We have no responsibility for any Service Provider Service which users may book through us. We are simply involved with the booking process and introducing the users to each other. The contract for the purchase of the services is between you and the Service Provider, as applicable. This means that it is the Service Provider (not us) who is legally responsible for providing their services, as purchased, to you.
                                    </span>
                                </span>
                            </p>
                        </li>

                        <li>
                            <p >
                                <span >
                                    <span >
                                        Our Services are not employment services, and Plan Seminars does not serve as an employer of any Service Provider. Service Providers are acting as independent parties. Any financial and tax liability that comes from using our services is solely the responsibility of the Service Provider. Our help with design or drafting marketing and advertising content for them, sending booking details to them and sending Students a booking confirmation are performed on their behalf to let them advertise and market their services.
                                    </span>
                                </span>
                            </p>
                        </li>

                        <li>
                            <p >
                                <span >
                                    <span >
                                        To ensure efficiency, we attempt to verify every Service Provider listed on our Website. However, we do not control and are not responsible for any information provided by any Service Provider. We verify Service Providers based on the information provided to us. While we use good faith efforts to confirm that each Service Provider on our Website are legitimate Brazilian Jiu-jitsu practitioners. We are not able to provide any assurances regarding the trustworthiness or accuracy of the information they provide via our Website. They are given access to our Website to provide and update their availability, price, policies, marketing information, etc. We disclose these information on their behalf. we do not verify all information and therefore cannot guarantee its accuracy, completeness, or correctness. We are not liable for any errors, including obvious or typographical mistakes, nor for any interruptions caused by temporary or partial breakdowns, repairs, upgrades, or maintenance of our Website, or other issues. Furthermore, we are not responsible for any inaccurate, misleading, or false information, or for any failures in the delivery or provision of information. Each user is responsible and liable for all information and actions conducted using our Website.
                                    </span>
                                </span>
                            </p>
                        </li>

                        <li>
                            <p >
                                <span >
                                    <span >
                                        Plan Seminars expressly states that we do not endorse or make any representations or warranties regarding the reliability of the verification or any User. When interacting with other Users, you should exercise caution and common sense to protect your personal safety and property, just as you would when interacting with other persons whom you don

                                        &rsquo;
                                        t know. Neither Plan Seminars nor its affiliates or licensors is responsible for the conduct, whether online or offline, of any User of the Services. Plan Seminars, its affiliates and licensors will not be liable for any claim, injury or damage arising in connection with your use of our services.

                                        {" "}
                                    </span>
                                </span>
                            </p>
                        </li>

                        <li>
                            <p >
                                <span >
                                    <span >
                                        Plan Seminars is only a platform bringing Users together. It is the responsibility of Users to read the information on other Users

                                        &rsquo;
                                        {" page to make an informed personal decision on whether to engage/hire the User or not. As a User, you are responsible for making a decision regarding the suitability of the User you engage for your needs. When interacting with any User, you should exercise caution and common sense to protect your personal safety, details and property, just as you would when interacting with other persons who are unknown to you."}
                                    </span>
                                </span>
                            </p>
                        </li>

                        <li>
                            <p >
                                <span >
                                    <span >
                                        Plan Seminars may or may not utilise third party agencies that perform, among other things, identification verifications, license verifications, etc. However, we have no obligation to use these verification channels.

                                        {" "}
                                    </span>
                                </span>
                            </p>
                        </li>

                        <li>
                            <p >
                                <span >
                                    <span >
                                        To access our services, you must have an account. For more on this, please see our

                                        <Link to="/terms-of-use">
                                            Website Terms of Use
                                        </Link>

                                        .
                                    </span>
                                </span>
                            </p>
                        </li>

                        <li id="3(i)">
                            <p >
                                <span >
                                    <span >
                                        Users in the United Kingdom or the European Economic Area (EEA)

                                        {" "}
                                    </span>
                                </span>

                                <span >
                                    <span >
                                        <strong>
                                            MUST BE 18 YEARS OLD TO USE OUR SERVICES
                                        </strong>
                                    </span>
                                </span>

                                <span >
                                    <span >
                                        . For minors outside the EEA who are between the age of 13 and 18, the use of our Website or any of our services must be through the use of an account owned and operated by a parent or legal guardian. The parent must also provide affirmative consent and supervise the use of their Account. Users are solely responsible for any use of their Account by a minor.

                                        {" "}
                                    </span>
                                </span>
                            </p>
                        </li>

                        <li>
                            <p >
                                <span >
                                    <span >
                                        By using our Website, you hereby confirm that you are of the required Age as stated in 3(i). A breach of this term constitutes a fundamental breach of this Terms and Conditions of Service.

                                        {" "}
                                    </span>
                                </span>
                            </p>
                        </li>

                        <li>
                            <p >
                                <span >
                                    <span >
                                        You acknowledge that the Website is an evolving one and that the form and nature of the Website, including the Services, may change from time to time. Whenever we are making a reasonably significant change, we will inform you about the changes.

                                        {" "}
                                    </span>
                                </span>
                            </p>
                        </li>

                        <li>
                            <p >
                                <span >
                                    <span >
                                        We will take all reasonable steps to ensure that access to our Services is available at all times. We will inform you if we need to carry out an upgrade or restrict access to our services for a period of time for maintenance purposes.

                                        {" "}
                                    </span>
                                </span>
                            </p>
                        </li>

                        <li>
                            <p >
                                <span >
                                    <span >
                                        You agree that we do not bear any liability from any loss of data, business opportunity or content due to the scheduled maintenance, unplanned outages or malfunctioning of our Website.
                                    </span>
                                </span>
                            </p>
                        </li>

                        <li>
                            <p >
                                <span >
                                    <span >
                                        If you have any questions or complaints regarding Our Service, you can reach us through our

                                        <Link to="/contact-us">
                                            contact us page
                                        </Link>
                                    </span>
                                </span>
                            </p>
                        </li>

                        <li>
                            <p >
                                <span >
                                    <span >
                                        You hereby agree that neither our Service, our Website, nor any part of our Website constitutes advice or suggestion. They are just general information put out for information purposes only.
                                    </span>
                                </span>
                            </p>
                        </li>

                        <li>
                            <p >
                                <span >
                                    <span >
                                        We make no representation, warranty, or guarantee that our Website or Service will meet your requirements, that it will be fit for a particular purpose, that it will not infringe the rights of third parties, that it will be compatible with all software and hardware, or that it will be secure. We will take reasonable steps to ensure that our Content and Service is functional and that it works; however, we do not make any express or implied warranty, guarantee or representation that it will be functional and up to date. We are not responsible for any User

                                        &rsquo;
                                        s content and those content do not reflect our opinion or values.

                                        {" "}
                                    </span>
                                </span>
                            </p>
                        </li>
                    </ol>

                    <ol
                        start="4"
                        type="1"
                    >
                        <li>
                            <p >
                                <span >
                                    <span >
                                        <strong>
                                            SERVICE PROVIDERS SPECIFIC PROVISIONS.
                                        </strong>
                                    </span>
                                </span>
                            </p>
                        </li>
                    </ol>

                    <p >
                        <span >
                            <span >
                                <strong>
                                    4.1 THE INCORPORATION OF SERVICE PROVIDERS

                                    &rsquo;
                                    {" TERMS."}
                                </strong>
                            </span>
                        </span>
                    </p>

                    <ol type="a">
                        <li>
                            <p >
                                <span >
                                    <span >
                                        On their Account profile, some Service Providers may have Terms regulating their Services. Plan Seminars is not a party to the Service Provider Terms as this is between the User and the Service Provider for the provision of the Service Provider Service. Parties may negotiate these Service Provider Terms by agreeing to variate it in Booking Specific Terms that maybe included in any Booking.

                                        {" "}
                                    </span>
                                </span>
                            </p>
                        </li>

                        <li>
                            <p >
                                <span >
                                    <span >
                                        Please ensure that you read the Service Provider Terms or Booking Terms as they are binding on you to the extent of the provision of the Service Provider Service.

                                        {" "}
                                    </span>
                                </span>
                            </p>
                        </li>

                        <li>
                            <p >
                                <span >
                                    <span >
                                        In case of any conflict or inconsistencies between the Terms, please see section 9(c) below

                                        {" "}
                                    </span>
                                </span>
                            </p>
                        </li>
                    </ol>

                    <p >
                        <span >
                            <span >
                                <strong>
                                    4.2 SERVICE PROVIDER

                                    &rsquo;
                                    S SPECIFIC RIGHTS AND OBLIGATIONS.
                                </strong>
                            </span>
                        </span>
                    </p>

                    <ol type="a">
                        <li>
                            <p >
                                <span >
                                    <span >
                                        Service Providers must refrain from actions and omissions that:
                                    </span>
                                </span>
                            </p>

                            <ol type="i">
                                <li>
                                    <p >
                                        <span >
                                            <span >
                                                seeks to take Orders, Bookings or Payments from Users (introduced by our platform and our Website) outside our Website;
                                            </span>
                                        </span>
                                    </p>
                                </li>

                                <li>
                                    <p >
                                        <span >
                                            <span >
                                                violates, or encourages any conduct that would violate, any applicable law or regulation or would give rise to civil liability; and
                                            </span>
                                        </span>
                                    </p>
                                </li>

                                <li>
                                    <p >
                                        <span >
                                            <span >
                                                that contravenes or breaches the provisions of this Terms and Conditions of Service or any other Plan Seminars policies.
                                            </span>
                                        </span>
                                    </p>
                                </li>
                            </ol>
                        </li>

                        <li>
                            <p >
                                <span >
                                    <span >
                                        All Service Providers shall:
                                    </span>
                                </span>
                            </p>

                            <ol type="i">
                                <li>
                                    <p >
                                        <span >
                                            <span >
                                                take all reasonable steps to ensure their safety and the safety of other Users when providing the relevant Service Provider Services;

                                                {" "}
                                            </span>
                                        </span>
                                    </p>
                                </li>

                                <li>
                                    <p >
                                        <span >
                                            <span >
                                                abide by all applicable data protection and privacy laws relating to all User

                                                &rsquo;
                                                s personal data that it has access to;
                                            </span>
                                        </span>
                                    </p>
                                </li>

                                <li>
                                    <p >
                                        <span >
                                            <span >
                                                update your account and ensure that the account is kept up-to-date;
                                            </span>
                                        </span>
                                    </p>
                                </li>

                                <li>
                                    <p >
                                        <span >
                                            <span >
                                                quickly inform us of any issues with our Website or the Service Provider

                                                &rsquo;
                                                s Services;
                                            </span>
                                        </span>
                                    </p>
                                </li>

                                <li>
                                    <p >
                                        <span >
                                            <span >
                                                you agree that Plan Seminars has no obligation to introduce you with any User within a particular time, the introduction service is based on availability of Users alone.

                                                {" "}
                                            </span>
                                        </span>
                                    </p>
                                </li>

                                <li>
                                    <p >
                                        <span >
                                            <span >
                                                cooperate with us in all respects and take all reasonable steps to allow us perform the Services as well as our obligations under this Agreement; and
                                            </span>
                                        </span>
                                    </p>
                                </li>

                                <li>
                                    <p >
                                        <span >
                                            <span >
                                                do all that is reasonably expected and possible to ensure the fulfilment of the obligations contained in this Terms and Conditions of Service.
                                            </span>
                                        </span>
                                    </p>
                                </li>
                            </ol>
                        </li>
                    </ol>

                    <ol
                        start="5"
                        type="1"
                    >
                        <li>
                            <p >
                                <span >
                                    <span >
                                        <strong>
                                            HOW TO MAKE ORDERS AND BOOKINGS ON OUR WEBSITE.
                                        </strong>
                                    </span>
                                </span>
                            </p>
                        </li>
                    </ol>

                    <ol type="a">
                        <li>
                            <p >
                                <span >
                                    <span >
                                        After creating an account with us, a User can continue by making an Order for a Service Provider Service by choosing a Service Provider of their choice. As a User, you will be guided through this process on our Website.

                                        {" "}
                                    </span>
                                </span>
                            </p>
                        </li>

                        <li>
                            <p >
                                <span >
                                    <span >
                                        Order for Services will be made directly to the relevant Service, such as in the case of a Student making an offer to an Instructor for the Instructor Services, a Student making an offer to an Academy for the attendance of a seminar (Academy Services) or an Academy making an offer to an Instructor, inviting the Instructor to provide its Services at the Academy

                                        &rsquo;
                                        s event or seminar.

                                        {" "}
                                    </span>
                                </span>
                            </p>
                        </li>

                        <li>
                            <p >
                                <span >
                                    <span >
                                        An offer for Service Provider Services shall be made from the party seeking to engage the Service Provider and the Service Provider shall either accept or reject the offer. Users are allowed to communicate through the platform chatroom before sending an official offer which constitutes an Order for the Service Provider Services.

                                        {" "}
                                    </span>
                                </span>
                            </p>
                        </li>

                        <li>
                            <p >
                                <span >
                                    <span >
                                        The Service Provider will also be able to send a Proposal to Users to which they have communicated with in the chatroom, however all Proposals from Service Providers shall be an invitation to treat after which the User seeking to purchase the Service Provider

                                        &rsquo;
                                        s Services will be able to send an offer in the manner stated in section 5(c) above. For the avoidance of doubt, only Service Providers can accept an offer.

                                        {" "}
                                    </span>
                                </span>
                            </p>
                        </li>

                        <li>
                            <p >
                                <span >
                                    <span >
                                        Once you make an Order, we function solely as an intermediary, transmitting the Order details to the relevant Service Provider and sending you a Booking Confirmation on their behalf. We do not sell, resell, rent, or offer any products or services from Service Providers. It is the User and Service Provider responsibility to ensure that they check the Proposal or Order details so please check it carefully before you make an Order, or in the case of the Service Provider, accept an Order.

                                        {" "}
                                    </span>
                                </span>
                            </p>
                        </li>

                        <li>
                            <p >
                                <span >
                                    <span >
                                        Users must make all Orders related to the Service Provider

                                        &rsquo;
                                        s Service on our Website. As long as Users meet, connect or are introduced through our Website, all Orders and Bookings for any Service Provider Service must be done through our Website. We reserve the right to ban any account that makes any order or accept any order from another User outside our Website. This is done to protect our legitimate and financial interest.

                                        {" "}
                                    </span>
                                </span>
                            </p>
                        </li>

                        <li>
                            <p >
                                <a name="_Hlk172763350" />

                                {" "}

                                <span >
                                    <span >
                                        The

                                        {" "}
                                    </span>
                                </span>

                                <span >
                                    <span >
                                        Booking Confirmation

                                        {" "}
                                    </span>
                                </span>

                                <span >
                                    <span >
                                        will include details of your Booking (your booking date, the date of the sessions or seminar (as applicable), the minimum attendance requirement and the type of session), the Booking Fees, including VAT and other related or miscellaneous fees.

                                        {" "}
                                    </span>
                                </span>
                            </p>
                        </li>
                    </ol>

                    <p >
                        <span >
                            <span >
                                <strong>
                                    Booking Specific Terms.
                                </strong>
                            </span>
                        </span>
                    </p>

                    <ol
                        start="8"
                        type="a"
                    >
                        <li>
                            <p >
                                <span >
                                    <span >
                                        Users may decide to add specific terms to their Booking or Orders. When making an Order or sending a Proposal, Users will be able to proposal Booking Terms. These terms will only apply to the Order and the Booking to which they are attached. If there is a conflict between a Booking Specific Term, the Service Provider Terms, or this Terms and Conditions of Service, the provisions of section 9(c) of this Terms and Conditions of Service shall apply.
                                    </span>
                                </span>
                            </p>
                        </li>

                        <li>
                            <p >
                                <span >
                                    <span >
                                        A Booking can be cancelled in accordance with our cancellation and refund policy.

                                        {" "}
                                    </span>
                                </span>
                            </p>
                        </li>
                    </ol>

                    <ol
                        start="6"
                        type="1"
                    >
                        <li>
                            <p >
                                <span >
                                    <span >
                                        <strong>
                                            POST PERFORMANCE OBLIGATIONS.
                                        </strong>
                                    </span>
                                </span>
                            </p>

                        </li>
                    </ol>

                    <ol type="a">
                        <li>
                            <p >
                                <span >
                                    <span >
                                        Within one week of the date to which a Booking has been made, both the User who purchased the Service Provider Services and the Service Provider will be required to confirm that the Booking happened on the date. If this is not confirmed within a week, we will presume that the Booking takes place and release the payments to the Service Provider in the manner discussed in section 7 below.
                                    </span>
                                </span>
                            </p>
                        </li>

                        <li>
                            <p >
                                <span >
                                    <span >
                                        Any complaint by the User who purchased the Service Provider Services shall be made to us at

                                        {" "}
                                    </span>
                                </span>

                                <span >
                                    <u>
                                        <a href="mailto:support@bjjseminars.com">
                                            <span >
                                                support@bjjseminars.com
                                            </span>
                                        </a>
                                    </u>
                                </span>

                                <span >
                                    <span >
                                        {" "}

                                        , we will try our best to resolve the situation amicably between the Users.
                                    </span>
                                </span>
                            </p>
                        </li>

                        <li>
                            <p >
                                <a name="_Hlk172764060" />

                                {" "}

                                <span >
                                    <span >
                                        We do not offer refunds or cancellations for no-show, late arrival or non-attendance of events, seminars or sessions to which a Booking was made. The decision to refund or reschedule for non-attendance, late arrival or no-show rests solely with the Service Provider

                                        &rsquo;
                                        s discretion. It is only the Service Provider that can authorise or make this request to us on your behalf. Please contact the Service Provider for any refund or cancellation regarding non-attendance, late arrival or no-show.
                                    </span>
                                </span>
                            </p>
                        </li>
                    </ol>

                    <ol
                        start="7"
                        type="1"
                    >
                        <li>
                            <p >
                                <span >
                                    <span >
                                        <strong>
                                            PAYMENT PROVISIONS.
                                        </strong>
                                    </span>
                                </span>
                            </p>
                        </li>
                    </ol>

                    <ol type="a">
                        <li>
                            <p >
                                <span >
                                    <span >
                                        All Payment made in connection to any Service Provider

                                        &rsquo;
                                        s Services must be made on our Website. We reserve the right to ban any User that makes additional Payment or obtains additional Service Provider Services outside our Website without making Payment through our Website. This is done to protect our legitimate and financial interests.

                                        {" "}
                                    </span>
                                </span>
                            </p>
                        </li>

                        <li>
                            <p >
                                <span >
                                    <span >
                                        The details of any Service Provider Service Fees can be found on the Service Provider

                                        &rsquo;
                                        s page, the seminar or event details or maybe agreed by the parties during the Booking making process.
                                    </span>
                                </span>
                            </p>
                        </li>

                        <li>
                            <p >
                                <span >
                                    <span >
                                        Payment shall be made by Users using the mediums and payment channels stipulated on our Website. Our Payment medium is limited to Stripe. Please note that unless a payment channel is available on our Website, they are not acceptable to us. Please note that the use of any payment medium might attract extra charges from the payment provider, this will be communicated to you before the payment medium is used. You will pay for such additional charges. Data registered and saved by Us act as proof of Order and of all transactions done.

                                        {" "}
                                    </span>
                                </span>
                            </p>
                        </li>

                        <li>
                            <p >
                                <span >
                                    <span >
                                        Payment shall not be deemed to have been made until we have received cleared funds in respect of the full amount of your Order.
                                    </span>
                                </span>
                            </p>
                        </li>

                        <li>
                            <p >
                                <span >
                                    <span >
                                        Service Providers reserve the right to modify their prices at all times, but the prices invoiced to a User are those applicable and agreed upon on the date of the Booking.

                                        {" "}
                                    </span>
                                </span>
                            </p>
                        </li>

                        <li>
                            <p >
                                <span >
                                    <span >
                                        For every payment received on behalf of a Service Provider, the Service Providers shall pay a commission to us. The commission will be 8% of all payments received after all Miscellaneous Fees has been removed.

                                        {" "}
                                    </span>
                                </span>
                            </p>
                        </li>

                        <li>
                            <p >
                                <span >
                                    <span >
                                        Within 5 days of confirmation in accordance with section 6(a) above, we will remit all payment minus our commission as stated in 7(f) to the bank or payment details provided to by Service Providers on their Account. It is the responsibility of Service Providers to ensure that the payment details are accurate and updated. Plan Seminars will bear no liability or responsibility for payments made to payment details contained on the Service Provider

                                        &rsquo;
                                        s Account.

                                        {" "}
                                    </span>
                                </span>
                            </p>
                        </li>

                        <li>
                            <p >
                                <span >
                                    <span >
                                        Additionally, the use of various payment method or details may incur additional Miscellaneous Fees (for example international transfer or conversion rates). The Service Provider will be responsible for these Miscellaneous Fees.
                                    </span>
                                </span>
                            </p>
                        </li>

                        <li>
                            <p >
                                <span >
                                    <span >
                                        If a User become aware of fraudulent use of your payment card or payment information on our Website, you must notify your card provider in accordance with the applicable reporting rules.
                                    </span>
                                </span>
                            </p>
                        </li>

                        <li>
                            <p >
                                <span >
                                    <span >
                                        Plan Seminars may offer or issue refunds in accordance with our cancellation and refund policy.

                                        {" "}
                                    </span>
                                </span>
                            </p>
                        </li>
                    </ol>

                    <ol
                        start="8"
                        type="1"
                    >
                        <li>
                            <p >
                                <span >
                                    <span >
                                        <strong>
                                            DISCLAIMERS
                                        </strong>
                                    </span>
                                </span>
                            </p>
                        </li>
                    </ol>

                    <ol type="a">
                        <li>
                            <p >
                                <span >
                                    To the maximum extent permitted by applicable law, we expressly disclaim warranties and conditions including, without limitation, warranties and conditions of satisfactory quality, merchantability, fitness for a particular purpose, non-infringement, and those arising from course of dealing or usage of trade. You acknowledge and agree that the relevant Service Provider is solely responsible and assumes all responsibility and liability in respect of the Service Provider Services including any warranties and representations made by the Service Provider. All queries, claims and complaints in respect of the Service Provider Services are to be referred to and resolved by the Service Provider.

                                    {" "}
                                </span>
                            </p>
                        </li>

                        <li>
                            <p >
                                <span >
                                    To the extent permitted by law, neither we nor any of our officers, directors, employees, representatives, subsidiaries, affiliated companies, distributors, affiliate distribution partners, licensees, agents, or others involved in creating, sponsoring, promoting, or otherwise making available the Website and its contents shall be liable for:

                                    {" "}
                                </span>
                            </p>

                            <ol type="i">
                                <li>
                                    <p >
                                        <span >
                                            the Services rendered or products offered by any Service Provider= or other business partners;

                                            {" "}
                                        </span>
                                    </p>
                                </li>

                                <li>
                                    <p >
                                        <span >
                                            Any personal injury, death, property damage, or other direct, indirect, special, consequential, or punitive damages, losses, or costs suffered, incurred, or paid by you, whether due to legal acts, errors, breaches, gross negligence, wilful misconduct, omissions, non-performance, misrepresentations, tort, or strict liability by or wholly or partly attributable to the Service Provider or any of our other business partners, including any of their employees, directors, officers, agents, representatives, subcontractors, or affiliated companies whose products or services are directly or indirectly made available, offered, or promoted on or through our Website, including any partial cancellation, overbooking, strike, force majeure, or any other event beyond our control;
                                        </span>
                                    </p>
                                </li>

                                <li>
                                    <p >
                                        <span >
                                            any punitive, special, indirect, or consequential loss or damages, including but not limited to loss of production, profit, revenue, contract, goodwill or reputation, or claims;
                                        </span>
                                    </p>
                                </li>

                                <li>
                                    <p >
                                        <span >
                                            any inaccuracy related to the information, including rates, availability, and ratings, of the Service Providers as made available on the Website; or
                                        </span>
                                    </p>
                                </li>

                                <li>
                                    <p >
                                        <span >
                                            any direct, indirect, consequential, or punitive damages, losses, or costs suffered, incurred, or paid by you, arising out of or in connection with the use, inability to use, or delay of our Website.
                                        </span>
                                    </p>
                                </li>
                            </ol>
                        </li>

                        <li>
                            <p >
                                <span >
                                    We make no warranty as to the accuracy, completeness or reliability of any materials, information or data available through, or the performance of the websites and/or the services.
                                </span>
                            </p>
                        </li>

                        <li>
                            <p >
                                <span >
                                    You acknowledge and agree that any material downloaded or otherwise obtained through the use of the websites and/or the services is at your own risk and that you will be solely responsible for any damage to your computer, mobile phone or other device or any loss of data resulting from downloading or obtaining such material.
                                </span>
                            </p>
                        </li>

                        <li>
                            <p >
                                <span >
                                    Some jurisdictions do not allow the disclaimer of implied warranties. in such jurisdictions, the company expressly disclaims all warranties and conditions to the maximum extent permitted by applicable law.
                                </span>
                            </p>
                        </li>

                        <li>
                            <p>
&nbsp;
                            </p>
                        </li>
                    </ol>

                    <ol
                        start="9"
                        type="1"
                    >
                        <li>
                            <p >
                                <span >
                                    <span >
                                        <strong>
                                            MISCELLANEOUS PROVISIONS.
                                        </strong>
                                    </span>
                                </span>
                            </p>
                        </li>
                    </ol>

                    <ol type="a">
                        <li>
                            <p >
                                <span >
                                    <span >
                                        <strong>
                                            Third Party Content -

                                            {" "}
                                        </strong>
                                    </span>
                                </span>

                                <span >
                                    <span >
                                        We may provide links to other content such as websites, Services and apps. Unless expressly stated, this content is not under our control. We neither assume or accept responsibility or liability for such third-party content. The provision of a link by us is for reference only and does not imply any endorsement of the linked Content or of those in control of it. Please make sure you read the relevant terms as well as other relevant policies of these third parties.

                                        {" "}
                                    </span>
                                </span>
                            </p>
                        </li>

                        <li>
                            <p >
                                <span >
                                    <span >
                                        <strong>
                                            Anti-Corruption and Anti Money-Laundering
                                        </strong>
                                    </span>
                                </span>

                                <span >
                                    <span >
                                        {" "}

                                        - Users agree that in using our Website, they will not undertake any conduct that constitutes an offence under applicable anti-corruption and anti-money laundering laws. A breach of this clause shall be deemed a material breach of this Terms and Conditions of Service.
                                    </span>
                                </span>
                            </p>
                        </li>

                        <li>
                            <p >
                                <span >
                                    <span >
                                        <strong>
                                            Conflict between Terms

                                            {" "}
                                        </strong>
                                    </span>
                                </span>

                                <span >
                                    <span >
                                        &ndash;
                                        {" "}
                                    </span>
                                </span>
                            </p>

                            <ol type="i">
                                <li>
                                    <p >
                                        <span >
                                            <span >
                                                When there is a conflict between this Terms and Conditions of Service, Service Provider

                                                &rsquo;
                                                s Terms, or Booking Specific Terms, this Terms and Conditions shall take precedence when it relates to matters connected to Plan Seminars, our Services and our Website.

                                                {" "}
                                            </span>
                                        </span>
                                    </p>
                                </li>

                                <li>
                                    <p >
                                        <span >
                                            <span >
                                                When there is a conflict between any Service Provider Terms, the Booking Specific Terms, the Booking Specific Terms shall take precedence between Users unless agreed otherwise by the Users.

                                                {" "}
                                            </span>
                                        </span>
                                    </p>
                                </li>
                            </ol>
                        </li>

                        <li>
                            <p >
                                <span >
                                    <strong>
                                        Dispute between Users
                                    </strong>
                                </span>

                                <span >
                                    {" "}

                                    &ndash;
                                    {" All dispute between Users maybe referred to us at"}
                                </span>

                                {" "}

                                <span >
                                    <u>
                                        <a href="mailto:support@bjjseminars.com">
                                            <span >
                                                support@bjjseminars.com
                                            </span>
                                        </a>
                                    </u>
                                </span>

                                <span >
                                    , we will try our best to amicably resolve the issue, however we are not a judicial body and we can only offer amicable negotiation between the Users. Users reserve the right to pursue an action in court for any dispute with other Users.
                                </span>
                            </p>
                        </li>

                        <li>
                            <p >
                                <span >
                                    <span >
                                        <strong>
                                            Unsolicited Idea
                                        </strong>
                                    </span>
                                </span>

                                <span >
                                    <span >
                                        {" "}

                                        &ndash;
                                        {" You agree that all unsolicited comments and advice are non-confidential and non-proprietary. An irrevocable, worldwide, transferable and royalty-free license to use, exploit, distribute and display the unsolicited advice or communication is hereby granted to Us"}
                                    </span>
                                </span>
                            </p>
                        </li>

                        <li>
                            <p >
                                <a name="_heading=h.9gfzh9lur1i" />

                                {" "}

                                <span >
                                    <span >
                                        <strong>
                                            Severability

                                            {" "}
                                        </strong>
                                    </span>
                                </span>

                                <span >
                                    <span >
                                        - In the event that any provision of this Terms and Conditions of Service is held to be invalid or unenforceable in whole or in part, all other provisions will continue to be valid with the invalid and unenforceable parts severed from the remainder of this Terms and Conditions of Service.
                                    </span>
                                </span>
                            </p>
                        </li>

                        <li>
                            <p >
                                <a name="_heading=h.6qa594yhi01g" />

                                {" "}

                                <span >
                                    <span >
                                        <strong>
                                            Amendment
                                        </strong>
                                    </span>
                                </span>

                                <span >
                                    <span >
                                        {" "}

                                        - We may update these Terms and Conditions of Service from time to time by publishing a new version on our Website. The right to amend is at our sole discretion. You should check this page occasionally to ensure you understand any changes to this Terms and Conditions of Service. At our discretion, we may notify you of changes to this policy by email or through email or any other choice of communications as set by you.
                                    </span>
                                </span>
                            </p>
                        </li>

                        <li>
                            <p >
                                <a name="_heading=h.n8wal9qap4a6" />

                                {" "}

                                <span >
                                    <span >
                                        <strong>
                                            Notices

                                            {" "}
                                        </strong>
                                    </span>
                                </span>

                                <span >
                                    <span >
                                        - Unless otherwise stated in this Terms and Conditions of Service or any other relevant part of our Website, all notices and other communications shall be in writing and delivered by electronic mail. For Plan Seminars, the applicable email address is

                                        {" "}
                                    </span>
                                </span>

                                <span >
                                    <u>
                                        <a href="mailto:support@bjjseminars.com">
                                            <span >
                                                support@bjjseminars.com
                                            </span>
                                        </a>
                                    </u>
                                </span>

                                {" "}

                                <span >
                                    <span >
                                        unless another email address is provided on the relevant page on our Website or this Terms and Conditions of Service. For a User, the email address will be the email address provided when registering for an account or contacting us.

                                        {" "}
                                    </span>
                                </span>
                            </p>
                        </li>

                        <li>
                            <p >
                                <a name="_heading=h.g2130y0kxkz" />

                                {" "}

                                <span >
                                    <span >
                                        <strong>
                                            Waiver

                                            {" "}
                                        </strong>
                                    </span>
                                </span>

                                <span >
                                    <span >
                                        - No single or partial exercise of a right or remedy provided by this Terms and Conditions of Service or by law prevents Plan Seminars from the exercise of any right or remedy. A waiver of a breach of this Terms and Conditions of Service does not constitute a waiver of a subsequent or prior breach of this Terms and Conditions of Service.
                                    </span>
                                </span>
                            </p>
                        </li>

                        <li>
                            <p >
                                <a name="_heading=h.mm8kolru34l2" />

                                {" "}

                                <span >
                                    <span >
                                        <strong>
                                            Relationship Of the Parties

                                            {" "}
                                        </strong>
                                    </span>
                                </span>

                                <span >
                                    <span >
                                        - This Terms and Conditions of Service is intended and shall be construed as creating an Agreement for the purposes specified in this Terms and Conditions of Service. Nothing in this Terms and Conditions of Service or otherwise is intended or shall be construed as creating a partnership other than as specifically set out in this Terms and Conditions of Service or any legal entity between the Users and Plan Seminars or any ongoing or continuing relationship or commitment between the Users of Plan Seminars, other than as specifically set out in this Terms and Conditions of Service.
                                    </span>
                                </span>

                                <span >
                                    {" "}

                                    Except for sales tax for the Services, Service Providers are

                                    {" "}
                                </span>

                                <span >
                                    <span >
                                        at all times responsible for the collection, withholding, remittance, and payment of the applicable taxes due on the total amount of the price or fee to the relevant tax authorities. Plan Seminars shall not be liable or responsible for the remittance, collection, withholding or payment of the relevant taxes due on payments received or charged for the Service Provider Services to the relevant tax authorities. We do not act as the merchant of record for Service Provider Service made available on the Website.
                                    </span>
                                </span>
                            </p>
                        </li>

                        <li>
                            <p >
                                <a name="_heading=h.hn20fdptzlp5" />

                                <a name="_heading=h.h922exow6akl" />

                                {" "}

                                <span >
                                    <span >
                                        <strong>
                                            Governing Law
                                        </strong>
                                    </span>
                                </span>

                                <span >
                                    <span >
                                        {" "}

                                        - These Terms and Conditions of Service, the jurisdiction clause contained in it and any non-contractual obligations arising out of or in connection with it or its subject matter or formation are governed by, construed and take effect in accordance with the law of Denmark.
                                    </span>
                                </span>
                            </p>
                        </li>

                        <li id="9(l)">
                            <p >
                                <a name="_heading=h.qvjeuwv7seuh" />

                                {" "}

                                <span >
                                    <span >
                                        <strong>
                                            DISPUTE RESOLUTION
                                        </strong>
                                    </span>
                                </span>

                                <strong >
                                    <span >
                                        {" "}

                                        - N
                                    </span>
                                </strong>

                                <span >
                                    <span >
                                        <strong>
                                            OTWITHSTANDING THE PROVISIONS OF 13(L) FOR ANY AND ALL GRIEVANCES, DISPUTES, CLAIMS, OR CONTROVERSIES YOU MAY HAVE AGAINST Plan Seminars (

                                            &ldquo;
                                            DISPUTES

                                            &rdquo;
                                            ), BEFORE PURSUING ANY DISPUTE RESOLUTION AVENUE, YOU MUST FIRST GIVE US AN OPPORTUNITY TO RESOLVE THE DISPUTE INFORMALLY BY SENDING AN EMAIL TO

                                            {" "}
                                        </strong>
                                    </span>
                                </span>

                                <span >
                                    <u>
                                        <a href="mailto:support@bjjseminars.com">
                                            <span >
                                                support@bjjseminars.com
                                            </span>
                                        </a>
                                    </u>
                                </span>

                                {" "}

                                <span >
                                    <span >
                                        <strong>
                                            {"WITH THE SUBJECT "}
                                            &ldquo;
                                        </strong>
                                    </span>
                                </span>

                                <span >
                                    <span >
                                        <u>
                                            <strong>
                                                PRE-ACTION DISPUTE NOTICE
                                            </strong>
                                        </u>
                                    </span>
                                </span>

                                <span >
                                    <span >
                                        <strong>
                                            &rdquo;
                                            . THE EMAIL SHOULD CONTAIN YOUR NAME, YOUR EMAIL ADDRESS AS REGISTERED ON YOUR ACCOUNT (IF YOU HAVE NO ACCOUNT, PLEASE STATE THAT), A DETAILED DESCRIPTION OF YOUR GRIEVANCE AND CLAIMS, AND A DESCRIPTION OF THE SPECIFIC RELIEF YOU SEEK AND HOW WE CAN RESOLVE THIS GRIEVANCE. IF WE DO NOT RESOLVE THE DISPUTE WITHIN SIXTY (60) DAYS AFTER RECEIVING YOUR PRE-ACTION DISPUTE NOTICE, THEN YOU MAY PURSUE RESOLUTION OF THE DISPUTE IN ACCORDANCE TO SECTION 9(m).

                                            {" "}
                                        </strong>
                                    </span>
                                </span>
                            </p>
                        </li>

                        <li>
                            <p >
                                <a name="_heading=h.lka1pfkyfezr" />

                                {" "}

                                <span >
                                    <span >
                                        Subject to section 9(l), the parties agree that any dispute arising out of or in connection with this Terms and Conditions of Service or the performance, validity or enforceability of it will be finally resolved by the courts of Denmark, who have exclusive jurisdiction to settle any dispute or claim (including non-contractual disputes or claims) arising out of or in connection with this Agreement or its subject matter or formation.
                                    </span>
                                </span>
                            </p>
                        </li>

                        <li id="9(n)">
                            <p >
                                <a name="_heading=h.oxss3qhwe6up" />

                                {" "}

                                <span >
                                    <span >
                                        <strong>
                                            {"CLASS ACTION WAIVER "}

                                            &ndash;
                                            {" THE PARTIES AGREE THAT ANY PROCEEDINGS TO RESOLVE OR LITIGATE ANY DISPUTE WILL BE CONDUCTED SOLELY ON AN INDIVIDUAL BASIS. NEITHER PARTY WILL SEEK TO HAVE ANY DISPUTE HEARD AS A CLASS ACTION, A REPRESENTATIVE ACTION, A COLLECTIVE ACTION, OR IN ANY PROCEEDING IN WHICH EITHER PARTY ACTS OR PROPOSES TO ACT IN A REPRESENTATIVE CAPACITY. THE PARTIES FURTHER AGREE THAT NO PROCEEDING WILL BE JOINED, CONSOLIDATED, OR COMBINED WITH ANOTHER PROCEEDING WITHOUT THE PRIOR WRITTEN CONSENT OF THE PARTIES, AND ALL OTHER PARTIES TO ANY SUCH PROCEEDING."}
                                        </strong>
                                    </span>
                                </span>
                            </p>
                        </li>

                        <li>
                            <p >
                                <a name="_heading=h.mwbemmvhrvyy" />

                                {" "}

                                <span >
                                    <span >
                                        <strong>
                                            {"Indemnity "}
                                            &ndash;

                                            {" "}
                                        </strong>
                                    </span>
                                </span>

                                <span >
                                    <span >
                                        Users agree to indemnify and hold harmless Plan Seminars, directors, officers, employees, consultants, agents, and affiliates against all losses, costs, liability, damages and/or costs (including, but not limited to, legal fees) arising from, without limitation, your breach of these terms and conditions of service, your infringement of any intellectual property right or any other right of any person or entity, or your breach of any duty of confidence or privacy, or any defamatory statements made by you in any form.
                                    </span>
                                </span>
                            </p>
                        </li>

                        <li>
                            <p >
                                <a name="_heading=h.gmbc4cw1lomp" />

                                {" "}

                                <span >
                                    <span >
                                        <strong>
                                            User Warranties.

                                            {" "}
                                        </strong>
                                    </span>
                                </span>
                            </p>

                            <ol type="i">
                                <li>
                                    <p >
                                        <a name="_heading=h.vyks7bumwoiw" />

                                        {" "}

                                        <span >
                                            <span >
                                                You understand and agree that your use of our Website and/or the Services is at your own risk;
                                            </span>
                                        </span>
                                    </p>
                                </li>

                                <li>
                                    <p >
                                        <a name="_heading=h.bkrqg6mnntg" />

                                        {" "}

                                        <span >
                                            <span >
                                                {"our Website and our Services are provided on an "}

                                                &ldquo;
                                                as is

                                                &rdquo;
                                                {" and "}

                                                &ldquo;
                                                as available

                                                &rdquo;
                                                {" basis without warranties or conditions of any kind, either express or implied (to the maximum extent permitted by applicable law)"}
                                            </span>
                                        </span>
                                    </p>
                                </li>
                            </ol>
                        </li>

                        <li>
                            <p >
                                <a name="_heading=h.2q424xd6psbl" />

                                <a name="_heading=h.sn28r999azot" />

                                {" "}

                                <span >
                                    <span >
                                        <strong>
                                            LIMITATION OF LIABILITY -

                                            {" "}
                                        </strong>
                                    </span>
                                </span>
                            </p>

                            <ol type="i">
                                <li>
                                    <p >
                                        <a name="_heading=h.58juprub0xrf" />

                                        {" "}

                                        <span >
                                            <span >
                                                To the fullest extent permissible by law, we accept no liability to any user for any loss or damages, direct, indirect, incidental, special or consequential, whether foreseeable or otherwise, in contract, tort (including negligence), for breach of statutory duty, or otherwise, arising out of or in connection with the use of (or inability to use) our Website or the use of or reliance upon any formulas, tools or templates included in our materials or otherwise made available via our Website. You agree that our total cumulative liability in connection with these Terms and Conditions of Service, our Website, our Services, our Content, or any listing or services whether in contract, tort, or otherwise, shall not exceed the total cost of the booking as stated in the booking confirmation, whether arising from one event or a series of connected events.
                                            </span>
                                        </span>
                                    </p>
                                </li>

                                <li>
                                    <p >
                                        <a name="_heading=h.8zs4sdosscps" />

                                        {" "}

                                        <span >
                                            <span >
                                                Nothing in these Terms and Conditions of Service limits any liability which cannot legally be limited, including, but not limited to, liability for:
                                            </span>
                                        </span>
                                    </p>

                                    <ul className="arrow-list">
                                        <li>
                                            <p >
                                                <a name="_heading=h.ez4mb49k4i4" />

                                                {" "}

                                                <span >
                                                    <span >
                                                        death or personal injury caused by negligence;
                                                    </span>
                                                </span>
                                            </p>
                                        </li>

                                        <li>
                                            <p >
                                                <a name="_heading=h.guxwue2nojh" />

                                                {" "}

                                                <span >
                                                    <span >
                                                        fraud or fraudulent misrepresentation; and
                                                    </span>
                                                </span>
                                            </p>
                                        </li>

                                        <li>
                                            <p >
                                                <a name="_heading=h.7290pq8p3o21" />

                                                {" "}

                                                <span >
                                                    <span >
                                                        any liabilities that may not be excluded under applicable law.
                                                    </span>
                                                </span>
                                            </p>
                                        </li>
                                    </ul>
                                </li>
                            </ol>
                        </li>

                        <li>
                            <p >
                                <a name="_heading=h.2lvkl4rfqlsg" />

                                <a name="_heading=h.o0otnjqdtdpl" />

                                {" "}

                                <span >
                                    <span >
                                        <strong>
                                            Confidentiality

                                            {" "}
                                        </strong>
                                    </span>
                                </span>

                                <span >
                                    <span >
                                        {"- Except to the extent required by law, any legal or regulatory authority of competent jurisdiction or, with the prior written consent of the other Party. No party shall use or disclose to any third party such information belonging to the other Party. This paragraph shall survive the termination of this Terms and Conditions of Service. For the avoidance of doubt, Personal Information shall be treated in accordance with applicable laws and shall not be considered "}

                                        &ldquo;
                                        confidential information

                                        &rdquo;
                                        {" belonging to a party."}
                                    </span>
                                </span>
                            </p>
                        </li>

                        <li>
                            <p >
                                <a name="_heading=h.wztgrzgu6wlx" />

                                <a name="_heading=h.irr64xh6n6v0" />

                                <a name="_heading=h.31rgmhmyiruk" />

                                {" "}

                                <span >
                                    <span >
                                        <strong>
                                            Headings

                                            {" "}
                                        </strong>
                                    </span>
                                </span>

                                <span >
                                    <span >
                                        - The paragraph titles in this Terms and Conditions of Service are for convenience only and shall not define or limit any of the provisions hereof.
                                    </span>
                                </span>
                            </p>
                        </li>
                    </ol>

                    <h1 >
                        <a name="_heading=h.16ubyjvtbrmx" />

                        {" "}

                        <span >
                            <span >
                                14.

                                {" "}
                            </span>
                        </span>

                        <span >
                            <span >
                                <strong>
                                    CONTACTING US.
                                </strong>
                            </span>
                        </span>
                    </h1>

                    <p className="ml-20">
                        <span >
                            You can reach us at

                            {" "}
                        </span>

                        <span >
                            <u>
                                <a href="mailto:support@bjjseminars.com">
                                    <span >
                                        support@bjjseminars.com
                                    </span>
                                </a>
                            </u>
                        </span>

                        <span >
                            . You can also contact us through our registered address, Svanemoellevej 41 2900 Hellerup, Copenhagen, Denmark.
                        </span>
                    </p>
                </div>
            </div>
        </section>
    );
};

export default TermsAndConditions;
