import React, {
    useEffect, useState
} from "react";
import {
    useDispatch, useSelector
} from "react-redux";
import InstructorSeminarCard from "../../../components/parts/cards/InstructorSeminarCard";
import Loader from "../../../components/ui/Loader";
import { getInstructorsAllFetch } from "../../../redux/thunks/instructorsThunks";

const SinglePageInstructorsBlock = ({ data = [] }) => {
    // const dispatch = useDispatch()
    // const { instructors, isLoading } = useSelector(state => state.instructors)
    // const [currentInstructors, setCurrentInstructors] = useState([]) 

    // useEffect(() => {
    //     dispatch(getInstructorsAllFetch("no_limit=true"))
    // }, []) 

    // useEffect(() => {
    //     if (!data.length || !instructors?.length) return
    //     const ids = data.map(item => item?.userId)
    //     const res = instructors
    //         .filter(instructor => ids.includes(instructor?.user?.id))
    //     setCurrentInstructors(res)
    // }, [instructors, data]) 

    // console.log(data);

    // if (isLoading) return <Loader />

    return (
        <>
            <div
                className={`${data?.length
                    ? "border-bottom pb-42 mb-42"
                    : ""}`}
            >
                <h4 className="heading mb-32">
                    Instructor(S)
                </h4>

                {
                    data?.length
                        ? (
                            <ul className="row gx-32 gy-32">
                                {
                                    data.map((item, index) => (
                                        <li
                                            key={index}
                                            className="col-lg-6"
                                        >
                                            <InstructorSeminarCard data={item} />
                                        </li>
                                    )



                                    )
                                }
                            </ul>
                        )


                        : (
                            <h4>
                                There is no data
                            </h4>
                        )


                }
            </div>

            {
                data?.length
                    ? data?.filter((item) => item?.user?.instructorProfile?.bio)?.map((item, index) => (
                        <React.Fragment key={index}>
                            <h4 className="heading mb-32">
                                About{item?.user?.instructorProfile?.fullName}
                            </h4>

                            <p className="text-description mb-42">
                                {item?.user?.instructorProfile?.bio}
                            </p>
                        </React.Fragment>
                    )



                    )
                    : null
            }

        </>

    // <>
    //     <div className={`${currentInstructors?.length ? "border-bottom pb-42 mb-42" : ""}`}>
    //         <h4 className="heading mb-32">Instructor(S)</h4>
    //         {
    //             currentInstructors?.length ?
    //                 <ul className="row gx-32 gy-32">
    //                     {
    //                         currentInstructors.map((item, index) => (
    //                             <li key={index} className="col-6">
    //                                 <InstructorSeminarCard data={item} />
    //                             </li> 

    //                   ))
    //                     }
    //                 </ul>
    //                 : <h4>There is no data</h4>
    //         }
    //     </div>
    //     {
    //         currentInstructors?.length ?
    //             currentInstructors.map((item, index) => (
    //                 <React.Fragment key={index}>
    //                     <h4 className="heading mb-32">{item?.fullName}</h4>
    //                     <p className="text-description mb-42">David Dotzsky is a highly committed black belt Brazilian Jiu Jitsu coach with a passion for martial arts dating back to 1998. He has participated in sports jujutsu and judo but found his true calling in BJJ. In 2014, he achieved a significant milestone, obtaining his black belt in BJJ from world-renowned Alan "Finfou".</p>
    //                 </React.Fragment>
    //             ))
    //             : null
    //     } 

    // </>
    );
};

export default SinglePageInstructorsBlock;
