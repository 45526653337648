
import Input from "../../../components/ui/formElements/Input";
import { ReactComponent as PlusIcon } from "../../../assets/img/ico-plus.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/img/ico-trash.svg";
import Button from "../../../components/ui/buttons/Button";
import { useEffect } from "react";

const HotelInfoSection = ({ data = [], handleChange, errors, isErrorsShown }) => {
    const handleHotelLinkAdd = () => {
        handleChange([...data, ""]);
    };

    const handleHotelLinkDelete = (index) => {
        handleChange(data?.filter((item, i) => i !== index));
    };

    const handleHotelLinkChange = (index, value) => {
        const updatedhotelLinks = [...data];

        updatedhotelLinks[index] = value;
        handleChange(updatedhotelLinks);
    };

    useEffect(() => {
        if (!data?.length) {
            handleHotelLinkAdd();
        }
    }, [data]);

    return (
        <div className="form-group input--lg pb-32 border-bottom">
            <label className="label">
                hotel link
            </label>

            {data?.length
                ? data.map((link, index) => (
                    <div
                        key={index}
                        className="flex items-center gap-12 w-full"
                    >
                        <Input
                            error={isErrorsShown && errors[index]}
                            inputClasses="input--solid"
                            onChange={(e) => handleHotelLinkChange(index, e.target.value)}
                            placeholder="www.hotelwebsite.com"
                            value={link}
                            wrapperClasses="input--lg flex-auto"
                        />

                        {
                            index >= 1
                                ? (
                                    <Button
                                        onClick={() => handleHotelLinkDelete(index)}
                                        className="btn btn--outline-default  btn--square btn--md rounded-full"
                                    >
                                        <DeleteIcon />
                                    </Button>
                                )
                                : (
                                    <Button
                                        onClick={handleHotelLinkAdd}
                                        className="btn btn--outline-default  btn--square btn--md rounded-full"
                                    >
                                        <PlusIcon />
                                    </Button>
                                )
                        }
                    </div>
                )



                )
                : null}
        </div>
    );
};

export default HotelInfoSection;
