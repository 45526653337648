import React from "react";
import { Link } from "react-router-dom";
import pdf from "../assets/Privacy and Cookies Policy - bjj NEW.pdf";

const PrivacyPolicy = () => {
    const handleClick = (e) => {
        e.preventDefault();
        const link = document.createElement("a");

        link.href = pdf;
        link.download = "your-file-name.pdf"; // Set desired file name
        link.click();
    };

    return (
        <section className="section">
            <div className="container">
                {/* <h2 className="heading text-center mb-44">Privacy Policy</h2> */}
                <div className="card-shadow document-card mb-42 terms-doc">
                    <div className="document-card__header">
                        <h3 className="heading text-center mb-44">
                            PRIVACY AND COOKIES POLICY
                        </h3>
                    </div>

                    <p>
                        This Privacy Policy was published on 05.08.2024 and last updated on 10.07.2024. The PDF copy of this Policy can be downloaded

                        <Link onClick={handleClick}>
                            here
                        </Link>

                        .
                    </p>

                    <ol>
                        <li>
                            <h1>
                                INTRODUCTION.
                            </h1>
                        </li>
                    </ol>

                    <ol type="a">
                        <li>
                            <p>
                                {"At Plan Seminars ApS (trading under the name Plan Seminars) (For the purpose of this policy document, we are also referred to as "}

                                &ldquo;
                                Plan Seminars

                                &rdquo;
                                {" "}

                                &ldquo;
                                we

                                &rdquo;
                                {" "}

                                &ldquo;
                                our website

                                &rdquo;
                                {" "}

                                &ldquo;
                                our platform

                                &rdquo;
                                {" and "}

                                &ldquo;
                                us

                                &rdquo;
                                ).
                            </p>
                        </li>

                        <li>
                            <p>
                                we are dedicated to the privacy of our website and platform visitors and users. We are committed to protecting and safeguarding your Personal Data. This privacy and cookies policy gives you an insight into how we collect and use your data to maximize your experience on our Platform. It shows how we collect, use, protect the information collected and your rights regarding your privacy.
                            </p>
                        </li>

                        <li>
                            <p>
                                This policy describes how

                                <a href="http://www.bjjseminars.com/">
                                    <u>
                                        www.BJJSeminars.com
                                    </u>
                                </a>

                                {" "}

                                {"(the "}

                                &ldquo;
                                site

                                &rdquo;
                                {" "}

                                &ldquo;
                                website

                                &rdquo;
                                {" and/or "}

                                &ldquo;
                                we

                                &rdquo;
                                ) collects, uses, and discloses your Personal Data when you visit or make a purchase from the site.

                                {" "}
                            </p>
                        </li>

                        <li>
                            <p>
                                By using our website or any of our services, you consent to the use of your Personal Data in the manner contained in this policy, to the extent permitted by law. Please read this policy carefully and note that the English version of this policy is binding, all other translations are only for convenience purposes.
                            </p>
                        </li>

                        <li>
                            <p>
                                Please note that if you are in the European Economic Area, United Kingdom, Switzerland or any other country that the EU General Data Protection Regulation 2016/679 (

                                &ldquo;
                                GDPR

                                &rdquo;
                                ) and Data Protection Act of 2018 (DPA), you will not be required to consent to this policy, however, by using Plan Seminars, you acknowledge that you have read and understood its terms. The provisions of 14 of this policy apply to you specifically.
                            </p>
                        </li>

                        <li>
                            <p>
                                Please note that if you live in California, the California Consumer Privacy Act applies to you. The provisions of Section 15 of this policy applies to you specifically.
                            </p>
                        </li>

                        <li>
                            <p>
                                For the purpose of this policy,
                            </p>

                            <ol type="i">
                                <li>
                                    <p>
                                        &ldquo;
                                        <strong>
                                            Academies
                                            &rdquo;
                                        </strong>

                                        {" "}

                                        means corporate bodies and training centers who signs up as Academies on our website and who represents themselves as a body training, teaching Brazilian jiu-jitsu and/or related services.

                                        {" "}
                                    </p>
                                </li>

                                <li>
                                    <p>
                                        &ldquo;
                                        <strong>
                                            Instructors
                                        </strong>

                                        &rdquo;
                                        {" means individuals who signs up as Instructors on our website and represents himself/herself as a trainer or person who teaches Brazilian jiu-jitsu and/or related services."}

                                        {" "}
                                    </p>
                                </li>

                                <li>
                                    <p>
                                        &ldquo;
                                        <strong>
                                            Students
                                            &rdquo;
                                        </strong>

                                        {" "}

                                        means persons connecting, taking or seeking to find Brazilian jiu-jitsu seminars, gathering or related activities using our website.

                                        {" "}
                                    </p>
                                </li>
                            </ol>

                            <p>
                                Words denoting the singular shall include the plural and vice versa.
                            </p>
                        </li>
                    </ol>

                    <ol
                        start="8"
                        type="a"
                    >
                        <li>
                            <p>
                                This policy should be read and used together with our

                                <Link to="/terms-of-use">
                                    Website Terms of Use
                                </Link>

                                {" "}

                                and

                                <Link to="/terms-of-service">
                                    Terms and Conditions of Service
                                </Link>

                                .
                            </p>
                        </li>
                    </ol>

                    <ol start="2">
                        <li>
                            <h1>
                                ABOUT US
                            </h1>
                        </li>
                    </ol>

                    <p>
                        We are a platform that introduces Brazilian Jiu-Jitsu Students to Instructors, Instructors to Academies, Academies to Instructors and also interested users to Brazilian Jiu-Jitsu Seminars. We simplify booking and organizing of Brazilian Jiu-Jitsu seminars. Instructors and Academies can plan, share, and join seminars. Students can easily view, book, and pay for seminars and find Instructors for private lessons. The platform manages seminar details, attendance requirements, private lessons bookings, and payments to ensure a smooth experience for all users. We are based in Hellerup, Denmark. Regarding any issue related and connected to your privacy on our platform, we can be contacted at

                        <a href="mailto:support@bjjseminars.com">
                            <u>
                                support@bjjseminars.com
                            </u>
                        </a>

                        . We have a dedicated team who would endeavour to respond to your email timeously.

                        {" "}
                    </p>

                    <ol start="3">
                        <li>
                            <h1>
                                WHAT IS PERSONAL DATA
                            </h1>
                        </li>
                    </ol>

                    <p>
                        Personal Data includes information relating to natural persons that allow the natural person to be directly or indirectly identified through the information.
                    </p>

                    <ol start="4">
                        <li>
                            <h1>
                                HOW WE COLLECT YOUR INFORMATION.
                            </h1>
                        </li>
                    </ol>

                    <p>
                        To maximize your experience on our website, we collect information in the following ways:
                    </p>

                    <ol type="a">
                        <li>
                            <p>
                                When you provide them to us either by access, use or purchasing our services, registering for our platform (including but not limited to when you connect your profile on a third-party platform with your registration details), request certain information from us, join or participate in any of our promotional services, use our review, ratings or scoring feature, interact with any of our social media platforms, access any third party links on our platform or interact with us either online or offline in any manner whatsoever.
                            </p>
                        </li>

                        <li>
                            <p>
                                We also passively collect information when you use our service. This collection of information can be from information in your browser or device.
                            </p>
                        </li>

                        <li>
                            <p>
                                As you interact with any of our platforms, we may automatically collect technical information about your equipment, browsing actions and patterns. We collect this Personal Data by using cookies, server logs and other similar technologies. We may also receive technical information about you if you visit other websites or when you visit our sites, or use our sites on third-party sites or platforms using one or more devices that employ our cookies whether or not you are logged in or registered. To disable cookies, please refer to our

                                <Link to="#cookies">
                                    Cookies Policy
                                </Link>

                                {" "}

                                below.
                            </p>
                        </li>

                        <li>
                            <p>
                                In line with our

                                <Link to="#cookies">
                                    Cookies Policy
                                </Link>

                                , Plan Seminars may use cookies to track user traffic patterns in order to create user

                                &rsquo;
                                s personalized/targeted services, offers, promotions, adverts, products, and capture trends, in accordance with our services. For safety and security purposes, service improvement, and data collection, we may use software that receives and records the Internet Protocol (IP) addresses of the computer

                                &rsquo;
                                s users used to interact/contact our platform. We do not attempt to link these addresses with the identity of individuals visiting our platform.
                            </p>
                        </li>

                        <li>
                            <p>
                                We also use social media tools or third-party applications to collect information, especially when you join our platform or use our services through these third party or social media platforms. To prevent us from sharing your information with these third-party platforms, please do not access our services through them. It is also advisable that you read the privacy policy of these third-party platforms to understand how your privacy is ensured by them.
                            </p>
                        </li>
                    </ol>

                    <ol start="5">
                        <li>
                            <h1>
                                INFORMATION WE COLLECT.
                            </h1>
                        </li>
                    </ol>

                    <p>
                        Through the mediums stated in Section 3, either from those provided to us by you, through third parties or through passive and automated means, we collect the following information from you.
                    </p>

                    <p>
                        <u>
                            <strong>
                                Transaction information
                            </strong>
                        </u>
                    </p>

                    <ol type="a">
                        <li>
                            <p>
                                Examples of this information are
                            </p>

                            <ol type="i">
                                <li>
                                    <p>
                                        <strong>
                                            Registration Information:

                                            {" "}
                                        </strong>

                                        This is the collection of information when you register on our website. For every User, we collect log-in details which may include but are not limited to name, email address, postcode, contact details and any other information you choose to share with us such as (bio, your description, identity etc.). If you are an Instructor or an Academy, in addition to the already-listed information, we may collect verification information (including but not limited to the proof of your qualification or competence to carry out your trade, your business and trade name (if applicable) and other information you share with us such as profile pictures, certifications and other awards as applicable. On profile picture, this image may be shown as a thumbnail next to some of your activities on our website. Please note that this image is not frozen in time, rather it is of a dynamic nature. For example, If you change your image in 2026, it is the new image that will appear next to your activities from 2024, not that from 2024.
                                    </p>
                                </li>

                                <li>
                                    <p>
                                        <strong>
                                            Compliance Information
                                        </strong>

                                        : This includes Information needed for compliance purposes as required for our services. This information might include your government ID and others as needed or required by law. For Instructors or Academies, these might include your verification documents and certifications.
                                    </p>
                                </li>

                                <li>
                                    <p>
                                        <strong>
                                            Transaction Information:

                                            {" "}
                                        </strong>

                                        We collect this information when you use our services, conduct transactions on our website, contact us about a transaction, live chat communications between users on our website etc. The information that will be collected includes but are not limited to payment information, contact information such as your phone number, email address, mailing address, any other information you provide to us.
                                    </p>
                                </li>

                                <li>
                                    <p>
                                        <strong>
                                            Financial Information
                                        </strong>

                                        {" "}

                                        - This includes financial information such as payment details and in case of Instructors or Academies, bank accounts in order to remit the payment for the seminars (minus our fee) rendered to Students as organized on our website. Please note that in many cases, Plan Seminars cannot view or access all of the information you provide to us, such as complete bank account numbers or payment information, as this may be transmitted in an automated manner directly to the vendor that requires that compliance information.
                                    </p>
                                </li>

                                <li>
                                    <p>
                                        <strong>
                                            Location Information
                                        </strong>

                                        {" "}

                                        - This include information about your location provided by the device used by you to interact with our website, or associated with your IP address, where we are permitted by law to process this information.
                                    </p>
                                </li>
                            </ol>
                        </li>

                        <li>
                            <p>
                                <strong>
                                    Purpose of collection:
                                </strong>

                                {" "}

                                to provide products or services to you to fulfil our contract, to process your payment information, give you authorization to our tools and services, and provide you with invoices and/or transaction confirmations, communicate with you, screen our transactions for potential risk or fraud, and when in line with the preferences you have shared with us, provide you with information or advertising relating to our products or services.
                            </p>
                        </li>

                        <li>
                            <p>
                                <strong>
                                    Source of collection:
                                </strong>

                                {" "}

                                collected from you.
                            </p>
                        </li>

                        <li>
                            <p>
                                <strong>
                                    {"Legal Basis for Processing the Personal Data listed above "}
                                    &ndash;
                                </strong>
                            </p>

                            <ol type="i">
                                <li>
                                    <p>
                                        <strong>
                                            Contract
                                        </strong>

                                        {" "}

                                        - To fulfil a contract to which you are a party to and to take steps as requested by you prior to entering into the contract.
                                    </p>
                                </li>

                                <li>
                                    <p>
                                        <strong>
                                            Legitimate Interests
                                        </strong>

                                        {" "}

                                        - To protect our legitimate interest or the interests of a third party.
                                    </p>
                                </li>

                                <li>
                                    <p>
                                        <strong>
                                            Consent
                                        </strong>

                                        {" "}

                                        - With your clear consent which is expressed when you use our website or sign-up to our Services. You can withdraw your consent at any time by contacting us on our

                                        <Link to="/contact-us">
                                            Contact Us
                                        </Link>

                                        {" "}

                                        page. If you withdraw your consent, we will stop processing your Personal Data immediately. However, please note that if we have another legal basis for processing your Personal Data, we may continue to do so.
                                    </p>
                                </li>
                            </ol>
                        </li>
                    </ol>

                    <p>
                        <u>
                            <strong>
                                Device Information
                            </strong>
                        </u>
                    </p>

                    <ol type="a">
                        <li>
                            <p>
                                Examples of this information are
                            </p>

                            <ol type="i">
                                <li>
                                    <p>
                                        Information about your visits to and use of our platform including the referral source, length of visit, page views, and website navigation paths.
                                    </p>
                                </li>

                                <li>
                                    <p>
                                        <strong>
                                            Location Information:

                                            {" "}
                                        </strong>

                                        This is information about your location provided by a mobile or other device interacting with our platforms, or associated with your IP address, where we are permitted by law to process this information.
                                    </p>
                                </li>

                                <li>
                                    <p>
                                        {"Usage, viewing, technical, and device data when you visit our sites, or use our sites on third-party sites or platforms, or open emails we send, including your browser or device type, unique device identifier, and IP address. We collect some of this information by using "}

                                        &ldquo;
                                        cookies

                                        &rdquo;
                                        {" or other similar technologies directly from your device."}
                                    </p>
                                </li>
                            </ol>
                        </li>

                        <li>
                            <p>
                                <strong>
                                    Purpose of collection:
                                </strong>

                                {" "}

                                to load the site accurately for you, and to perform analytics on site usage to optimize our site.
                            </p>
                        </li>

                        <li>
                            <p>
                                <strong>
                                    Source of collection:

                                    {" "}
                                </strong>

                                Collected automatically when you access our site using cookies, log files, web beacons, tags, or pixels.
                            </p>
                        </li>

                        <li>
                            <p>
                                <strong>
                                    {"Legal Basis for Processing the Personal Data listed above "}
                                    &ndash;
                                </strong>
                            </p>

                            <ol type="i">
                                <li>
                                    <p>
                                        <strong>
                                            Contract
                                        </strong>

                                        {" "}

                                        - To fulfil a contract to which you are a party to and to take steps as requested by you prior to entering into the contract.
                                    </p>
                                </li>

                                <li>
                                    <p>
                                        <strong>
                                            Consent
                                        </strong>

                                        {" "}

                                        - With your clear consent which is expressed when you use our website or acceptance of our Cookies. You can withdraw your consent at any time by contacting us on our

                                        <Link to="/contact-us">
                                            Contact Us
                                        </Link>

                                        {" "}

                                        page and/or following the instructions in our

                                        <Link to="#cookies">
                                            Cookies Policy
                                        </Link>

                                        . If you withdraw your consent, we will stop processing your Personal Data immediately. However, please note that if we have another legal basis for processing your Personal Data, we may continue to do so.
                                    </p>
                                </li>
                            </ol>
                        </li>
                    </ol>

                    <p>
                        <u>
                            <strong>
                                Other Information
                            </strong>
                        </u>
                    </p>

                    <ol type="a">
                        <li>
                            <p>
                                Examples of this information are
                            </p>

                            <ol type="i">
                                <li>
                                    <p>
                                        Information, such as your name and email address, that you enter in order to set up subscriptions to our emails, promotional and campaign updates and/or newsletters.
                                    </p>
                                </li>

                                <li>
                                    <p>
                                        Information that you post to our website with the intention of publishing it on the internet, which includes your username, and the content of your posts, such as reviews.
                                    </p>
                                </li>

                                <li>
                                    <p>
                                        <strong>
                                            Customer Service Information
                                        </strong>

                                        {" "}

                                        - Information contained in any communications that you send to us by email or through our website, including its communication content and metadata.
                                    </p>
                                </li>

                                <li>
                                    <p>
                                        Activity information about your use, and the use by any person(s) you authorize through your account, of our sites, such as the content you view or post, how often you use our services, and your preferences.
                                    </p>
                                </li>
                            </ol>
                        </li>

                        <li>
                            <p>
                                <strong>
                                    Purpose of collection
                                </strong>

                                : to perform our services to you
                            </p>
                        </li>

                        <li>
                            <p>
                                <strong>
                                    Source of collection:

                                    {" "}
                                </strong>

                                collected from you.
                            </p>
                        </li>

                        <li>
                            <p>
                                <strong>
                                    {"Legal Basis for Processing the Personal Data listed above "}
                                    &ndash;
                                </strong>
                            </p>

                            <ol type="i">
                                <li>
                                    <p>
                                        <strong>
                                            Contract
                                        </strong>

                                        {" "}

                                        - To fulfil a contract to which you are a party to and to take steps as requested by you prior to entering into the contract.
                                    </p>
                                </li>

                                <li>
                                    <p>
                                        <strong>
                                            Legitimate Interests
                                        </strong>

                                        {" "}

                                        - To protect our legitimate interest or the interests of a third party.
                                    </p>
                                </li>

                                <li>
                                    <p>
                                        <strong>
                                            Consent
                                        </strong>

                                        {" "}

                                        - With your clear consent which is expressed when you use our website or sign-up to our Services. You can withdraw your consent at any time by contacting us on our

                                        <Link to="/contact-us">
                                            Contact Us
                                        </Link>

                                        {" "}

                                        page. If you withdraw your consent, we will stop processing your Personal Data immediately. However, please note that if we have another legal basis for processing your Personal Data, we may continue to do so.
                                    </p>
                                </li>
                            </ol>
                        </li>
                    </ol>

                    <p>
                        We do not collect any special categories of Personal Data about you (this includes details about your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade union membership, information about your health and genetic and biometric data). If you provide this information in our public forums such as reviews, we might not be able to protect such information. Please know that we will in no circumstance ask for this special category of Personal Data.
                    </p>

                    <p>
                        You are not required to provide Personal Data to us. It is within your right to decline any request to provide information to us. However, your failure to do so may affect our ability to provide the services you request. It might also limit the use of our platform to its full potential. If you have an account with us, you may also review, change or remove certain types of your information provided to us by accessing your account section of the website.
                    </p>

                    <ol start="6">
                        <li>
                            <h1>
                                HOW WE USE THE INFORMATION COLLECTED FROM YOU.
                            </h1>
                        </li>
                    </ol>

                    <p>
                        In line with regulatory provisions, applicable laws, your preferences as set on our platform or third-party platforms, we use information collected from you or related devices in the following manner or any other manner as stated on the relevant pages of our platform:
                    </p>

                    <ol type="a">
                        <li>
                            <p>
                                Administering, improving or promoting our services and ensuring that we maximize the potentials of our platform for your use.
                            </p>
                        </li>

                        <li>
                            <p>
                                To connect Students with Instructors or Academies.
                            </p>
                        </li>

                        <li>
                            <p>
                                To inform students about Instructors or Academies so they can make an informed decision about them, their services (in the case of Instructors) and the event (in case of Academies).
                            </p>
                        </li>

                        <li>
                            <p>
                                To verify user accounts and the information provided to us by users.
                            </p>
                        </li>

                        <li>
                            <p>
                                Personalizing the use of our platform as selected and set by you.
                            </p>
                        </li>

                        <li>
                            <p>
                                For processing purchases, transactions, understanding your interests and doing all that is needed and important to carry out our services to you.
                            </p>
                        </li>

                        <li>
                            <p>
                                Internal use by our staff and contractors to ensure the maximization of your use of our platforms.
                            </p>
                        </li>

                        <li>
                            <p>
                                Analysing data usage trends and preferences in order to improve the accuracy, effectiveness, security, usability or popularity of our Services.
                            </p>
                        </li>

                        <li>
                            <p>
                                Communicating with you regarding a) marketing communications relating to our business or the businesses of carefully-selected third parties which we think may be of interest to you (you can inform us at any time if you no longer require marketing communications either by clicking unsubscribe at the bottom of the email or contacting us through our

                                <Link to="/contact-us">
                                    Contact Us
                                </Link>

                                {" "}

                                page); b) your account or transactions on our platform; (c) send you Know Your Customer (KYC) information or request feedback about features on our platform; (d) notify you of changes to our policies; and (e) sending you non-marketing commercial communications such as dealing with inquiries and complaints made by or about you relating to our platform or any other communication to us from you.
                            </p>
                        </li>

                        <li>
                            <p>
                                Technical activities, such as bug detection and error reporting, etc.
                            </p>
                        </li>

                        <li>
                            <p>
                                Providing third parties with statistical information about our users (but those third parties will not be able to identify any individual user from that information);
                            </p>
                        </li>

                        <li>
                            <p>
                                Verifying compliance with the

                                <Link to="/terms-of-use">
                                    Website Terms of Use
                                </Link>

                                , and the

                                <Link to="/terms-of-service">
                                    Terms and Conditions of Service
                                </Link>

                                {" "}

                                governing the use of our website and services; and
                            </p>
                        </li>

                        <li>
                            <p>
                                Security, Fraud, and Legal Compliance, including to detect, investigate and prevent activities that may violate our policies or be illegal. We may review, scan, or analyse your communications on our website between users for fraud prevention, risk assessment, regulatory compliance, investigation, product development, research, analytics, and customer support purposes. In some cases, we may also scan, review, or analyse messages to debug, improve, and expand product offerings. We use automated methods where reasonably possible. However, occasionally we may need to manually review some communications, such as for fraud investigations and service support, or to assess and improve the functionality of automated tools. We will not review, scan, or analyse your messaging communications to send third party marketing messages to you, and we will not sell reviews or analyses of these communications. These activities are carried out based on Plan Seminars

                                &rsquo;
                                {" legitimate interest in ensuring compliance with applicable laws and our policies, preventing fraud, promoting safety, and improving and ensuring the adequate performance of our services and use of our website."}
                            </p>
                        </li>

                        <li>
                            <p>
                                Your information will only be used for the purposes for which it was collected, unless we reasonably consider that we need to use it for another reason and that reason is compatible with the original purpose. If you wish to obtain an explanation as to how the processing for the new purpose is compatible with the original purpose, please contact us. We will notify you if we need to use your information for unrelated purposes and we will explain the legal basis which allows us to do so. Please note that we may process your Personal Data without your knowledge or consent, in compliance with the above rules, where this is required or permitted by law.
                            </p>
                        </li>
                    </ol>

                    <ol start="7">
                        <li>
                            <h1>
                                HOW WE SHARE AND DISCLOSE THE INFORMATION COLLECTED FROM YOU.
                            </h1>
                        </li>
                    </ol>

                    <p>
                        Except as provided in this policy, we will not provide your Personal Data to third parties. The way in which we may disclose information collected from you are:
                    </p>

                    <ol type="a">
                        <li>
                            <p>
                                For compliance purposes which includes but are not limited to verification (for Instructors or Academies), payment processing, etc.
                            </p>
                        </li>

                        <li>
                            <p>
                                To perform and maximise our services and our website to you.
                            </p>
                        </li>

                        <li>
                            <p>
                                To connect you (as Student) to Instructors or Academies of your choice and selection.
                            </p>
                        </li>

                        <li>
                            <p>
                                To connect Instructors or Academies to Students interested in their seminars and services.
                            </p>
                        </li>

                        <li>
                            <p>
                                For legal purposes, which includes but are not limited to; complying with the requirements of the law such as subpoenas, search warrants, court orders, and other legal processes; responding to enquiries or requests from government, regulatory, law enforcement, public authorities, or content protection organisations; defending the legal rights, privacy, safety or property of Plan Seminars, its subsidiaries, employees, agents, contractors or users; permitting Plan Seminars to pursue available remedies, commence, participate in or defend litigation, or limit the damages we may sustain, and to enforce this Policy or any applicable

                                <Link to="/terms-of-use">
                                    Website Terms of Use
                                </Link>

                                {" "}

                                and

                                <Link to="/terms-of-service">
                                    Terms of and Conditions of Service
                                </Link>

                                {" "}

                                are complied with.
                            </p>
                        </li>

                        <li>
                            <p>
                                To maximise our service to you, we might disclose to our partners and service providers, which includes but are not limited to banks and payment providers, third-party identity checking or credit reference agencies, communication providers, analytics tools, IT, information security and cloud services providers. These parties will keep the information confidential and use it only to the extent necessary to provide the applicable service(s) or as otherwise permitted or required by law.
                            </p>
                        </li>

                        <li>
                            <p>
                                We may disclose aggregate statistics about visitors to the website in order to describe our services to prospective partners, advertisers, sponsors and other reputable third parties and for other lawful purposes.
                            </p>
                        </li>

                        <li>
                            <p>
                                We may share your information with your consent, such as when you post personal information on platforms accessible to third parties, or you share your profile activities with third-party platforms.
                            </p>
                        </li>

                        <li>
                            <p>
                                Please note that once we share your personal information with another third-party, the information received by the other third party is controlled by that company and becomes subject to the third-party

                                &rsquo;
                                s privacy practices.
                            </p>
                        </li>

                        <li>
                            <p>
                                We may disclose aggregate statistics about visitors to the website in order to describe our services to prospective partners, advertisers, sponsors and other reputable third parties and for other lawful purposes
                            </p>
                        </li>
                    </ol>

                    <ol start="8">
                        <li>
                            <h1>
                                COLLECTION OF INFORMATION AND OBLIGATIONS OF INSTRUCTORS, ACADEMIES AND STUDENTS.
                            </h1>

                        </li>
                    </ol>

                    <ol type="a">
                        <li>
                            <p>
                                As an Instructor or Academy on our website, you agree that you are responsible for ensuring that you comply with applicable data protection laws in respect of any personal information that you collect from Students connected through our website and in the entire course of providing your services to them. Plan Seminars does not accept any responsibility for this processing of Personal Data by any Instructor or Academy.
                            </p>
                        </li>

                        <li>
                            <p>
                                As a Student on our website, you are responsible for any misuse of personal information obtained through the use of our website and the services of any Instructor or Academy on our website. It is your duty to comply with applicable data protection laws. You agree that Plan Seminars does not accept any responsibility for the use or misuse of any Personal Data obtained from our website.
                            </p>
                        </li>
                    </ol>

                    <ol start="9">
                        <li>
                            <h1>
                                PRIVACY OF PAYMENT INFORMATION.
                            </h1>
                        </li>
                    </ol>

                    <ol type="a">
                        <li>
                            <p>
                                All payments transactions made through our website are made through Stripe. You will be providing credit or debit card information directly to Stripe who process payment details, encrypting your credit/debit card information and authorising payment. The processing of your payment information is done so in accordance with Stripe's privacy policies and terms that can be accessed here:

                                <a href="https://stripe.com/gb/privacy">
                                    <u>
                                        https://stripe.com/gb/privacy
                                    </u>
                                </a>

                                {" "}

                                .

                                {" "}
                            </p>
                        </li>
                    </ol>

                    <ol start="10">
                        <li>
                            <h1>
                                SECURITY OF YOUR PERSONAL DATA.
                            </h1>

                        </li>
                    </ol>

                    <ol type="a">
                        <li>
                            <p>
                                We will take reasonable technical and organisational precautions to prevent the loss, misuse, or alteration of your personal information. We have implemented appropriate technical and organisational measures to ensure the security of your information and to protect your Personal Data. In addition, we limit access to only those employees, agents, contractors and other third parties who have a business need to know. They will only process it on our instructions, and they are subject to a duty of confidentiality.
                            </p>
                        </li>

                        <li>
                            <p>
                                We will store all the Personal Data you provide on secure cloud servers who are GDPR compliant.
                            </p>
                        </li>

                        <li>
                            <p>
                                You acknowledge that the transmission of information over the internet is inherently insecure, and we cannot guarantee the security of data sent over the internet, however, we undertake to take all reasonable steps to ensure the safety of your data.,
                            </p>
                        </li>

                        <li>
                            <p>
                                You are responsible for keeping the password you use for accessing our website confidential; we will not ask you for your password (except when you log in to our website).
                            </p>
                        </li>
                    </ol>

                    <ol start="11">
                        <li>
                            <h1>
                                YOUR RIGHTS AND CONTROLS ON YOUR INFORMATION.
                            </h1>
                        </li>
                    </ol>

                    <p>
                        Under certain circumstances, you have rights under data protection laws in relation to your Personal Data. These rights are:
                    </p>

                    <ol type="a">
                        <li>
                            <p>
                                <strong>
                                    {"The right to request access to your Personal Data (commonly known as a "}

                                    &ldquo;
                                    data subject access request

                                    &rdquo;
                                    )
                                </strong>

                                : enables you to receive a copy of the Personal Data we hold about you and to check that we are lawfully processing it.
                            </p>
                        </li>

                        <li>
                            <p>
                                <strong>
                                    The right to request correction of such Personal Data that we hold about you:

                                    {" "}
                                </strong>

                                this enables you to have any incomplete or inaccurate data we hold about you corrected. However, we may need to verify the accuracy of the new information you provide to us.
                            </p>
                        </li>

                        <li>
                            <p>
                                <strong>
                                    Request erasure of your Personal Data
                                </strong>

                                : this enables you to ask us to delete or remove Personal Data where there is no good reason for us to continue to process it. You also have the right to ask us to delete or remove your Personal Data where you have successfully exercised your right to object to processing (see below), where we may have processed your information unlawfully or where we are required to erase your Personal Data to comply with local law. Note, however, that we may not always be able to comply with your request of erasure for specific legal reasons which will be notified to you, if applicable, at the time of your request.
                            </p>
                        </li>

                        <li>
                            <p>
                                <strong>
                                    The right to object to the processing of your Personal Data, where we are relying on a legitimate interest (or those of a third party) and there is something about your particular situation which makes you want to object to processing on this ground as you feel it impacts on your fundamental rights and freedoms:
                                </strong>

                                {" "}

                                You also have the right to object where we are processing your Personal Data for direct marketing purposes. In some cases, we may demonstrate that we have compelling legitimate grounds to process your information which override your rights and freedoms.
                            </p>
                        </li>

                        <li>
                            <p>
                                <strong>
                                    The right to request the restriction of processing of your Personal Data:

                                    {" "}
                                </strong>

                                this enables you to ask us to suspend the processing of your Personal Data in the following scenarios: (a) if you want us to establish the data

                                &rsquo;
                                s accuracy; (b) where our use of the data is unlawful but you do not want us to erase it; (c) where you need us to hold the data even if we no longer require it as you need it to establish, exercise or defend legal claims; or (d) you have objected to our use of your data, but we need to verify whether we have overriding legitimate grounds to use it.
                            </p>
                        </li>

                        <li>
                            <p>
                                <strong>
                                    The right to request the transfer of your Personal Data to you or to a third party
                                </strong>

                                : we will provide to you, or a third party you have chosen, your Personal Data in a structured, commonly used, machine-readable format. Please note that this right only applies to automated information that you initially provided consent for us to use or where we used the information to perform a contract with you.
                            </p>
                        </li>

                        <li>
                            <p>
                                <strong>
                                    The right to withdraw consent
                                </strong>

                                {" "}

                                at any time where we are relying on consent to process your Personal Data. However, this will not affect the lawfulness of any processing carried out before you withdraw your consent. If you withdraw your consent, we may not be able to provide certain products or services to you. We will advise you if this is the case at the time you withdraw your consent.
                            </p>
                        </li>

                        <li>
                            <p>
                                <strong>
                                    The right to opt-out
                                </strong>

                                {" "}

                                - You can opt-out of receiving our marketing communications. However, you will still receive transactional messages from us. To opt-out of receiving our marketing communications, you can contact us at

                                <a href="mailto:support@bjjseminars.com">
                                    <u>
                                        support@bjjseminars.com
                                    </u>
                                </a>

                                .
                            </p>
                        </li>
                    </ol>

                    <p>
                        <strong>
                            In ensuring that we address your requests:
                        </strong>
                    </p>

                    <ol
                        start="9"
                        type="a"
                    >
                        <li>
                            <p>
                                You will not have to pay a fee to access your Personal Data (or to exercise any of the other rights). However, we may charge a reasonable fee if your request is clearly unfounded, repetitive, or excessive. Alternatively, we may refuse to comply with your request in such circumstances.
                            </p>
                        </li>

                        <li>
                            <p>
                                We may need to request specific information from you to help us confirm your identity and ensure your right to access your Personal Data (or to exercise any of your other rights). This is a security measure to ensure that Personal Data is not disclosed to any person who has no right to receive it. We may also contact you to ask you for further information in relation to your request to speed up our response.
                            </p>
                        </li>

                        <li>
                            <p>
                                We try to respond to all legitimate requests within one month. Occasionally it may take us longer than a month if your request is particularly complex, requires additional efforts or you have made several requests. In this case, we will notify you and keep you updated.
                            </p>
                        </li>

                        <li>
                            <p>
                                For the purpose of this section 8, legitimate interest means the interest of our business in conducting and managing our business to enable us to give you the best service/product and the best and most secure experience. We make sure we consider and balance any potential impact on you (both positive and negative) and your rights before we process your Personal Data for our legitimate interests. We do not use your Personal Data for activities where our interests are overridden by the impact on you (unless we have your consent or are otherwise required or permitted to by law). You can obtain further information about how we assess our legitimate interests against any potential impact on you in respect of specific activities by contacting us.
                            </p>
                        </li>
                    </ol>

                    <ol start="12">
                        <li>
                            <h1>
                                TARGET AND BEHAVIOURAL ADVERTISEMENT
                            </h1>

                        </li>

                    </ol>

                    <ol type="a">
                        <li>
                            <p>
                                We use Google Analytics to help us understand how our customers use the site. You can read more about how Google uses your Personal Data here:

                                <a href="https://policies.google.com/privacy?hl=en">
                                    <u>
                                        https://policies.google.com/privacy?
                                    </u>
                                </a>

                                . You can also opt-out of Google Analytics here:

                                <a href="https://tools.google.com/dlpage/gaoptout">
                                    <u>
                                        https://tools.google.com/dlpage/gaoptout
                                    </u>
                                </a>

                                .

                                {" "}
                            </p>
                        </li>

                        <li>
                            <p>
                                We may share information about your use of our website, your purchases, and your interaction with our ads on other websites with our advertising partners. We collect and share some of this information directly with our advertising partners, and in some cases through the use of cookies or other similar technologies (which you may consent to, depending on your location)
                            </p>
                        </li>

                        <li>
                            <p>
                                For more information about how targeted advertising works, you can visit the Network Advertising Initiative

                                &rsquo;
                                s (

                                &ldquo;
                                NAI

                                &rdquo;
                                ) educational page at

                                <a href="http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work">
                                    <u>
                                        http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work
                                    </u>
                                </a>
                            </p>
                        </li>

                        <li>
                            <p>
                                You can opt out of targeted advertising by:
                            </p>

                            <ol type="i">
                                <li>
                                    <p>
                                        <strong>
                                            FACEBOOK

                                            {" "}
                                        </strong>

                                        -

                                        <a href="https://www.facebook.com/settings/?tab=ads">
                                            <u>
                                                https://www.facebook.com/settings/?tab=ads
                                            </u>
                                        </a>
                                    </p>
                                </li>

                                <li>
                                    <p>
                                        <strong>
                                            GOOGLE

                                            {" "}
                                        </strong>

                                        -

                                        <a href="https://www.google.com/settings/ads/anonymous">
                                            <u>
                                                https://www.google.com/settings/ads/anonymous
                                            </u>
                                        </a>
                                    </p>
                                </li>

                                <li>
                                    <p>
                                        <strong>
                                            BING

                                            {" "}
                                        </strong>

                                        -

                                        <a href="https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads">
                                            <u>
                                                https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads
                                            </u>
                                        </a>
                                    </p>
                                </li>
                            </ol>
                        </li>

                        <li>
                            <p>
                                Additionally, you can opt out of some of these services by visiting the Digital Advertising Alliance

                                &rsquo;
                                s opt-out portal at:

                                <a href="http://optout.aboutads.info/">
                                    <u>
                                        http://optout.aboutads.info/
                                    </u>
                                </a>
                            </p>
                        </li>
                    </ol>

                    <ol start="13">
                        <li>
                            <h1>
                                HOW LONG DO WE KEEP YOUR INFORMATION?
                            </h1>
                        </li>
                    </ol>

                    <p>
                        We retain personal data for as long as necessary, based on the type of data, regulatory requirements, and our usage purposes. The retention period is determined by criteria such as legally mandated retention times, ongoing or potential litigation, intellectual property or ownership rights, contract requirements, operational needs, and historical archiving.
                    </p>

                    <ol start="14">
                        <li>
                            <h1>
                                GENERAL DATA PROTECTION REGULATION (

                                &ldquo;
                                GDPR

                                &rdquo;
                                ) AND DATA PROTECTION ACT (

                                &ldquo;
                                DPA

                                &rdquo;
                                ) PROVISIONS.
                            </h1>
                        </li>
                    </ol>

                    <p>
                        In addition to the clauses contained in this policy, if you are a user in the European Economic Area, United Kingdom, and/or Switzerland, as directed by the GDPR, the following provisions apply to you. However, if you have doubts on whether the provisions in this (13) apply to you, please contact a legal practitioner for advice.
                    </p>

                    <ol type="a">
                        <li>
                            <p>
                                For Personal Data use by Plan Seminars, we are data controller under the provisions of the GDPR and DPA, and we are responsible for how your information is collected, used and disclosed. Please note that - the information collected by us is controlled by this policy,

                                <Link to="/terms-of-use">
                                    Website Terms of Use
                                </Link>

                                {" "}

                                and

                                <Link to="/terms-of-service">
                                    Terms of and Conditions of Service
                                </Link>

                                {" "}

                                and our

                                <Link to="#cookies">
                                    Cookies Policy
                                </Link>

                                .
                            </p>
                        </li>

                        <li>
                            <p>
                                For Personal Data disclosed by users to each other through our Platform, we are data processors, and the user collecting the information shall be the data controller (under the provisions of the GDPR and DPA) for the relevant personal data collected. Instructors, Academies and Students hereby instructs us to process such data on their behalf.
                            </p>
                        </li>

                        <li>
                            <p>
                                For Personal Data where Users and Plan Seminars are joint controllers under the GDPR and DPA, each party shall comply with its obligations under the GDPR and DPA to ensure the security and compliant use of the Personal Data.
                            </p>
                        </li>

                        <li>
                            <p>
                                Also be informed that if you provide information to us through any third-party platform, that third-party platform may also collect your information separately. The information collected by any third parties is subject to the third-party

                                &rsquo;
                                s privacy policy. Please note that the Privacy choices you have made on the third party

                                &rsquo;
                                s platform will not apply to our use of the information we have collected directly through our platforms.
                            </p>
                        </li>

                        <li>
                            <p>
                                Also be informed that our platform may contain links to third party websites. We are not responsible for the privacy practices of those sites. It is our advice to you that you please pay attention to the privacy policy of these third-party platforms.
                            </p>
                        </li>

                        <li>
                            <p>
                                We only collect information to the extent needed and germane for the performance of our contract to you, provide services on our platform and perform all the rights, obligations, responsibilities and terms contained in our

                                <Link to="/terms-of-use">
                                    Website Terms of Use
                                </Link>

                                {" "}

                                and

                                <Link to="/terms-of-service">
                                    Terms and Conditions of Service
                                </Link>

                                .
                            </p>
                        </li>

                        <li>
                            <p>
                                We also collect information for our legitimate interest (which do not override your fundamental rights and freedoms), on the basis of consent, to improve the quality of service provided to you, to respond to your questions, to provide marketing information to you, for legal reasons, to make and receive payment for the security of our platform, to enforce our Term and Conditions, to engage in business change, to comply with a legal or regulatory obligation and to create anonymous data.
                            </p>
                        </li>

                        <li>
                            <p>
                                For the purpose of (e), legitimate interest means the interest of our business in conducting and managing our business to enable us to give you the best service/product and the best and most secure experience. We make sure we consider and balance any potential impact on you (both positive and negative) and your rights before we process your Personal Data for our legitimate interests. We do not use your Personal Data for activities where our interests are overridden by the impact on you (unless we have your consent or are otherwise required or permitted to by law). You can obtain further information about how we assess our legitimate interests against any potential impact on you in respect of specific activities by contacting us.
                            </p>
                        </li>

                        <li>
                            <p>
                                For the purpose of (e), compliance with a legal or regulatory obligation means processing your Personal Data where it is necessary for compliance with a legal or regulatory obligation that we are subject to.
                            </p>
                        </li>

                        <li>
                            <p>
                                {"Users in the European Economic Area, United Kingdom, and/or Switzerland have the right to opt-out of all of our processing of their data for direct marketing purposes, to do this you click "}

                                &ldquo;
                                unsubscribe

                                &rdquo;
                                {" at the bottom of a marketing email or edit your preferences in your account setting."}
                            </p>
                        </li>

                        <li>
                            <p>
                                Users in the European Economic Area, United Kingdom, and/or Switzerland also have the rights contained in 11 (a-g). In addition, they may also object to our uses or disclosures of Personal Data, request a restriction on its processing, or withdraw any consent, though such actions typically will not have a retroactive effect. They also will not affect our ability to continue processing data in lawful ways (for example, if you opt-out of the use of your telephone number for direct marketing, we might still decide to contact you by phone regarding potential fraud on your account).
                            </p>
                        </li>

                        <li>
                            <p>
                                Users in the European Economic Area, United Kingdom, and/or Switzerland also have the right to lodge a complaint with the local data protection authority if you believe that we have not complied with applicable data protection laws. You also have the right to lodge a complaint with the supervisory authority of your residence, place of work or where the incident took place. However, please endeavour to contact us at

                                <a href="mailto:support@bjjseminars.com">
                                    <u>
                                        support@bjjseminars.com
                                    </u>
                                </a>

                                .

                                {" "}
                            </p>
                        </li>

                        <li>
                            <p>
                                We will only keep and retain the collected information as needed and pertinent for the collected data and as permitted by law. As soon as we no longer need to keep the data, we will remove it from our platforms and systems. We will also take the needed steps to anonymize the collected information.
                            </p>
                        </li>

                        <li>
                            <p>
                                When we no longer need to use your information, we will remove it from our systems and records and/or take steps to anonymize it and take other steps to protect them. We regularly review our security procedures to consider appropriate protection methods. However, please be aware that despite our best efforts, no security measures are perfect or impenetrable.
                            </p>
                        </li>

                        <li>
                            <p>
                                When determining the retention period, we take into account various criteria, such as the type of products and services requested by you or that you are provided with by us, the nature and length of our relationship with you, possible re-enrolment with our services, the impact on our platform if we delete the information about you, mandatory retention periods provided by law and the statute of limitations.
                            </p>
                        </li>

                        <li>
                            <p>
                                For any transfer, wherever your Personal Data is transferred, stored or processed by us, we will take steps to safeguard the privacy of your Personal Data. For any information on your privacy, any question and these regulations, you can reach us at

                                <a href="mailto:support@bjjseminars.com">
                                    <u>
                                        support@bjjseminars.com
                                    </u>
                                </a>

                                .

                                {" "}
                            </p>
                        </li>
                    </ol>

                    <ol start="15">
                        <li>
                            <h1>
                                CALIFORNIA CONSUMER PRIVACY ACT.
                            </h1>
                        </li>
                    </ol>

                    <p>
                        If you live in California, you may have the following rights over your information:
                    </p>

                    <ol type="a">
                        <li>
                            <p>
                                For the period covering the 12 months preceding your request, you may request that we disclose to you the categories and specific pieces of information collected about you, the categories of sources from which we collected that information, and the purposes for which your information was collected. The categories of third parties with whom we share that information. The specific pieces of information we collected about you (

                                <strong>
                                    a data portability request)
                                </strong>

                                . If we disclosed your information for a business purpose, two separate lists disclosing:

                                {" "}
                            </p>

                            <ol type="i">
                                <li>
                                    <p>
                                        sales, identifying the information categories that each category of recipient purchased; and
                                    </p>
                                </li>

                                <li>
                                    <p>
                                        disclosures for a business purpose, identifying the Personal Data categories that each category of recipient obtained.
                                    </p>
                                </li>
                            </ol>
                        </li>

                        <li>
                            <p>
                                You may also request that we delete the information we collected from you.
                            </p>
                        </li>

                        <li>
                            <p>
                                You have the right not to be discriminated against for exercising your rights over your information.
                            </p>
                        </li>

                        <li>
                            <p>
                                You may submit a request to exercise your rights by sending us an email at

                                <a href="mailto:support@bjjseminars.com">
                                    <u>
                                        support@bjjseminars.com
                                    </u>
                                </a>

                                . We try to respond to all legitimate requests within one month. Occasionally it may take us longer than a month if your request is particularly complex, requires additional efforts or you have made several requests. In this case, we will notify you and keep you updated.

                                {" "}
                            </p>
                        </li>

                        <li>
                            <p>
                                We will not discriminate against you for exercising any of your rights as guaranteed by the California Consumer Privacy Act.
                            </p>
                        </li>
                    </ol>

                    <p>
                        <strong>
                            COOKIES
                        </strong>
                    </p>

                    <ol start="16">
                        <li>
                            <h1>
                                WHAT ARE COOKIES
                            </h1>
                        </li>
                    </ol>

                    <ol type="a">
                        <li>
                            <p>
                                {"A cookie is a file containing an identifier (a string of letters and numbers) that is sent by a web server to a web browser and is stored by the browser. The identifier is then sent back to the server each time the browser requests a page from the server. Cookies may be either "}

                                &ldquo;
                                persistent

                                &rdquo;
                                {" cookies or "}

                                &ldquo;
                                session

                                &rdquo;
                                {" cookies: a persistent cookie will be stored by a web browser and will remain valid until its set expiry date unless deleted by the user before the expiry date; a session cookie, on the other hand, will expire at the end of the user session, when the web browser is closed. Cookies do not typically contain any information that personally identifies a user, but personal information that we store about you may be linked to the information stored in and obtained from cookies."}
                            </p>
                        </li>
                    </ol>

                    <ol start="17">
                        <li>
                            <h1>
                                CONSENT TO USE COOKIES AND CHANGING SETTINGS.
                            </h1>
                        </li>
                    </ol>

                    <ol type="a">
                        <li>
                            <p>
                                We will ask for your consent to place cookies or other similar technologies on your device, except where they are essential for us to provide you with a service that you have requested.
                            </p>
                        </li>

                        <li>
                            <p>
                                The first time you visit our website, you will be informed about our use of cookies to improve your website experience. Consenting to our use of cookies in accordance with the terms of this Policy when you first visit our website permits us to use cookies every time you visit our website. However, you may adjust your consent by using our cookies settings which is accessible at the bottom right of your screen.
                            </p>
                        </li>
                    </ol>

                    <ol start="18">
                        <li>
                            <h1>
                                WHY WE USE COOKIES ON OUR WEBSITE.
                            </h1>
                        </li>
                    </ol>

                    <p>
                        We use the following cookies to optimise your experience on our site and to provide our services.
                    </p>

                    <ol type="a">
                        <li>
                            <p>
                                <strong>
                                    Necessary Cookies -
                                </strong>

                                {" "}

                                These are cookies that are required for the operation of our website. They include, for example, cookies that enable you to log into secure areas of our website, use a checkout cart or make use of e-billing services. These cookies cannot be disabled on our website. However, your browser can be set to alert or even block these cookies. Please note that this may affect the effectiveness and function of our website for you.
                            </p>
                        </li>

                        <li>
                            <p>
                                <strong>
                                    Functional Cookies
                                </strong>

                                {" "}

                                - Functionality cookies are used by us to remember your preferences so that our platform can be customised for your use, without them certain functions of our platform may not be available.
                            </p>
                        </li>

                        <li>
                            <p>
                                <strong>
                                    {"Performance Cookies "}
                                    &ndash;
                                </strong>

                                {" "}

                                Performance cookies enhance our website performance and functionality. These cookies also allow us to improve site behaviours, personalise your browsing experience and inform us on how you use our website so that we can improve the functionality of our website for your use.
                            </p>
                        </li>

                        <li>
                            <p>
                                <strong>
                                    Analytical Cookies
                                </strong>

                                {" "}

                                &ndash;
                                {" We use these cookies to collect information to help us understand how our website is being used. An example is counting the number of visitors and seeing how many visitors move around our website when they are using it. This helps us to improve the way our website works, for example, ensuring that users, such as yourself, find what you are looking for easily."}
                            </p>
                        </li>
                    </ol>

                    <p>
                        <strong>
                            {"WE DO NOT RESPOND TO "}

                            &ldquo;
                            DO NOT TRACK

                            &rdquo;
                            {" SIGNALS THAT CAN BE ACTIVATED ON CERTAIN BROWSERS."}

                            {" "}
                        </strong>
                    </p>

                    <p>
                        The specific details of the cookies we use are as stated below:
                    </p>

                    <table
                        cellPadding="7"
                        cellSpacing="0"
                    >
                        <thead>
                            <tr>
                                <td height="51">
                                    <p>
                                        <strong>
                                            Name
                                        </strong>
                                    </p>
                                </td>

                                <td >
                                    <p>
                                        <strong>
                                            Purpose and Duration
                                        </strong>
                                    </p>
                                </td>

                                <td >
                                    <p>
                                        <strong>
                                            Whether cookie is essential for us to provide you with a service that you have requested and whether we will seek your consent before we place the cookie
                                        </strong>
                                    </p>
                                </td>

                                <td >
                                    <p>
                                        <strong>
                                            Duration
                                        </strong>
                                    </p>
                                </td>
                            </tr>
                        </thead>

                        <tbody>
                            <tr>
                                <td >
                                    <p>
                                        __stripe_mid
                                    </p>
                                </td>

                                <td >
                                    <p>
                                        Stripe sets this cookie cookieto process payments.
                                    </p>
                                </td>

                                <td >
                                    <p>
                                        Yes, essential cookies (we will therefore not request your consent before placing this cookie)
                                    </p>
                                </td>

                                <td >
                                    <p>
                                        One year
                                    </p>
                                </td>
                            </tr>

                            <tr>
                                <td >
                                    <p>
                                        [Insert Details]
                                    </p>
                                </td>

                                <td >
                                    <p>
                                        [Insert Details]
                                    </p>
                                </td>

                                <td >
                                    <p>
                                        [Insert Details]
                                    </p>
                                </td>

                                <td >
                                    <p>
                                        [Insert Details]
                                    </p>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <p>
                                        [Insert Details]
                                    </p>
                                </td>

                                <td >
                                    <p>
                                        [Insert Details]
                                    </p>
                                </td>

                                <td >
                                    <p>
                                        [Insert Details]
                                    </p>
                                </td>

                                <td >
                                    <p>
                                        [Insert Details]
                                    </p>
                                </td>
                            </tr>

                            <tr>
                                <td >
                                    <p>
                                        [Insert Details]
                                    </p>
                                </td>

                                <td >
                                    <p>
                                        [Insert Details]
                                    </p>
                                </td>

                                <td >
                                    <p>
                                        [Insert Details]
                                    </p>
                                </td>

                                <td >
                                    <p>
                                        [Insert Details]
                                    </p>
                                </td>
                            </tr>

                            <tr>
                                <td >
                                    <p>
                                        [Insert Details]
                                    </p>
                                </td>

                                <td >
                                    <p>
                                        [Insert Details]
                                    </p>
                                </td>

                                <td >
                                    <p>
                                        [Insert Details]
                                    </p>
                                </td>

                                <td >
                                    <p>
                                        [Insert Details]
                                    </p>
                                </td>
                            </tr>

                            <tr>
                                <td >
                                    <p>
                                        [Insert Details]
                                    </p>
                                </td>

                                <td >
                                    <p>
                                        [Insert Details]
                                    </p>
                                </td>

                                <td >
                                    <p>
                                        [Insert Details]
                                    </p>
                                </td>

                                <td >
                                    <p>
                                        [Insert Details]
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <ol start="19">
                        <li>
                            <h1>
                                HOW TO TURN OFF ALL COOKIES AND CONSEQUENCES OF DOING SO.
                            </h1>
                        </li>
                    </ol>

                    <p>
                        If you do not want to accept any cookies, you may be able to change your browser settings so that cookies (including those which are essential to the services requested) are not accepted. If you do this, please be aware that you may lose some of the functionality of our website.
                    </p>

                    <ol start="20">
                        <li>
                            <h1>
                                AMENDMENTS
                            </h1>
                        </li>
                    </ol>

                    <p>
                        We may update this policy from time to time by publishing a new version on our website. You should check this page occasionally to ensure you understand any changes to this policy. We may notify you of changes to this policy by email or through email or any other choice of communications as set by you.
                    </p>

                    <ol start="21">
                        <li>
                            <h1>
                                CONTACTING US AND OUR DATA PROTECTION OFFICER.
                            </h1>
                        </li>
                    </ol>

                    <p>
                        Please do not hesitate to contact us if you have any questions regarding Plan Seminars

                        &rsquo;
                        s policies. You can contact our data protection officer, at

                        <a href="mailto:support@bjjseminars.com">
                            <u>
                                support@bjjseminars.com
                            </u>
                        </a>

                        {" "}

                        or at our registered address, Plan Seminars ApS, Svanemoellevej 41 2900 Hellerup, Denmark.

                        {" "}
                    </p>
                </div>

            </div>
        </section>
    );
};

export default PrivacyPolicy;
