import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <>
            {/* <a href="" className="scroll_to_top"></a> */}
            <footer className="footer">
                <div className="container footer-container">
                    <div className="footer-body">
                        <div className="footer-top">
                            <div className="footer-top__global-info">
                                <Link
                                    className="footer__logo"
                                    to="/"
                                >
                                    <img
                                        alt=""
                                        src={require("../../assets/img/footer-logo.svg").default}
                                    />
                                </Link>

                                <div className="footer-text-md-small">
                                    <p className="footer-text">
                                        Plan Seminars ApS
                                    </p>

                                    <p className="footer-text">
                                        VAT: 44792605
                                    </p>
                                </div>

                                <ul className="social-list">
                                    <li>
                                        <a
                                            className="btn"
                                            href="https://x.com/"
                                        >
                                            <svg
                                                fill="none"
                                                height="20"
                                                viewBox="0 0 20 20"
                                                width="20"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M15.7508 0.960938H18.8175L12.1175 8.61927L20 19.0384H13.8283L8.995 12.7184L3.46333 19.0384H0.395L7.56167 10.8468L0 0.961771H6.32833L10.6975 6.73844L15.7508 0.960938ZM14.675 17.2034H16.3742L5.405 2.7001H3.58167L14.675 17.2034Z"
                                                    fill="currentColor"
                                                />
                                            </svg>
                                        </a>
                                    </li>

                                    <li>
                                        <a
                                            className="btn"
                                            href="https://www.youtube.com/"
                                        >
                                            <svg
                                                fill="none"
                                                height="20"
                                                viewBox="0 0 20 20"
                                                width="20"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M8.33334 12.4998L12.6583 9.99984L8.33334 7.49984V12.4998ZM17.9667 5.97484C18.075 6.3665 18.15 6.8915 18.2 7.55817C18.2583 8.22484 18.2833 8.79984 18.2833 9.29984L18.3333 9.99984C18.3333 11.8248 18.2 13.1665 17.9667 14.0248C17.7583 14.7748 17.275 15.2582 16.525 15.4665C16.1333 15.5748 15.4167 15.6498 14.3167 15.6998C13.2333 15.7582 12.2417 15.7832 11.325 15.7832L10 15.8332C6.50834 15.8332 4.33334 15.6998 3.47501 15.4665C2.72501 15.2582 2.24167 14.7748 2.03334 14.0248C1.92501 13.6332 1.85001 13.1082 1.80001 12.4415C1.74167 11.7748 1.71667 11.1998 1.71667 10.6998L1.66667 9.99984C1.66667 8.17484 1.80001 6.83317 2.03334 5.97484C2.24167 5.22484 2.72501 4.7415 3.47501 4.53317C3.86667 4.42484 4.58334 4.34984 5.68334 4.29984C6.76667 4.2415 7.75834 4.2165 8.675 4.2165L10 4.1665C13.4917 4.1665 15.6667 4.29984 16.525 4.53317C17.275 4.7415 17.7583 5.22484 17.9667 5.97484Z"
                                                    fill="currentColor"
                                                />
                                            </svg>
                                        </a>
                                    </li>

                                    <li>
                                        <a
                                            className="btn"
                                            href="https://www.facebook.com/"
                                        >
                                            <svg
                                                fill="none"
                                                height="20"
                                                viewBox="0 0 20 20"
                                                width="20"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M18.3333 9.99984C18.3333 5.39984 14.6 1.6665 10 1.6665C5.40001 1.6665 1.66667 5.39984 1.66667 9.99984C1.66667 14.0332 4.53334 17.3915 8.33334 18.1665V12.4998H6.66667V9.99984H8.33334V7.9165C8.33334 6.30817 9.64167 4.99984 11.25 4.99984H13.3333V7.49984H11.6667C11.2083 7.49984 10.8333 7.87484 10.8333 8.33317V9.99984H13.3333V12.4998H10.8333V18.2915C15.0417 17.8748 18.3333 14.3248 18.3333 9.99984Z"
                                                    fill="currentColor"
                                                />
                                            </svg>
                                        </a>
                                    </li>

                                    <li>
                                        <a
                                            className="btn"
                                            href="https://www.instagram.com/"
                                        >
                                            <svg
                                                fill="none"
                                                height="20"
                                                viewBox="0 0 20 20"
                                                width="20"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M6.49999 1.6665H13.5C16.1667 1.6665 18.3333 3.83317 18.3333 6.49984V13.4998C18.3333 14.7817 17.8241 16.0111 16.9177 16.9175C16.0112 17.8239 14.7819 18.3332 13.5 18.3332H6.49999C3.83332 18.3332 1.66666 16.1665 1.66666 13.4998V6.49984C1.66666 5.21796 2.17588 3.98858 3.08231 3.08215C3.98873 2.17573 5.21811 1.6665 6.49999 1.6665ZM6.33332 3.33317C5.53767 3.33317 4.77461 3.64924 4.212 4.21185C3.64939 4.77446 3.33332 5.53752 3.33332 6.33317V13.6665C3.33332 15.3248 4.67499 16.6665 6.33332 16.6665H13.6667C14.4623 16.6665 15.2254 16.3504 15.788 15.7878C16.3506 15.2252 16.6667 14.4622 16.6667 13.6665V6.33317C16.6667 4.67484 15.325 3.33317 13.6667 3.33317H6.33332ZM14.375 4.58317C14.6513 4.58317 14.9162 4.69292 15.1116 4.88827C15.3069 5.08362 15.4167 5.34857 15.4167 5.62484C15.4167 5.9011 15.3069 6.16606 15.1116 6.36141C14.9162 6.55676 14.6513 6.6665 14.375 6.6665C14.0987 6.6665 13.8338 6.55676 13.6384 6.36141C13.4431 6.16606 13.3333 5.9011 13.3333 5.62484C13.3333 5.34857 13.4431 5.08362 13.6384 4.88827C13.8338 4.69292 14.0987 4.58317 14.375 4.58317ZM9.99999 5.83317C11.1051 5.83317 12.1649 6.27216 12.9463 7.05356C13.7277 7.83496 14.1667 8.89477 14.1667 9.99984C14.1667 11.1049 13.7277 12.1647 12.9463 12.9461C12.1649 13.7275 11.1051 14.1665 9.99999 14.1665C8.89492 14.1665 7.83511 13.7275 7.05371 12.9461C6.27231 12.1647 5.83332 11.1049 5.83332 9.99984C5.83332 8.89477 6.27231 7.83496 7.05371 7.05356C7.83511 6.27216 8.89492 5.83317 9.99999 5.83317ZM9.99999 7.49984C9.33695 7.49984 8.70106 7.76323 8.23222 8.23207C7.76338 8.70091 7.49999 9.3368 7.49999 9.99984C7.49999 10.6629 7.76338 11.2988 8.23222 11.7676C8.70106 12.2364 9.33695 12.4998 9.99999 12.4998C10.663 12.4998 11.2989 12.2364 11.7678 11.7676C12.2366 11.2988 12.5 10.6629 12.5 9.99984C12.5 9.3368 12.2366 8.70091 11.7678 8.23207C11.2989 7.76323 10.663 7.49984 9.99999 7.49984Z"
                                                    fill="currentColor"
                                                />
                                            </svg>
                                        </a>
                                    </li>
                                </ul>
                            </div>

                            <div className="footer-info-group">
                                <div className="footer-info-group__item">
                                    <h5 className="heading">
                                        Our Goal
                                    </h5>

                                    <p className="footer-text">
                                        Our aim is to elevate BJJ worldwide by improving living conditions for instructors through our platform. This will lead to more instructors, more clubs, and an increased number of BJJ students, making it a sustainable source of income for practitioners.
                                    </p>
                                </div>

                                <hr className="hr-vertical" />

                                <div className="footer-info-group__item">
                                    <h5 className="heading">
                                        Resources
                                    </h5>

                                    <ul className="footer-menu footer-menu-mobile-col">
                                        <li>
                                            <Link to="/seminars">
                                                Seminars / Camps
                                            </Link>
                                        </li>

                                        <li>
                                            <Link to="/instructors">
                                                Book Instructors
                                            </Link>
                                        </li>

                                        <li>
                                            <Link to="/about-us">
                                                About Us
                                            </Link>
                                        </li>

                                        <li>
                                            <Link to="/academies">
                                                Academies
                                            </Link>
                                        </li>

                                        <li>
                                            <Link
                                                className="primary-link"
                                                to="/contact-us"
                                            >
                                                <u>
                                                    Problem with your

                                                    <br />

                                                    {" "}

                                                    seminar?
                                                </u>

                                                {" "}
                                            </Link>
                                        </li>
                                    </ul>
                                </div>

                                <hr className="hr-vertical" />

                                <div className="footer-info-group__item">
                                    <h5 className="heading">
                                        Get In Touch
                                    </h5>

                                    <ul className="footer-menu">
                                        <li>
                                            <a
                                                className="primary-link"
                                                href="mailto:support@bjj-seminar.com"
                                            >
                                                <span className="ico">
                                                    <svg
                                                        fill="none"
                                                        height="22"
                                                        viewBox="0 0 22 22"
                                                        width="22"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M19.25 8.7085L12.4424 11.6212C11.5212 12.0154 10.4788 12.0154 9.55756 11.6212L2.75 8.7085"
                                                            stroke="currentColor"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth="1.5"
                                                        />

                                                        <rect
                                                            height="13.75"
                                                            rx="4"
                                                            stroke="currentColor"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth="1.5"
                                                            width="16.5"
                                                            x="2.75"
                                                            y="4.5835"
                                                        />
                                                    </svg>
                                                </span>

                                                <span>
                                                    support@bjj-seminar.com
                                                </span>
                                            </a>
                                        </li>
                                    </ul>

                                    <h5 className="heading mt-5 mt-md-auto">
                                        We Accept
                                    </h5>

                                    <div className="paymants-methods">
                                        <img
                                            alt="Visa"
                                            src={require("../../assets/img/Visa.svg").default}
                                        />

                                        <img
                                            alt="Mastercard"
                                            src={require("../../assets/img/Mastercard.svg").default}
                                        />

                                        <img
                                            alt="PayPal"
                                            src={require("../../assets/img/PayPal.svg").default}
                                        />
                                    </div>
                                </div>

                                <hr className="hr-vertical md-hidden" />
                            </div>
                        </div>

                        <div className="footer-bottom">
                            <p>
                                Plan Seminars © 2024
                            </p>

                            <ul className="footer-menu">
                                <li>
                                    <Link to="/terms-of-service">
                                        Terms And Conditions of Service
                                    </Link>
                                </li>

                                <li>
                                    <Link to="/terms-of-use">
                                        Website Terms Of Use
                                    </Link>
                                </li>

                                <li>
                                    <Link to="/privacy-policy">
                                        Privacy and Cookies Policy
                                    </Link>
                                </li>

                                <li>
                                    <Link to="/refund-policy">
                                        Cancellation and Refund Policy
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default Footer;
