import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../api";
import { apiConfig } from "../../api/config.js";
import { setToken } from "../../redux/slices/applicationSlice.js";
import { getUserFetch } from "../../redux/thunks/applicationThunks.js";
import { setAuthModalOpen } from "../../redux/slices/applicationSlice";
import Button from "../ui/buttons/Button";
import Input from "../ui/formElements/Input";
import InputPass from "../ui/formElements/InputPass";
import { AuthModals } from "../modals/singUpModals/AuthProvider";
import classNames from "classnames";

const LoginForm = ({
    onAfterSubmit, singUpHandler, forgotPassHandler, guestHandler, wrapperClasses = "", description
}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [data, setData] = useState({
        email: "",
        password: ""
    });

    const login = async () => {
        const response = await api.auth.login(data);

        if (response?.success && response.data.token) {
            localStorage.setItem("authToken", response.data.token);
            dispatch(setToken(response.data.token));
            setTimeout(() => {
                dispatch(getUserFetch(response.data.token));
                onAfterSubmit && onAfterSubmit();
                navigate("/");
            }, 200);
        } else {
            toast.error(response.message || "Email or password are wrong");
        }
    };

    const handleSignUp = () => {
        if (singUpHandler) {
            singUpHandler();
        } else {
            dispatch(setAuthModalOpen(AuthModals.singUp));
        }
    };

    const handleForgotPassword = () => {
        if (forgotPassHandler) {
            forgotPassHandler();
        } else {
            dispatch(setAuthModalOpen(AuthModals.fogrotPassword));
        }
    };

    const handleGuest = () => {
        if (guestHandler) {
            guestHandler();
        } else {
            dispatch(setAuthModalOpen(AuthModals.guest));
        }
    };

    return (
        <form
            action="#"
            autoComplete="off"
            className={classNames("form form--lg", wrapperClasses)}
            onSubmit={(e) => {
                e.preventDefault();
            }}
        >
            <div className="form-header">
                <div className="heading-block">
                    <h3 className="heading">
                        Login to account
                    </h3>

                    <p className="text--md desk-heading">
                        {description || "Fill in the form below to access your account."}
                    </p>
                </div>
            </div>

            <div className="form-body">
                <div className="row gutters-form">
                    <div className="col-12">
                        <Input
                            inputClasses="input--solid"
                            label="Email or phone"
                            onInput={(e) => {
                                setData({
                                    ...data,
                                    email: e.target.value.trim()
                                });
                            }}
                            placeholder="Type your email or phone..."
                            value={data.email}
                            wrapperClasses="input--xl"
                        />
                    </div>

                    <div className="col-12">
                        <InputPass
                            forgotPassClick={handleForgotPassword}
                            formGroupClass="input--xl input--icon-right"
                            inputClass="input--solid w-full"
                            label="Password"
                            onInput={(e) => {
                                setData({
                                    ...data,
                                    password: e.target.value
                                });
                            }}
                            placeholder="Password"
                            value={data.password}
                        />
                    </div>

                    <div className="col-12">
                        <Button
                            className="w-full btn--primary btn--lg radius fs-20 mb-10"
                            onClick={(e) => {
                                login();
                            }}
                            type="submit"
                        >
                            <span>
                                Login
                            </span>
                        </Button>

                        <Button
                            className="w-full btn--default btn--lg radius fs-20"
                            onClick={handleGuest}
                            type="button"
                        >
                            <span>
                                Continue as Guest
                            </span>
                        </Button>
                    </div>

                    <div className="col-12 text-center">
                        <p className="desk-heading">
                            or login with
                        </p>
                    </div>

                    <div className="col-12">
                        <ul className="flex-group social-list justify-center">
                            <li>
                                <a
                                    className="btn btn--square btn--xl rounded-full btn-border"
                                    href={`${apiConfig.baseURL}oauth/google`}
                                >
                                    <span className="ico">
                                        <img src={require("../../assets/img/ico-google.svg").default} />
                                    </span>
                                </a>
                            </li>

                            <li>
                                <Button className="btn btn--square btn--xl rounded-full btn-border">
                                    <span className="ico">
                                        <img src={require("../../assets/img/ico-apple.svg").default} />
                                    </span>
                                </Button>
                            </li>

                            <li>
                                <a
                                    className="btn btn btn--square btn--xl rounded-full btn-border"
                                    href={`${apiConfig.baseURL}oauth/facebook`}
                                >
                                    <span className="ico">
                                        <img src={require("../../assets/img/ico-facebook.svg").default} />
                                    </span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="row gutters-form">
                    <div className="col-12 text-center">
                        <p className="text--md">
                            Don’t have an account yet?
                            &nbsp;
                            <Button
                                className="color-secondary btn-link"
                                onClick={handleSignUp}
                            >
                                <span
                                    className="--fontFamily text-normal
                                text--md"
                                >
                                    Sign Up
                                </span>
                            </Button>
                        </p>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default LoginForm;
