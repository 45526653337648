import React from "react";
import { getTeachStyle } from "../../../helpers/utils";

const SinglePageTeachSyles = ({ teachingStyles }) => {
    return (
        <div className="border-bottom mb-42 pb-24">
            <h4 className="heading mb-32">
                Teaching Styles
            </h4>

            <ul className="teaching-styles-list">
                {teachingStyles?.length && getTeachStyle(teachingStyles).map((item, index) => (
                    <li key={index}>
                        {item}
                    </li>
                )



                )}
            </ul>
        </div>
    );
};

export default SinglePageTeachSyles;
