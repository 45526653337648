import {
    useEffect, useState
} from "react";
import DatePicker from "../../../components/ui/formElements/DatePicker";
import moment from "moment";
import {
    eachDayOfInterval, format
} from "date-fns";
import classNames from "classnames";

const DateRangeSelect = ({ handleChange, disabled, isErrorsShown, errors }) => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    useEffect(() => {
        if (!(startDate && endDate)) return;

        handleChange(eachDayOfInterval({
            start: startDate,
            end: endDate
        }).map((date) => ({
            date: format(date, "yyyy-MM-dd"),
            startTime: null,
            endTime: null
        })));
    }, [startDate, endDate]);

    return (
        <div className={classNames("schedule-group", { "block-disabled": disabled })} >
            <div className="input-date-time-group">
                <div className="flex-1">
                    <DatePicker
                        label="Start Date"
                        minDate={moment().toDate()}
                        placeholderText="Select Start Date"
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        // excludeDates={excludeDates}
                        error={!disabled && isErrorsShown && errors?.availableDates && !startDate}
                    />
                </div>

                <span
                    className="input-delimiter"
                    style={{ marginTop: 26 }}
                />

                <div className="flex-1">
                    <DatePicker
                        label="End Date"
                        minDate={startDate}
                        placeholderText="Select End Date"
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        // excludeDates={excludeDates}
                        error={!disabled && isErrorsShown && errors?.availableDates && !endDate}
                    />
                </div>
            </div>
        </div>
    );
};

export default DateRangeSelect;
