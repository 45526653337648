import {
    useEffect, useState
} from "react";
import {
    useDispatch, useSelector
} from "react-redux";
import {
    Link, useNavigate, useOutletContext, useParams
} from "react-router-dom";
import api from "../../../api";
import { ReactComponent as InfoIcon } from "../../../assets/img/question-circle.svg";
import Textarea from "../../../components/ui/formElements/Textarea";
import PageLoader from "../../../components/ui/PageLoader";
import { getUser } from "../../../redux/slices/applicationSlice";
import { getChatByIdFetch } from "../../../redux/thunks/chatsThunks";

const BookingTerms = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { seminarId, chatId, requestId } = useParams();
    const [
        formData,
        handleChange,
        errors,
        handlePreviewChange,
        preview,
        setErrorConfig
    ] = useOutletContext();
    const [isErrorsShown, setIsErrorsShown] = useState(false);
    const {
        currentChatData,
        isCurrentChatDataLoading
    } = useSelector((state) => state.chats);
    const terms = currentChatData?.academyAccept?.terms;

    const handleStepChange = (name, value) => {
        handleChange(name, value);
    };

    const handleSubmit = async () => {
        setIsErrorsShown(true);

        if (!errors.hasRequiredError) {
            const res = await api.requests.createUpdateTerms({
                requestId,
                body: { terms: formData.bookingTerms }
            });

            if (res?.success) {
                navigate(`/chat/${chatId}`);
            }
        }
    };

    useEffect(() => {
        setErrorConfig({
            exceptions: [],
            requiredFields: ["bookingTerms"]
        });
    }, [formData]);
    useEffect(() => {
        handleChange("bookingTerms", terms);
    }, [currentChatData]);
    useEffect(() => {
        dispatch(getChatByIdFetch({ chatId }));
    }, []);

    return (
        <>
            {
                !isCurrentChatDataLoading
                    ? (
                        <>
                            <h4 className="row-group mb-36">
                                Set your location Availability

                                <span
                                    className="popover ico ms-2"
                                    data-tippy-content="Content"
                                >
                                    <InfoIcon />
                                </span>
                            </h4>

                            <div className="row mb-36">
                                <div className="col-12">
                                    <Textarea
                                        error={isErrorsShown && errors?.bookingTerms}
                                        label="Booking Specific Terms"
                                        maxChars={1000}
                                        onChange={(e) => handleStepChange("bookingTerms", e.target.value)}
                                        placeholder="Type or paste booking specific terms here..."
                                        style={{
                                            resize: "vertical",
                                            minHeight: 250
                                        }}
                                        textareaClasses="input--solid"
                                        value={formData.bookingTerms}
                                        wrapperClasses="input--lg"
                                    />
                                </div>
                            </div>

                            <div className="page-action-group">
                                <Link
                                    className="btn btn--default btn--sm"
                                    to={seminarId
                                        ? `/chat/${chatId}`
                                        : "../seminar-info"}
                                >
                                    Cancel
                                </Link>

                                <button
                                    className="btn btn--primary btn--sm"
                                    onClick={handleSubmit}
                                >
                                    {
                                        seminarId
                                            ? (
                                                <span className="info">
                                                    Apply Changes
                                                </span>
                                            )


                                            : (
                                                <>
                                                    <span className="info">
                                                        Continue
                                                    </span>

                                                    <span className="ico">
                                                        <svg
                                                            fill="none"
                                                            height="14"
                                                            viewBox="0 0 14 14"
                                                            width="14"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M5.25 2.625L9.625 7L5.25 11.375"
                                                                stroke="#02020A"
                                                                strokeLinecap="square"
                                                                strokeWidth="1.6"
                                                            />
                                                        </svg>
                                                    </span>
                                                </>
                                            )


                                    }
                                </button>
                            </div>
                        </>
                    )


                    : <PageLoader />
            }
        </>
    );
};

export default BookingTerms;
