import React, {
    useEffect, useRef, useState
} from "react";
import { Link } from "react-router-dom";
import { Pagination } from "swiper/modules";
import {
    Swiper, SwiperSlide
} from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";

const TopInstructors = ({ pageData }) => {
    const [swiper, setSwiper] = useState(null);
    const swiperRef = useRef();
    const instructorsList = [
        {
            name: "Mikey Musumeci",
            avatar: "inctructor-1.png",
            belt: "Black Belt",
            weave: "New Weave",
            champion: "10x World Champion"
        },
        {
            name: "Nicholas Meregali",
            avatar: "inctructor-2.png",
            belt: "Black Belt",
            weave: "New Weave",
            champion: "10x World Champion"
        },
        {
            name: "Gordon Ryan",
            avatar: "inctructor-3.png",
            belt: "Black Belt",
            weave: "New Weave",
            champion: "10x World Champion"
        },
        {
            name: "tommy langaker",
            avatar: "inctructor-4.png",
            belt: "Black Belt",
            weave: "New Weave",
            champion: "10x World Champion"
        },
        {
            name: "Tainan Dalpra",
            avatar: "inctructor-5.png",
            belt: "Black Belt",
            weave: "New Weave",
            champion: "10x World Champion"
        },
        {
            name: "John Danaher",
            avatar: "inctructor-6.png",
            belt: "Black Belt",
            weave: "New Weave",
            champion: "10x World Champion"
        }
    ];

    return (
        <section className="section">
            <div className="container">
                <div className="flex items-center justify-between flex-wrap mb-60 mb-max-md-30 gap-30">
                    <h2
                        className="heading"
                        dangerouslySetInnerHTML={{ __html: pageData?.blocks?.[2]?.blockPresets?.[0]?.value || "OUR TOP <br/> INSTRUCTORS" }}
                        style={{ maxWidth: "300px" }}
                    />

                    <p
                        className="description-section"
                        dangerouslySetInnerHTML={{ __html: pageData?.blocks?.[2]?.blockPresets?.[1]?.value || "Our top instructors are seasoned experts in Brazilian Jiu-Jitsu, each bringing a unique blend of technique, experience, and passion to the mat. They are dedicated to fostering a supportive and challenging environment, helping students achieve new levels of mastery and confidence in their practice." }}
                    />
                </div>
            </div>

            <Swiper
                className="instructors-slider"
                modules={[Pagination]}
                onSwiper={setSwiper}
                pagination={{ clickable: true }}
                ref={swiperRef}
                slidesPerView="auto"
                spaceBetween={0}
                watchSlidesProgress={true}
            >
                {instructorsList.map((item, index) => (
                    <SwiperSlide key={index}>
                        <Link
                            to="/"
                            className="inctructor-card"
                            key={index}
                        >
                            <img src={require(`../../assets/img/${item.avatar}`)} />

                            <div className="inctructor-card__text-group">
                                <span className="name-inctructor">
                                    {item.name}
                                </span>

                                <span className="description-inctructor">
                                    <span className="description-inctructor__item">
                                        {item.champion}
                                    </span>

                                    <span className="description-inctructor__item">
                                        {item.belt}
                                    </span>

                                    <span className="description-inctructor__item">
                                        {item.weave}
                                    </span>
                                </span>
                            </div>
                        </Link>
                    </SwiperSlide>
                )



                )}

            </Swiper>

            {/* <div className="inctructor-list">
                {instructorsList.map((item, index) => (
                    <Link to={'/'} className="inctructor-card" key={index}>
                        <img src={require(`../../assets/img/${item.avatar}`)} />
                        <div className="inctructor-card__text-group">
                            <span className="name-inctructor">{item.name}</span>
                            <span className="description-inctructor">
                                <span className="description-inctructor__item">{item.champion}</span>
                                <span className="description-inctructor__item">{item.belt}</span>
                                <span className="description-inctructor__item">{item.weave}</span>
                            </span>
                        </div>
                    </Link>
                ))}

            </div> */}
        </section>
    );
};

export default TopInstructors;
