import {
    useDispatch, useSelector
} from "react-redux";
import {
    Link, useParams
} from "react-router-dom";
import Button from "../../../components/ui/buttons/Button";
import { getImageSrc } from "../../../helpers/utils";
import { getUser } from "../../../redux/slices/applicationSlice";
import classNames from "classnames";
import { useState } from "react";
import { ReactComponent as AjustIcon } from "../../../assets/img/icon-ajust.svg";
import { getChatByIdFetch } from "../../../redux/thunks/chatsThunks";
import { acceptCampRequestFetch } from "../../../redux/thunks/planCampThuncks";
import StepBooking from "./StepBooking";
import { StepLocationDate } from "./CampParts/StepLocationDate";
import { StepDesign } from "./CampParts/StepDesign";
import { StepDetails } from "./CampParts/StepDetails";
import api from "../../../api";

const SidebarHeader = ({
    status, seminarDetails, statusMap, users = [], accepts = [], step
}) => {
    return (
        <>
            <div className="row-group gap--xxl pb-24 border-bottom mb-24">
                <div className="image-wrapper --small-thumb">
                    <img
                        alt={seminarDetails?.name}
                        src={getImageSrc(seminarDetails?.photos?.find((photo) => photo.isCover)?.url)}
                    />
                </div>

                <div className="col-group gap--xxl justify-between">
                    <div className="seminar-label-info">
                        <h6 className="heading">
                            {seminarDetails?.name}
                        </h6>
                    </div>

                    <Link
                        className="color-secondary ttu italic fw-600 text--xs underline"
                        to={
                            status === "done"
                                ? `/seminars/${seminarDetails?.id}`
                                : `/planed-by-academy/camps/${seminarDetails?.id}`
                        }
                    >
                        {status === "done"
                            ? "Show Seminar"
                            : "Preview"}
                    </Link>
                </div>
            </div>

            <div className="row-group gap--sm pb-24 border-bottom mb-24">
                <span className="seminar__status">
                    Status:
                </span>

                <span
                    className={classNames("seminar__dot", {
                        "--done": status === "done",
                        "--rejected": status === "rejected"
                    })}
                />

                {statusMap[status] && (
                    <span className="chat-item__name">
                        {statusMap[status].label}
                    </span>
                )}
            </div>

            <div className="row-group gap--sm pb-24">
                <span className="seminar__status">
                    Participants:
                </span>

                {
                    !!users?.length && (
                        <div className="row-group gap--xs">
                            {users?.map((user) => (
                                <div
                                    key={user.id}
                                    className={"participant"}
                                >
                                    {accepts.find(item => item.userId === user.id)?.steps?.includes(step) && <span className={"sign"}></span>}
                                    <img
                                        src={getImageSrc(user.photo)}
                                        alt={"user"}
                                    />
                                </div>
                            ))}
                        </div>
                    )}
            </div>
        </>
    );
};

const ChatSidebarCamp = ({ chatData, academyAccept }) => {
    const dispatch = useDispatch();
    const { chatId } = useParams();
    const user = useSelector(getUser);
    const seminarDetails = chatData.plancamp;
    const ownerId = seminarDetails?.userId;
    const [isTermsAccepted, setIsTermsAccepted] = useState(false);
    const isActiveSeminar = academyAccept?.status === "active";
    const isAccepted = academyAccept?.accepts?.length && academyAccept?.accepts.find(({ userId }) => userId === user?.id)?.steps?.includes(chatData?.academyAccept?.step);
    const status = academyAccept
        ? isActiveSeminar
            ? "done"
            : chatData?.academyAccept?.step || "done"
        : seminarDetails.status === "active"
            ? "done"
            : [seminarDetails?.instructors?.[0]?.invite_status_instructor, seminarDetails?.instructors?.[0]?.invite_status_academy].includes("rejected")
                ? "rejected"
                : "pending";

    const handleAccept = async () => {
        await dispatch(acceptCampRequestFetch({
            plancampId: seminarDetails.id,
            requestId: academyAccept?.id
        })).unwrap();
    };

    const handleUnaccept = async () => {
        await api.events.unacceptStep({
            eventId: chatData.plancamp.id,
            eventType: "plancamp",
            body: {
                step: chatData?.academyAccept?.step,
                requestId: chatData?.academyAccept.id 
            } //location_and_date || details || design || terms
        })
    };

    if (seminarDetails.status === "inactive") return null;

    const statusMap = {
        rejected: { label: "Request Rejected" },
        pending: { label: "Pending Request" },
        location_and_date: {
            label: "Discussing Location & Date",
            stepName: "Location & Date",
            adjustLink: `/edit-camp/${chatId}/${seminarDetails?.id}/${academyAccept?.id}`,
        },
        details: {
            label: "Discussing Details",
            stepName: "Seminar Details",
            adjustLink: `/edit-camp/${chatId}/${seminarDetails?.id}/${academyAccept?.id}/seminar-info`
        },
        design: {
            label: "Discussing Design",
            stepName: "Design & Text",
            adjustLink: `/edit-camp/${chatId}/${seminarDetails?.id}/${academyAccept?.id}/design-text`
        },
        terms: {
            label: "Booking Specific Terms",
        },
        done: {
            label: "Active Camp",
        },
        in_progress: {
            label: "In progress",
        },
        archived: {
            label: "Ended",
        },
    };
    return (
        <aside className="chat-sidebar">
            <div className="chat-sidebar__header">
                <h6 className="heading">
                    Camp Information
                </h6>
            </div>

            {
                <div className="chat-sidebar__body" >
                    <SidebarHeader
                        accepts={academyAccept?.accepts}
                        seminarDetails={seminarDetails}
                        status={status}
                        statusMap={statusMap}
                        users={chatData?.users}
                        step={chatData?.academyAccept?.step}

                    />

                    <StepLocationDate
                        canEdit={!isActiveSeminar}
                        currentStatus={status}
                        seminarDetails={seminarDetails}
                        step={"location_and_date"}
                        requestId={academyAccept.id}
                        status={["location", "pending", "rejected"]}
                    />

                    <StepDetails
                        currentStatus={status}
                        seminarDetails={seminarDetails}
                        step={"details"}
                        requestId={academyAccept.id}
                        status={["details", "pending", "rejected"]}

                    />

                    <StepDesign
                        currentStatus={status}
                        seminarDetails={seminarDetails}
                        step={"design"}
                        requestId={academyAccept.id}
                        status={["design", "pending", "rejected"]}
                    />

                    <StepBooking
                        canEdit={!isActiveSeminar}
                        currentStatus={status}
                        data={academyAccept?.terms}
                        seminarDetails={seminarDetails}
                        link={`/edit-camp/${chatId}/${chatData?.plancamp?.id}/${academyAccept?.id}/booking`}
                        status="terms"
                        step={"terms"}
                        requestId={academyAccept.id}
                        eventType="plancamp"
                    />

                    {
                        !isAccepted && !isActiveSeminar ? (
                            <div className="chat-sidebar__footer gap--xs">
                                <div className="row-group gap--xs">
                                    {
                                        user?.id === ownerId &&  statusMap?.[status]?.adjustLink && (
                                            <Link
                                                className="btn btn--default btn--sm text-14 w-full"
                                                to={statusMap?.[status]?.adjustLink}
                                            >
                                                <span className="ico">
                                                    <AjustIcon />
                                                </span>

                                                <span className="info">
                                                    Adjust
                                                </span>
                                            </Link>
                                        )
                                    }
                                    {
                                        status !== "terms" && (
                                            <Button
                                                className="btn btn--primary btn--sm text-14 w-full"
                                                onClick={handleAccept}
                                            >
                                                <span className="info">
                                                    Accept
                                                </span>
                                            </Button>
                                        )}
                                </div>



                                {
                                    status === "terms" && !isTermsAccepted && (
                                        <div className="row-group gap--xs">
                                            <Button
                                                className="btn btn--default btn--sm text-14 w-full"
                                                onClick={() => setIsTermsAccepted(true)}
                                            >
                                                <span className="info">
                                                    Skip
                                                </span>
                                            </Button>

                                            <Button
                                                className="btn btn--primary btn--sm text-14 w-full"
                                                onClick={() => setIsTermsAccepted(true)}
                                            >
                                                <span className="info">
                                                    Accept
                                                </span>
                                            </Button>
                                        </div>
                                    )
                                }

                                {
                                    status === "terms" && isTermsAccepted && (
                                        <>
                                            {user?.id !== ownerId && (
                                                <div className="row-group gap--xs">
                                                    <Button
                                                        className="btn btn--primary btn--sm text-14 w-full"
                                                        onClick={handleAccept}
                                                    >
                                                        <span className="info">
                                                            Confirm Offer
                                                        </span>
                                                    </Button>
                                                </div>
                                            )}
                                            {user?.id === ownerId && (
                                                <Link
                                                    className="btn btn--primary btn--sm text-14 w-full"
                                                    to={`/edit-camp/${chatId}/${chatData?.plancamp?.id}/${academyAccept?.id}/summary`}
                                                >
                                                    <span className="ico">
                                                        <AjustIcon />
                                                    </span>

                                                    <span className="info">
                                                        Proceed to Summary
                                                    </span>
                                                </Link>
                                            )}
                                        </>
                                    )
                                }

                            </div>
                        ) : !isActiveSeminar ? (
                            <div className="chat-sidebar__footer gap--xs">

                                <Button
                                    className="btn btn--danger btn--sm text-14 w-full"
                                    onClick={handleUnaccept}
                                >
                                    <span className="info">
                                        {"Unaccept "}
                                        {statusMap?.[status]?.stepName || ""}
                                    </span>
                                </Button>

                            </div>
                        ) : null}
                </div >
            }
        </aside>
    );
};

export default ChatSidebarCamp;
