import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import api from "../../api";
import { ReactComponent as CompassIcon } from "../../assets/img/Compass.svg";
import { getImageSrc } from "../../helpers/utils";
import { getSingleCampFetch } from "../../redux/thunks/planCampThuncks";
import { getSeminarsById } from "../../redux/thunks/seminarsThunks";
import Button from "../ui/buttons/Button";
import Textarea from "../ui/formElements/Textarea";
import PageLoader from "../ui/PageLoader";
import ModalTemplate from "./ModalTemplate";

const RequestRefundModal = ({ open, handleClose, data }) => {
    const dispatch = useDispatch()
    const invoice = data?.invoice
    const { seminarDetails, isLoading: isSeminarLoading } = useSelector((state) => state.seminars);
    const { campDetails, isLoading: isCampLoading } = useSelector((state) => state.planCamps);
    const [isConfirmationStep, setIsConfirmationStep] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [formData, setFormData] = useState({
        text: "",
        invoiceId: invoice.id
    })

    const handleChange = (name, value) => {
        setFormData(prev => ({
            ...prev,
            [name]: value 
        }))
    }

    const handleSubmit = async () => {
        try {
            setIsLoading(true)
            const res = await api.tickets.returnTicket(data.id)

            if (res?.success) {
                update && update()
                toast.success(res?.message || "The refund request has been sent successfully")
                handleClose && handleClose()
            } else {
                toast.error(res?.message || "Something went wrong")
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if(!data) return;

        if(data.eventType === "plancamp") {
            dispatch(getSingleCampFetch(data.eventId))
        } else {
            dispatch(getSeminarsById(data.eventId))
        }
    }, [])

    if (!invoice) return

    if(isSeminarLoading) {
        return <PageLoader />
    }

    console.log(campDetails);
    

    return (
        <ModalTemplate
            modalSize="modal__dialog--xs"
            onClick={handleClose}
            open={open}
        >
            {
                !isConfirmationStep ? (
                    <div className="refund_body">
                        <div>
                            <h2 className="refund_heading">Request a Refund</h2>
                            <div className="refund_body_container">
                                <img
                                    className="refund_body_img"
                                    alt={seminarDetails?.seminarName || campDetails?.name}
                                    src={getImageSrc(seminarDetails?.cover || campDetails?.cover)}
                                />
                                <div>
                                    <div className="refund_body_address mb-8">
                                        <CompassIcon />
                                        <p>
                                            {[
                                                seminarDetails?.location,
                                                seminarDetails?.address,
                                                seminarDetails?.continent,
                                                seminarDetails?.country,
                                                seminarDetails?.city
                                            ].filter(Boolean).join(", ") || "Empty"}
                                        </p>
                                    </div>
                                    <p className="refund_body_seminar_name">{seminarDetails?.seminarName || campDetails?.name}</p>
                                </div>
                            </div>
                            <ul className="refund_info_list mb-32">
                                <li className="refund_info_list_item">
                                    <span className="refund_info_list_item_title">Order Number:</span>
                                    <span className="refund_info_list_item_value">#{invoice.id}</span>
                                </li>
                                <li className="refund_info_list_item">
                                    <span className="refund_info_list_item_title">Payment Date:</span>
                                    <span className="refund_info_list_item_value">{moment(invoice.updatedAt).format("d MMM, yyyy hh:mm")}</span>
                                </li>
                                <li className="refund_info_list_item">
                                    <span className="refund_info_list_item_title">Refund Amount:</span>
                                    <span className="refund_info_list_item_value">${invoice.price + invoice.price * invoice.fee}</span>
                                </li>
                            </ul>
                            <hr className="hr-horisontal mb-32" />
                            <Textarea
                                label="Comment"
                                onChange={(e) => handleChange("text", e.target.value)}
                                placeholder="Type the reason why you need the refund..."
                                style={{ minHeight: 140 }}
                                textareaClasses="input--solid"
                                value={formData.text}
                                wrapperClasses="input--lg"
                            />
                        </div>
                        <div className="ml-auto mt-28">
                            <Button
                                className=" btn--md"
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                            <Button
                                className="btn--primary btn--md"
                                onClick={() => setIsConfirmationStep(true)}
                            >
                                Request a refund
                            </Button>


                        </div>
                    </div>
                ) : (
                    <div className="refund_confirm_body">
                        <h2 className="refund_confirm_heading">Refund Confirmation</h2>
                        <p className="refund_confirm_text">Are you sure you want to refund this transaction?
                            This cannot be undone.</p>
                        <div className="m-auto">
                            <Button
                                className=" btn--md"
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                            <Button
                                className="btn--primary btn--md"
                                onClick={handleSubmit}
                                isLoading={isLoading}
                            >
                                Yes, Proceed
                            </Button>


                        </div>
                    </div>
                )
            }
        </ModalTemplate>
    );
};

export default RequestRefundModal;
