import classNames from "classnames";
import {
    useEffect, useState
} from "react";
import {
    useDispatch, useSelector
} from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Button from "../components/ui/buttons/Button";
import Loader from "../components/ui/Loader";
import { getImageSrc } from "../helpers/utils";
import {
    deleteNotificationFetch, getAllNotificationsFetch, readNotificationsFetch
} from "../redux/thunks/notificationsThunks";
import { ReactComponent as ChevronNextIcon } from "../assets/img/ico-chevron--next.svg";
import { ReactComponent as DeleteIcon } from "../assets/img/ico-trash.svg";
import { PaginationTemplate } from "../components/parts/PaginationTemplate";

export const NotificationItem = ({ data, isAllReading }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isReading, setIsReading] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    const handleNavigate = (id, instructorId) => {
        navigate(`/instructors/${instructorId}`);
        dispatch(readNotificationsFetch({ ids: [id] }));
    };

    const handleRead = () => {
        setIsReading(true);
        dispatch(readNotificationsFetch({ ids: [data.id] })).finally(() => setIsReading(false));
    };

    const handleDelete = () => {
        setIsDeleting(true);
        dispatch(deleteNotificationFetch(data.id)).finally(() => setIsDeleting(false));
    };

    const requestTypes = ['request_seminar', 'request_plancamp', 'request_private_lesson']
    const isRequest = requestTypes.includes(data?.type);

    return (
        <div className={classNames("notification-item border-bottom", { "--readed": data.isReaded })}>
            <div className="notification-item__body">
                <div className="col-group gap--sm">
                    <div className="row-group gap--md">
                        <div className="image-wrapper avatar">
                            <img
                                alt="instructor"
                                src={getImageSrc(data?.payload?.photo || data?.payload?.user?.photo)}
                            />
                        </div>
                        <div className="col-group gap--xs">
                            <h6>
                                {data?.payload?.fullName || data?.payload?.user?.fullName}
                            </h6>
                            <p className="color-text">
                                {data.message}
                            </p>
                        </div>
                    </div>

                </div>
            </div>

            <div className="notification-item__actions">

                <Button
                    className="underline color-danger btn--md btn--square"
                    isLoading={isDeleting}
                    onClick={handleDelete}
                >
                    <DeleteIcon />
                </Button>

                {
                    !data.isReaded && (
                        <Button
                            className="btn--md underline"
                            isLoading={isReading || isAllReading}
                            onClick={handleRead}
                        >
                            Mark as read
                        </Button>
                    )}
                {
                    isRequest && data?.payload?.chatId ? (
                        <Link
                            to={`/chat/${data?.payload?.chatId}`}
                            className="btn btn--primary btn--sm w-fit"
                            style={{ width: 228 }}
                        >
                            <span className="info">
                                Open Chat
                            </span>

                            <span className="ico ico--20">
                                <ChevronNextIcon />
                            </span>
                        </Link>
                    ) : data?.payload?.instructorId ? (
                        <Button
                            className="btn--primary btn--sm w-fit"
                            onClick={() => handleNavigate(data.id, data?.payload?.instructorId)}
                            style={{ width: 228 }}
                        >
                            <span className="info">
                                See Profile
                            </span>

                            <span className="ico ico--20">
                                <ChevronNextIcon />
                            </span>
                        </Button>
                    ) : (
                        <Link
                            to={`/requests`}
                            className="btn btn--primary btn--sm w-fit"
                            style={{ width: 228 }}
                        >
                            <span className="info">
                                Open Requests
                            </span>

                            <span className="ico ico--20">
                                <ChevronNextIcon />
                            </span>
                        </Link>
                    )
                }
            </div>
        </div>
    );
};

const NotificationsPage = () => {
    const dispatch = useDispatch();
    const [isAllReading, setIsAllReading] = useState(false);
    const { notifications, isLoading, pagination } = useSelector((state) => state.notifications);
    const [params, setParams] = useSearchParams({
        sort_by: "isReaded",
        sort_as: "asc"
    })

    const handleReadAll = () => {
        setIsAllReading(true);
        dispatch(readNotificationsFetch({ ids: notifications.map((item) => item.id) })).finally(() => setIsAllReading(false));
    };

    useEffect(() => {
        dispatch(getAllNotificationsFetch(params.toString()));
    }, [params]);

    const handlePageChange = (page) => {
        setParams(prev => {
            prev.set("page", page);

            return prev
        })
    }

    return (
        <section className="col-group flex-auto py-5">
            <div className="container col-group flex-auto">
                <div className="row-group justify-between gap--md mb-24">
                    <h3 className="heading">
                        Notifications
                    </h3>

                    {
                        !!notifications?.length && (
                            <Button
                                className="btn--primary btn--md"
                                isLoading={isAllReading}
                                onClick={handleReadAll}
                            >
                                Read all
                            </Button>
                        )}
                </div>

                {
                    notifications?.length && !isLoading
                        ? (
                            <div className="col-group">
                                {
                                    notifications.map((item) => (
                                        <NotificationItem
                                            key={item.id}
                                            data={item}
                                            isAllReading={isAllReading}
                                        />
                                    )



                                    )
                                }
                            </div>
                        )


                        : isLoading
                            ? (
                                <div className="m-auto">
                                    <Loader />
                                </div>
                            )


                            : (
                                <div className="m-auto">
                                    <h5 className="heading">
                                        There is no notifications
                                    </h5>
                                </div>
                            )


                }
                <PaginationTemplate
                    {...pagination}
                    onPageChange={handlePageChange}
                    className={"mt-auto pt-24"}
                />
            </div>
        </section>
    );
};

export default NotificationsPage;
