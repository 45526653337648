import React from "react";

const WhyChoseUs = ({ blockData }) => {
    const listData = blockData?.blockPresets?.[1]?.value?.data || [
        {
            title: "Experienced instructors",
            description: "Learn from elite instructors who bring unparalleled skills and knowledge to elevate your training experience."
        },
        {
            title: "Personalized Training",
            description: "Every journey is unique, and our customized training paths are designed to fit your personal goals and needs."
        },
        {
            title: "State-of-the-Art Facilities",
            description: "Our facilities are equipped with the latest technology and comfort, creating an ideal environment for your growth."
        },
        {
            title: "Community Focus",
            description: "Join our supportive community that extends beyond the gym, fostering growth, respect, and camaraderie."
        },
        {
            title: "Flexible Scheduling",
            description: "We understand the demands of life, offering flexible scheduling to ensure you can train at your convenience."
        },
        {
            title: "Competitive Opportunities",
            description: "Embrace the challenge with regular competitions and support for external tournaments to test and push your limits."
        }
    ];

    return (
        <section className="section">
            <div className="container">
                <h2
                    className="heading text-center mb-44"
                    dangerouslySetInnerHTML={{ __html: blockData?.blockPresets?.[0]?.value || "Why choose us?" }}
                />

                <div className="row gx-60">
                    {listData.map((item, index) => (
                        <div
                            className="col-12 col-md-6 col-xl-4 flex flex-col"
                            key={index}
                        >
                            <div className="choose-item">
                                <h4 className="heading mb-20">
                                    {item.title}
                                </h4>

                                <p className="choose-item__description ">
                                    {item.description}
                                </p>
                            </div>
                        </div>
                    )



                    )}
                </div>

            </div>
        </section>
    );
};

export default WhyChoseUs;
