import { createSlice } from "@reduxjs/toolkit";
import {
    deleteNotificationFetch, getAllNotificationsFetch, readNotificationsFetch
} from "../thunks/notificationsThunks";
const initialState = {
    notifications: [],
    isLoading: false,
    errors: null,
    unreadNotificationsCount: 0,
    pagination: {
        limit: null,
        maxPages: null,
        offset: null,
        page: null,
        total: null
    }
};
const notificationsSlice = createSlice({
    name: "notifications",
    initialState,
    reducers: { setUnreadNotificationsCount: (state, { payload }) => {
        state.unreadNotificationsCount = payload;
    } },
    extraReducers: (builder) => {
        builder
            // Get All
            .addCase(getAllNotificationsFetch.fulfilled, (state, action) => {
                state.isLoading = false;
                state.notifications = action.payload?.data;
                state.pagination = action.payload?.pagination;
            })
            .addCase(getAllNotificationsFetch.pending, (state, action) => {
                state.isLoading = true;
                state.notifications = [];
                state.pagination = {};
            })
            .addCase(getAllNotificationsFetch.rejected, (state, action) => {
                state.isLoading = false;
                state.notifications = [];
                state.pagination = {};
            })
            // Read
            .addCase(readNotificationsFetch.fulfilled, (state, action) => {
                const ids = action.payload?.data?.ids;

                state.notifications = state.notifications.map((item) => {
                    if (ids.includes(item.id)) {
                        item.isReaded = true;
                    }

                    return item;
                });

                if (state.notifications?.some((item) => item.isReaded)) {
                    state.unreadNotificationsCount = 0;
                }
            })
            // Delete
            .addCase(deleteNotificationFetch.fulfilled, (state, action) => {
                state.notifications = state.notifications.filter((item) => parseInt(item.id) !== parseInt(action.payload?.data?.id));

                if (!state.notifications?.length) {
                    state.unreadNotificationsCount = 0;
                }
            });
    }
});

export const { setUnreadNotificationsCount } = notificationsSlice.actions;
export const getUnreadNotificationsCount = (state) => state.notifications.unreadNotificationsCount;
export default notificationsSlice.reducer;
