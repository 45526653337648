import classNames from "classnames";
import { useState } from "react";
import api from "../../../api";
import Button from "../../../components/ui/buttons/Button";

export const Step = ({
    currentStatus, label, children, status, statuses = [
        "location_and_date",
        "details",
        "design",
        "terms",
        "done"
    ],
    eventId,
    eventType,
    requestId,
    step
}) => {
    const isDone = statuses.indexOf(currentStatus) > statuses.indexOf(Array.isArray(status) && status?.length
        ? status[0]
        : status);
    const isCurrent = Array.isArray(status)
        ? status.includes(currentStatus)
        : status === currentStatus;
    const [isOpen, setIsOpen] = useState(isCurrent
        ? true
        : false);

    const handleUnaccept = async () => {
        await api.events.unacceptStep({
            eventId,
            eventType,
            body: {
                step,
                requestId 
            } //location_and_date || details || design || terms
        })
    };


    const canUnaccept = statuses.indexOf(currentStatus) > statuses.indexOf(step)
    
    return (
        <>
            <div
                className={classNames("chat-sidebar__accordion", { "block-disabled-20": !isDone && !isCurrent })}
                onClick={isDone || isCurrent
                    ? () => setIsOpen(!isOpen)
                    : () => { }}
            >
                <h4>
                    {label}
                </h4>

                <span className="ico">
                    <svg
                        fill="none"
                        height="22"
                        viewBox="0 0 22 22"
                        width="22"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g clipPath="url(#clip0_15_17038)">
                            <path
                                d="M3.4375 11H18.5625"
                                stroke="#02020A"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.5"
                            />

                            {
                                !isOpen
                                    ? (
                                        <path
                                            d="M11 3.4375V18.5625"
                                            stroke="#02020A"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    )


                                    : null
                            }
                        </g>

                        <defs>
                            <clipPath id="clip0_15_17038">
                                <rect
                                    fill="white"
                                    height="22"
                                    width="22"
                                />
                            </clipPath>
                        </defs>
                    </svg>
                </span>
            </div>

            {
                isOpen && (
                    <div
                        className={`col-group gap-24 ${!isCurrent && isOpen
                            ? "pb-24 border-bottom"
                            : ""}`}
                    >
                        {children}
                        {
                            isDone && currentStatus !== "done" && canUnaccept && step !== currentStatus && (
                                <Button
                                    className="btn btn--danger btn--sm text-14 w-full"
                                    onClick={handleUnaccept}
                                >
                                    <span className="info">
                                        {"Unaccept "}
                                        {label || ""}
                                    </span>
                                </Button>
                            )
                        }

                    </div>
                )
            }
        </>
    );
};
