import React, {
    useEffect, useState
} from "react";
import {
    Outlet, useLocation, useNavigate, useParams
} from "react-router-dom";
import PlanningSteps from "../parts/PlanningSteps";
import { checkErrors } from "../../../helpers/utils";
import {
    useDispatch, useSelector
} from "react-redux";
import { getUser } from "../../../redux/slices/applicationSlice";
import SeminarCardPreview from "../parts/SeminarCardPreview";
import { getAcademyGeneralInfoFetch } from "../../../redux/thunks/academyGeneralInfoThunks";
import { getAcademyGeneralInfo } from "../../../redux/slices/academyGeneralInfoSlice";
import {
    getSeminarFormData, resetSeminarFormData, setSeminarFormData
} from "../../../redux/slices/planningSlice";
import api from "../../../api";
import { getSingleCampFetch } from "../../../redux/thunks/planCampThuncks";
const menuItems = [
    {
        title: "Time & Date",
        to: "",
        icon: "date"
    }, {
        title: "Camp Information",
        to: "seminar-info",
        icon: "info"
    }, {
        title: "Design & Text",
        to: "design-text",
        icon: "design"
    }
];
const initialDataInstructor = {
    startAt: "",
    endAt: "",
    name: "",
    teachingStyles: [],
    summary: "",
    photos: [],
    videos: [],
    what_included: [],
    schedule: [],
    invited_instructors: [],
    instructor: {

        // camp_price: {
        //     from: "",
        //     to: ""
        // }, 
        allowPrivateLessons: "0",
        allowUnder18: "0"
    },
    traningStyle: "",
    maxGymCapacity: "",
    priceFrom: "",
    priceTo: "",
    faq: [],
    hotelLink: [],
    instructorLocation: "",
    coverImageName: "",
    coverImageId: "",
    address: "",
    country: ""
};
const initialDataAcademy = {
    startAt: "",
    endAt: "",
    traningStyle: "",
    maxGymCapacity: "",
    specificBelt: "",
    allowUnder18: "0",
    name: "",
    teachingStyles: [],
    summary: "",
    photos: [],
    videos: [],
    what_included: [],
    schedule: [],
    faq: [],
    hotelLink: [],
    coverImageName: "",
    coverImageId: "",
    address: "",
    country: ""
};

const PlanCampWrapper = () => {
    const { pathname } = useLocation();
    const { campId } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector(getUser);
    const academyInfo = useSelector(getAcademyGeneralInfo);
    const { campDetails, isLoading } = useSelector((state) => state.planCamps);
    const [instructorInfo, setInstructorInfo] = useState(null);
    const formData = useSelector(getSeminarFormData);
    const [errors, setErrors] = useState({});
    const [errorConfig, setErrorConfig] = useState({});
    const [preview, setPreview] = useState({
        availableDates: [],
        location: "",
        seminarName: "",
        instructors: [],
        photo: "",
        fullName: "",
        minimalPriceTicket: "",
        cover: null,
        traningStyle: "",
        ...formData
    });

    const handlePreviewChange = (name, value) => {
        setPreview((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const handleChange = (name, value) => {
        dispatch(setSeminarFormData({
            name,
            value
        }));

        if (name === "startAt" || name === "endAt") {
            handlePreviewChange("availableDates", [
                {
                    date: name === "startAt"
                        ? value
                        : formData?.startAt,
                    startTime: "00:00",
                    endTime: "23:59"
                }, {
                    date: name === "endAt"
                        ? value
                        : formData?.endAt,
                    startTime: "00:00",
                    endTime: "23:59"
                }
            ]);
        }

        if (preview.hasOwnProperty(name)) {
            handlePreviewChange(name, value);
        }
    };

    useEffect(() => {
        if (user?.role === "academy" && academyInfo) {
            handlePreviewChange("location", formData.isAcademyLocation
                ? academyInfo?.addressLine
                : formData.customLocation);
        }
    }, [formData?.isAcademyLocation, formData?.customLocation]);
    useEffect(() => {
        if (user?.role === "academy" && academyInfo) {
            handlePreviewChange("fullName", user?.profile?.name || "");
            handlePreviewChange("photo", user?.profile?.photo || "");
            handlePreviewChange("location", formData.isAcademyLocation
                ? academyInfo?.addressLine
                : formData.customLocation);
        }

        if (user?.role === "instructor" && instructorInfo) {
            handlePreviewChange("fullName", user?.profile?.fullName || "");
            handlePreviewChange("photo", user?.profile?.photo || "");
            handlePreviewChange("location", instructorInfo?.addressLine);
            handleChange("instructorLocation", instructorInfo?.addressLine || "");
        }
    }, [user, academyInfo, instructorInfo]);

    const getInstructorGeneralInfo = async () => {
        const res = await api.profiles.getInstructorGeneralInfo();

        if (res.success) {
            setInstructorInfo(res.data);
        }
    };

    useEffect(() => {
        if (user?.role === "academy") {
            dispatch(getAcademyGeneralInfoFetch());
        }

        if (user?.role === "instructor") {
            getInstructorGeneralInfo();
        }
    }, [user]);
    useEffect(() => {
        setErrors(checkErrors(formData, errorConfig));
    }, [formData, errorConfig]);
    useEffect(() => {
        if (!campId) return;

        dispatch(getSingleCampFetch(campId));
    }, [campId]);
    useEffect(() => {
        if (campId && campDetails && !isLoading) {
            if (location.state?.previousLocation !== "/template-editor") {
                const initialData = {
                    startAt: campDetails?.startAt,
                    endAt: campDetails?.endAt,
                    name: campDetails?.name,
                    teachingStyles: campDetails.teachingStyles ?? [],
                    summary: campDetails?.summary,
                    photos: campDetails?.photos.map((photo) => ({
                        id: photo?.id,
                        url: photo?.url
                    })),
                    videos: campDetails?.videos,
                    specificBelt: campDetails?.specificBelt,
                    what_included: campDetails?.what_included?.length
                        ? campDetails?.what_included?.map((item) => item.id)
                        : [],
                    schedule: campDetails?.schedules?.length
                        ? campDetails?.schedules?.map((date) => {
                            return date.map((event) => ({
                                time: event?.start,
                                name: event?.name
                            }));
                        })
                        : [],
                    invited_instructors: campDetails?.instructors,
                    traningStyle: campDetails?.traningStyle ?? "",
                    maxGymCapacity: campDetails?.maxGymCapacity ?? "",
                    priceFrom: campDetails?.priceFrom,
                    priceTo: campDetails?.priceTo,
                    faq: campDetails?.faq,
                    hotelLink: campDetails?.hotelLink,
                    instructorLocation: campDetails?.instructorLocation,
                    coverImageId: campDetails?.photos?.find((photo) => photo?.isCover)?.id,
                    instructor: {
                        allowPrivateLessons: campDetails?.allowPrivateLessons,
                        allowUnder18: campDetails?.allowUnder18
                            ? "1"
                            : 0
                    },
                    minimalPriceTicket: campDetails?.minimalPriceTicket,
                    instructorPrice: campDetails?.instructorPrice,
                    country: campDetails?.country,
                };
                dispatch(resetSeminarFormData(initialData));
                // handleChange("availableDates", formData?.availableDates || formData?.seminarDates || "")
                handlePreviewChange("cover", campDetails?.photos?.find((photo) => photo?.isCover));
            }
        }

        if (!campId && user) {
            if (location.state?.previousLocation !== "/template-editor") {
                if (!user?.role) return;

                dispatch(resetSeminarFormData(user?.role === "academy"
                    ? initialDataAcademy
                    : initialDataInstructor));
                navigate("/plan-camp");
            }
        }
    }, [campDetails, user]);

    return (
        <>
            <PlanningSteps menuItems={menuItems} />

            <section className="section-42">
                <div className="container">
                    <div className="section-row-block">
                        <div className="section-row-block__body">
                            <Outlet
                                context={[
                                    formData,
                                    handleChange,
                                    errors,
                                    handlePreviewChange,
                                    preview,
                                    setErrorConfig
                                ]}
                                steps={menuItems}
                            />
                        </div>

                        {
                            !pathname.includes("success") && <SeminarCardPreview {...preview} />
                        }
                    </div>
                </div>
            </section>
        </>
    );
};

export default PlanCampWrapper;
