import React, {
    useEffect, useState
} from "react";
import BreadCrumds from "../../../components/parts/BreadCrumds";
import SinglePageTitleBlock from "../parts/SinglePageTitleBlock";
import GalleryImages from "../parts/GalleryImages";
import SinglePageTeachSyles from "../parts/SinglePageTeachSyles";
import SeminarsLockedAt from "../../../components/parts/SeminarsLockedAt";
import SeminarSingleDefaultAsside from "../parts/SeminarSingleDefaultAsside";
import SeminarSinglePendingAsside from "../parts/SeminarSinglePendingAsside";
import SinglePageInstructorsBlock from "../parts/SinglePageInstructorsBlock";
import SinglePageMapBlock from "../parts/SinglePageMapBlock";
import SinglaPageSummary from "../parts/SinglaPageSummary";
import PageLoader from "../../../components/ui/PageLoader";
import { useParams } from "react-router-dom";
import {
    useDispatch, useSelector
} from "react-redux";
import {
    getAllCampsFetch, getSingleCampFetch
} from "../../../redux/thunks/planCampThuncks";
import { getCampDetails } from "../../../redux/slices/planCampSlice";
import WhatsIncluded from "../parts/WhatsIncluded";
import HotelsPart from "../parts/HotelsPart";
import Accordeon from "../../../components/ui/accordeons/Accordeon";
import moment from "moment";
import { getHistoryFetch } from "../../../redux/thunks/historyThunks";

export const CampsSingle = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const { camps, campDetails, isLoading } = useSelector((state) => state.planCamps);
    const pageData = useSelector(getCampDetails);
    const { history, isLoading: isHistoryLoading } = useSelector((state) => state.history);
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index
            ? null
            : index);
    };

    useEffect(() => {
        dispatch(getSingleCampFetch(id));
        // dispatch(getAllCampsFetch())
        dispatch(getHistoryFetch("plancamp"));
    }, [dispatch, id]);

    if (isLoading) return <PageLoader />;

    const seminarsLookedAt = history?.filter((item) => +item?.plancamp?.id !== +id) || [];

    return (
        <>
            <section className="pt-28 pb-42">
                <div className="container">
                    <BreadCrumds
                        breadcrumbs={[
                            {
                                label: "Seminars",
                                link: "/seminars"
                            }, { label: String(pageData?.name).toLocaleLowerCase() }
                        ]}
                    />

                    <SinglePageTitleBlock
                        allowUnder18={pageData?.allowUnder18}
                        location={pageData?.location}
                        status={pageData?.status}
                        title={pageData?.seminarName || pageData?.name}
                        traningStyle={pageData?.traningStyle}
                    />

                    {pageData?.photos && pageData?.photos.length > 0
                        ? <GalleryImages gallery={pageData?.photos} />
                        : null}
                </div>
            </section>

            <section className="pb-42">
                <div className="container">
                    <div className="border-bottom pb-42">
                        <div className="row gx-80">
                            <div className="col-7">

                                {pageData?.teachingStyles &&
                                    <SinglePageTeachSyles teachingStyles={pageData?.teachingStyles} />}

                                <SinglaPageSummary
                                    summary={pageData?.summary}
                                    videos={pageData?.videos}
                                />

                                <HotelsPart data={campDetails?.hotelLink} />

                                <WhatsIncluded data={campDetails?.what_included} />

                                <div className="border-bottom mb-42 pb-42">
                                    <SinglePageInstructorsBlock />
                                </div>

                                <div className="border-bottom mb-42 pb-42">
                                    <h4 className="heading mb-32">
                                        Camp Schedule
                                    </h4>

                                    {
                                        pageData?.schedules?.length
                                            ? pageData?.schedules.map((item, index) => (
                                                <div
                                                    className="camp-schedule"
                                                    key={index}
                                                >
                                                    <div className="camp-schedule__date">
                                                        <span className="camp-schedule__number-day">
                                                            Day{index + 1}
                                                        </span>

                                                        <span className="camp-schedule__day">
                                                            {moment(item[0]?.date).format("MMM DD")}
                                                        </span>
                                                    </div>

                                                    <ul className="camp-schedule__list">
                                                        {
                                                            item?.length
                                                                ? item.map((event, i) => (
                                                                    <li
                                                                        key={i}
                                                                        className={"camp-schedule__item"}
                                                                    >
                                                                        <div className={"camp-schedule__group-time"}>
                                                                            <div className={"camp-schedule__clock-img"}>
                                                                                <img src={require("../../../assets/img/icon-alarm-clock-time-timer.svg").default} />
                                                                            </div>
                                                                            <span className={"camp-schedule__time"}>
                                                                                {event?.start}
                                                                            </span>
                                                                        </div>
                                                                        <span>{event?.name}</span>
                                                                    </li>
                                                                ))
                                                                : (
                                                                    <p>
                                                                        No events
                                                                    </p>
                                                                )
                                                        }
                                                    </ul>
                                                </div>
                                            )



                                            )
                                            : (
                                                <h4>
                                                    There is no data
                                                </h4>
                                            )


                                    }

                                </div>

                                <div className="camp-faq">
                                    <h4 className="heading mb-16">
                                        Camp FAQ
                                    </h4>

                                    {
                                        pageData?.faq?.length
                                            ? pageData?.faq.map((item, index) => (
                                                <Accordeon
                                                    className="w-full"
                                                    index={index}
                                                    isActive={activeIndex === index}
                                                    key={index}
                                                    onClick={() => toggleAccordion(index)}
                                                    onlyOneIsOpen={true}
                                                    title={item.question}
                                                >
                                                    {item?.answer}
                                                </Accordeon>
                                            )



                                            )
                                            : null
                                    }
                                </div>

                            </div>

                            <div className="col-5">
                                {pageData?.status === "pending"
                                    ? (
                                        <SeminarSinglePendingAsside
                                            pageData={pageData}
                                            type="camp"
                                        />
                                    )


                                    : <SeminarSingleDefaultAsside type="camp" />}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {
                !isHistoryLoading && (
                    <SeminarsLockedAt
                        data={seminarsLookedAt}
                        sectionTitle="CAMPS YOU LOOKED AT"
                    />
                )}
        </>
    );
};
