import { pick } from "lodash";
import {
    useEffect, useState
} from "react";
import {
    useDispatch, useSelector
} from "react-redux";
import {
    Link, useNavigate, useOutletContext, useParams
} from "react-router-dom";
import InviteInstructorModal from "../../../components/modals/InviteInstructorModal";
import CheckboxSwitch from "../../../components/ui/formElements/CheckboxSwitch";
import DatePicker from "../../../components/ui/formElements/DatePicker";
import Input from "../../../components/ui/formElements/Input";
import SelectMulty from "../../../components/ui/formElements/SelectMulty";
import SelectOne from "../../../components/ui/formElements/SelectOne";
import {
    belts, languages, sharedProfits, teachingStyles, trainingStyle
} from "../../../constants";
import {
    checkErrors, convertObjectToFormData, getImageSrc
} from "../../../helpers/utils";
import { getUser } from "../../../redux/slices/applicationSlice";
import { updateSeminarFetch } from "../../../redux/thunks/seminarsThunks";

const SeminarInformation = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { seminarId, chatId } = useParams();
    const user = useSelector(getUser);
    const { seminarDetails, isLoading } = useSelector((state) => state.seminars);
    const [isErrorsShown, setIsErrorsShown] = useState(false);
    const [
        formData,
        handleChange,
        errors,
        handlePreviewChange,
        preview,
        setErrorConfig
    ] = useOutletContext();
    const [isSharedProfit, setIsSharedProfit] = useState(formData.sharedProfit || false);
    const [editedFields, setEditedFields] = useState([]);

    const handleStepChange = (name, value) => {
        handleChange(name, value);
        setEditedFields((prev) => [...prev, name]);
    };

    const handleSubmit = async () => {
        setIsErrorsShown(true);
        console.log(errors);

        if (!errors?.hasRequiredError) {
            if (seminarId) {
                const body = new FormData();
                const dataToUpdate = pick(formData, editedFields);

                convertObjectToFormData(dataToUpdate, body);
                const res = await dispatch(updateSeminarFetch({
                    seminarId,
                    body
                })).unwrap();

                if (res) {
                    navigate(`/chat/${chatId}`);
                }
            } else {
                navigate("../design-text");
            }
        }
    };

    const teachingStyleOptions = teachingStyles.map(({ key, title }) => ({
        value: key,
        label: title
    }));
    const specificBeltOptions = belts.filter(({ key }) => !["blue_belt", "white_belt"].includes(key)).map(({ key, title }) => ({
        value: key,
        label: title
    }));

    const traningStyleOptions = trainingStyle.map(({ key, title }) => ({
        value: key,
        label: title
    }));
    const languageOptions = languages.map(({ title, key }) => ({
        value: key,
        label: title
    }));
    const sharedProfitOptions = sharedProfits.map(({ key, title }) => ({
        label: title,
        value: key 
    }))
    const [inviteInstructorModalOpen, setInviteInstructorModalOpen] = useState(false);
    const [instructors, setInstructors] = useState(preview?.instructors);

    const handleAddInstructors = (data) => {
        setInstructors(data);
        handleStepChange("instructors", data.map((item) => item?.user?.id));
        setInviteInstructorModalOpen(false);
        handlePreviewChange("instructors", data);
    };

    const handleRemoveInstructor = (index) => {
        const updatedInstructors = instructors.filter((_, i) => i !== index);

        handleStepChange("instructors", updatedInstructors.map((item) => item?.user?.id));
        setInstructors(updatedInstructors);
        handlePreviewChange("instructors", updatedInstructors);
    };


    useEffect(() => {
        setErrorConfig({
            exceptions: [
                ...isSharedProfit
                    ? ["seminarMinPrice", "seminarMaxPrice", "instructorPrice"]
                    : [],
                "minAttendanceRequirement"
            ],
            requiredFields: [
                ...(seminarId ? ["minimalPriceTicket", "instructorPrice", "maxGymCapacity"] : []),
                "traningStyle",
                "targetAudience",
                ...user?.role === "academy"
                    ? ["maxGymCapacity", "specificBelt"]
                    : [],
                ...isSharedProfit && seminarId
                    ? ["minAttendance"]
                    : [],
                ...isSharedProfit
                    ? ["sharedProfit"]
                    : ["seminarMinPrice", "seminarMaxPrice"]
            ]
        });
    }, [formData, isSharedProfit]);

    useEffect(() => {
        if (!seminarDetails || isLoading) return;

        setIsSharedProfit(seminarDetails?.sharedProfit);
    }, [seminarDetails, isLoading]);

    function calculateMinAttendance(ticketPrice, seminarMinPrice) {
        if (!ticketPrice || ticketPrice <= 0) return 0;

        if (!seminarMinPrice || seminarMinPrice <= 0) return 0;

        const minAttendanceRequirement = Math.ceil(seminarMinPrice / ticketPrice);

        handleStepChange("minAttendanceRequirement", minAttendanceRequirement);

        return minAttendanceRequirement;
    }

    useEffect(() => {
        calculateMinAttendance(formData.minimalPriceTicket, formData.instructorPrice);
    }, [formData.minimalPriceTicket, formData.instructorPrice]);

    return (
        <>
            <div className="row gx-16 py-24 mb-42">
                {
                    !seminarId && (
                        <div className="col-12">
                            <div className="form-group input--lg mb-16">
                                <label className="label">
                                    Price range for seminar
                                </label>

                                <div className="row-group w-full gap-10 mobile-range">
                                    <div className="form-group flex-auto input--lg">
                                        <Input
                                            disabled={isSharedProfit}
                                            error={isErrorsShown && errors.seminarMinPrice}
                                            inputClasses="input--solid"
                                            onChange={(e) => handleStepChange("seminarMinPrice", e.target.value)}
                                            placeholder="3000"
                                            type="text"
                                            value={formData.seminarMinPrice}
                                            wrapperClasses="input--currency"
                                        />
                                    </div>

                                    <span className="input-delimiter" />

                                    <div className="form-group flex-auto input--lg">
                                        <Input
                                            disabled={isSharedProfit}
                                            error={isErrorsShown && errors.seminarMaxPrice}
                                            inputClasses="input--solid"
                                            onChange={(e) => handleStepChange("seminarMaxPrice", e.target.value)}
                                            placeholder="4000"
                                            type="text"
                                            value={formData.seminarMaxPrice}
                                            wrapperClasses="input--currency"

                                        />
                                    </div>
                                </div>
                            </div>

                            {!isSharedProfit && (
                                <p className="color-text text-14 mb-16">
                                    Seminar pricing varies based on duration. Please adjust parameters based on your seminar duration.
                                </p>
                            )}

                            <CheckboxSwitch
                                checked={isSharedProfit}
                                onChange={() => {
                                    if (!isSharedProfit) {
                                        handleStepChange("seminarMinPrice", "");
                                        handleStepChange("seminarMaxPrice", "");
                                    } else {
                                        handleStepChange("sharedProfit", "");
                                    }

                                    setIsSharedProfit(!isSharedProfit);
                                }}
                                text="Set “Shared Profit” for seminar"
                            />
                        </div>
                    )
                }

                <div className="col-12">
                    <div className="row gx-16 mb-16">
                        {
                            seminarId && (
                                <div className="col-md-6 col-sm-6 col-12">
                                    <div className="form-group flex-auto input--lg">
                                        <label className="label">
                                            Minimal Ticket Price
                                        </label>

                                        <Input
                                            error={isErrorsShown && errors?.minimalPriceTicket}
                                            inputClasses="input--solid"
                                            onChange={(e) => handleStepChange("minimalPriceTicket", e.target.value)}
                                            placeholder="100.00"
                                            type="text"
                                            value={formData.minimalPriceTicket}
                                            wrapperClasses="form-group flex-auto input--lg input--currency"
                                        />
                                    </div>
                                </div>
                            )}
                        {
                            seminarId && (
                                <div className="col-md-6 col-sm-6 col-12">
                                    <Input
                                        error={isErrorsShown && errors?.instructorPrice}
                                        wrapperClasses="form-group flex-auto input--lg input--currency"
                                        inputClasses="input--solid"
                                        label="Instructor Price"
                                        onChange={(e) => handleStepChange("instructorPrice", e.target.value)}
                                        placeholder="100"
                                        value={formData.instructorPrice}
                                        disabled={isSharedProfit}
                                    />
                                </div>

                            )
                        }
                    </div>
                    {
                        seminarId && (
                            <CheckboxSwitch
                                checked={isSharedProfit}
                                onChange={() => {
                                    if (!isSharedProfit) {
                                        handleStepChange("seminarMinPrice", "");
                                        handleStepChange("seminarMaxPrice", "");
                                        handleStepChange("instructorPrice", "");
                                    } else {
                                        handleStepChange("sharedProfit", "");
                                    }

                                    setIsSharedProfit(!isSharedProfit);
                                }}
                                text="Set “Shared Profit” for seminar"
                            />
                        )
                    }
                </div>
                <div className="col-md-6 col-sm-6 col-12">
                    <SelectOne
                        disabled={!isSharedProfit}
                        error={isErrorsShown && errors?.sharedProfit}
                        label="Shared Profit (Instructor / Academy)"
                        onChange={(option) => handleStepChange("sharedProfit", option.value)}
                        options={sharedProfitOptions}
                        placeholder="Specific Shared Profit"
                        value={formData.sharedProfit}
                        wrapperClasses="select--outline select--outline-bg input--lg w-full"
                    />
                </div>

                <div className="col-12">
                    <hr className="hr" />
                </div>

                <div className="col-md-6 col-sm-6 col-12">
                    <SelectOne
                        error={isErrorsShown && errors?.traningStyle}
                        label="Gi or Nogi"
                        onChange={(option) => handleStepChange("traningStyle", option.value)}
                        options={traningStyleOptions}
                        placeholder="Gi or Nogi"
                        value={formData.traningStyle}
                        wrapperClasses="select--outline select--outline-bg input--lg w-full"
                    />
                </div>

                {
                    user?.role === "academy" && (
                        <>
                            <div className="col-md-6 col-sm-6 col-12">
                                <SelectOne
                                    error={isErrorsShown && errors?.specificBelt}
                                    label="Specific Belt for instructor"
                                    onChange={(option) => handleStepChange("specificBelt", option.value)}
                                    options={specificBeltOptions}
                                    placeholder="Specific Belt for instructor"
                                    value={formData.specificBelt}
                                    wrapperClasses="select--outline select--outline-bg input--lg w-full"
                                />
                            </div>

                            <div className="col-12">
                                <SelectMulty
                                    error={isErrorsShown && errors?.languages}
                                    label="Preferred Language"
                                    onChange={(options) => handleStepChange("languages", options.map((option) => option.value))}
                                    options={languageOptions}
                                    placeholder="Select preferred languages"
                                    value={formData.languages}
                                    wrapperClasses="select--outline select--outline-bg input--lg w-full"
                                />
                            </div>
                        </>
                    )}

                <div className="col-md-6 col-sm-6 col-12">
                    <SelectOne
                        error={isErrorsShown && errors?.targetAudience}
                        label="Open to"
                        onChange={(option) => handleStepChange("targetAudience", option.value)}
                        options={[
                            {
                                value: "any",
                                label: "Everyone"
                            }, {
                                value: "female",
                                label: "Female Only"
                            }, {
                                value: "male",
                                label: "Male Only"
                            }, {
                                value: "kids",
                                label: "Kids Only"
                            }
                        ]}
                        placeholder="Select target audience"
                        value={formData.targetAudience}
                        wrapperClasses="select--outline select--outline-bg input--lg w-full"
                    />
                </div>

                {/* <div className="col-md-6 col-sm-6 col-12"></div> */}
                {
                    user?.role === "academy" && (
                        <div className="col-md-6 col-sm-6 col-12">
                            <Input
                                error={isErrorsShown && errors?.maxGymCapacity}
                                inputClasses="input--solid"
                                label="Max Gym capacity"
                                onChange={(e) => handleStepChange("maxGymCapacity", e.target.value)}
                                placeholder="0"
                                type="text"
                                value={formData.maxGymCapacity}
                                wrapperClasses="input--lg"
                            />
                        </div>
                    )}

                {
                    seminarId && (
                        <>
                            <div className="col-md-6 col-sm-6 col-12">
                                <Input
                                    inputClasses={"input--solid"}
                                    label={"Minimum Attendance Requirement"}
                                    readOnly={!isSharedProfit}
                                    type={"text"}
                                    wrapperClasses={"input--lg"}
                                    error={isErrorsShown && isSharedProfit && errors?.minAttendance}
                                    onChange={(e) => !isSharedProfit ? () => { } : handleStepChange("minAttendance", e.target.value)}
                                    placeholder={"0"}
                                    value={isSharedProfit ? formData.minAttendance : formData.minAttendanceRequirement}

                                />
                            </div>

                            <div className="col-md-6 col-sm-6 col-12">
                                <DatePicker
                                    dateFormat={"dd.MM.yyyy"}
                                    error={isErrorsShown && errors?.minAttendanceDeadline}
                                    label={"Minimum Attendance Deadline"}
                                    placeholderText={"Select Event Date"}
                                    readOnly
                                    selected={formData.minAttendanceDeadline}
                                    onChange={() => { }}
                                />

                            </div>
                        </>
                    )}

                <CheckboxSwitch
                    checked={formData.allowUnder18}
                    onChange={() => handleStepChange("allowUnder18", !formData.allowUnder18)}
                    text="Do you allow students under the age of 18 to attend your seminar/camp?"
                />

                <div className="col-12">
                    <hr className="hr" />
                </div>

                <div className="col-12">
                    <SelectMulty
                        error={isErrorsShown && errors?.teachingStyles}
                        label={user?.role === "instructor"
                            ? "What do you want to teach?"
                            : "Preferred Teaching Style"}
                        onChange={(options) => handleStepChange("teachingStyles", options.map((option) => option.value))}
                        options={teachingStyleOptions}
                        placeholder="Add teaching style..."
                        value={formData.teachingStyles}
                        wrapperClasses="select--outline select--outline-bg input--lg w-full my-18"
                    />
                </div>

                {/* {
                    !isSharedProfit ?
                        <>
                            <div className="col-12">
                                <hr className="hr" />
                            </div>
                            <div className="col-12">
                                <div className="row g-16">
                                    <div className="col-12 mb-42">
                                        <ul className="order-list">
                                            <li className="order-list--item">
                                                <p className="order-list--text">Instructor Cut:</p>
                                                <p className="order-list--value">$ {instructorCut()}</p>
                                            </li>
                                            <li className="order-list--item">
                                                <p className="order-list--text">Academy Potential Revenue:</p>
                                                <p className="order-list--value">$ {academyRevenue()}</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </>
                        :
                        null
                } */}
                <div className="col-12">
                    {
                        user?.role === "instructor"
                            ? (
                                <>
                                    <hr className="hr-horisontal mb-42" />

                                    <div className="mb-42">
                                        <h6 className="heading mb-32">
                                            Invite Additional Instructor

                                            <span className="color-grey">
                                                (optional)
                                            </span>
                                        </h6>

                                        {
                                            instructors?.length
                                                ? (
                                                    <ul className="invite-instructors-list mb-32">
                                                        {
                                                            instructors.map((instructor, index) => (
                                                                <li
                                                                    key={index}
                                                                    className="invite-instructor__item"
                                                                >
                                                                    <div className="invite-instructor__img">
                                                                        <img
                                                                            src={getImageSrc(instructor.photo)}
                                                                            alt={instructor.fullName}
                                                                        />
                                                                    </div>

                                                                    <div className="invite-instructor__group-info">
                                                                        <span className="invite-instructor__name">
                                                                            {instructor.fullName}
                                                                        </span>

                                                                        <span className="invite-instructor__email color-grey fs-14">
                                                                            {instructor?.user?.email}
                                                                        </span>
                                                                    </div>

                                                                    <button
                                                                        onClick={() => handleRemoveInstructor(index)}
                                                                        className="btn"
                                                                    >
                                                                        <svg
                                                                            width="24"
                                                                            height="25"
                                                                            viewBox="0 0 24 25"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                        >
                                                                            <g>
                                                                                <path
                                                                                    d="M18.75 5.75L5.25 19.25"
                                                                                    stroke="#BDBDBD"
                                                                                    strokeWidth="1.2"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                >
                                                                                </path>

                                                                                <path
                                                                                    d="M18.75 19.25L5.25 5.75"
                                                                                    stroke="#BDBDBD"
                                                                                    strokeWidth="1.2"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                >
                                                                                </path>
                                                                            </g>

                                                                            <defs>
                                                                                <clipPath>
                                                                                    <rect
                                                                                        width="24"
                                                                                        height="24"
                                                                                        fill="white"
                                                                                        transform="translate(0 0.5)"
                                                                                    >
                                                                                    </rect>
                                                                                </clipPath>
                                                                            </defs>
                                                                        </svg>
                                                                    </button>
                                                                </li>
                                                            )

                                                            )
                                                        }
                                                    </ul>
                                                )


                                                : null
                                        }

                                        <button
                                            className="btn btn--sm btn--primary"
                                            data-modal="#invite-instructors"
                                            onClick={() => setInviteInstructorModalOpen(true)}
                                        >
                                            <span className="info">
                                                Send Invite
                                            </span>

                                            <span className="ico">
                                                <svg
                                                    fill="none"
                                                    height="14"
                                                    viewBox="0 0 14 14"
                                                    width="14"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <g clipPath="url(#clip0_910_51633)">
                                                        <path
                                                            d="M5.25 2.625L9.625 7L5.25 11.375"
                                                            stroke="#02020A"
                                                            strokeLinecap="square"
                                                            strokeWidth="1.6"
                                                        />
                                                    </g>

                                                    <defs>
                                                        <clipPath id="clip0_910_51633">
                                                            <rect
                                                                fill="white"
                                                                height="14"
                                                                width="14"
                                                            />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </span>
                                        </button>
                                    </div>
                                </>
                            )


                            : null
                    }
                </div>
            </div>

            <div className="page-action-group mobile-actions">
                <Link
                    className="btn btn--default btn--sm"
                    to=".."
                >
                    Cancel
                </Link>

                <button
                    className="btn btn--primary btn--sm"
                    onClick={handleSubmit}
                >
                    {
                        seminarId
                            ? (
                                <span className="info">
                                    Apply Changes
                                </span>
                            )


                            : (
                                <>
                                    <span className="info">
                                        Continue
                                    </span>

                                    <span className="ico">
                                        <svg
                                            fill="none"
                                            height="14"
                                            viewBox="0 0 14 14"
                                            width="14"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M5.25 2.625L9.625 7L5.25 11.375"
                                                stroke="#02020A"
                                                strokeLinecap="square"
                                                strokeWidth="1.6"
                                            />
                                        </svg>
                                    </span>
                                </>
                            )


                    }
                </button>
            </div>

            {
                inviteInstructorModalOpen
                    ? (
                        <InviteInstructorModal
                            open={inviteInstructorModalOpen}
                            handleClose={() => setInviteInstructorModalOpen(false)}
                            onSubmit={handleAddInstructors}
                            data={instructors}
                        />
                    )


                    : null
            }
        </>
    );
};

export default SeminarInformation;
