import { Country } from "country-state-city";
import moment from "moment";
import {
    useEffect, useState
} from "react";
import {
    useDispatch
} from "react-redux";
import { ReactComponent as DoneIcon } from "../../../assets/img/done.svg";
import { ReactComponent as PencilIcon } from "../../../assets/img/ico-pencil.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/img/ico-trash.svg";
import DropDown from "../../../components/ui/DropDown";
import DatePicker from "../../../components/ui/formElements/DatePicker";
import TimePicker from "../../../components/ui/formElements/TimePicker";
import {
    convertDatesToTimeSlot, convertObjectToFormData, uuidv4
} from "../../../helpers/utils";
import { updateSeminarFetch } from "../../../redux/thunks/seminarsThunks";
import { convertDatesToFormData } from "../../planning/parts/EventDatesSelect";
import { CardDetails } from "./CardDetails";
import { Step } from "./Step";

const TimeSlot = ({
    field, seminarId, timeSlot, index, handleChangeTimeSlot, isErrorsShown, events, handleRemoveTimeSlot
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const dispatch = useDispatch();

    const handleCheck = async () => {
        setIsOpen(false);
        const body = new FormData();

        convertObjectToFormData({ [field]: convertDatesToFormData(events) }, body, false);
        const res = await dispatch(updateSeminarFetch({
            seminarId,
            body
        })).unwrap();
    };

    const [ddOpen, setddOpen] = useState(false)

    return (
        <div
            className="color-text"
            key={index}
        >
            {
                timeSlot?.startTime && timeSlot?.endTime && !isOpen
                    ? (
                        <div className="row-group gap--xs">
                            {`${moment(timeSlot.startTime).format("hh:mm")} - ${moment(timeSlot.endTime).format("hh:mm")}`}
                            <DropDown
                                dropDownListClasses="time-input-menu"
                                labelButton={<button
                                    className="fw-500 text-14 text-normal style-normal color-secondary"
                                    onClick={() => setddOpen(true)}
                                >
                                    <PencilIcon />
                                </button>}
                                position="left"

                            >
                                {ddOpen && (
                                    <div
                                        className="schedule-group-item__time-group"
                                        key={index}
                                    >
                                        <div className="time-input-box-wrapper">
                                            <TimePicker
                                                error={isErrorsShown && errors?.timeSlots?.length && errors?.timeSlots[index]?.startTime}
                                                onChange={(time) => handleChangeTimeSlot(index, "startTime", time)}
                                                placeholderText="0:00pm"
                                                selected={timeSlot?.startTime}
                                                wrapperClassName="w-88"

                                            />

                                            <span className="input-delimiter" />

                                            <TimePicker
                                                error={isErrorsShown && errors?.timeSlots?.length && errors?.timeSlots[index]?.endTime}
                                                onChange={(time) => handleChangeTimeSlot(index, "endTime", time)}
                                                placeholderText="0:00pm"
                                                selected={timeSlot?.endTime}
                                                wrapperClassName="w-88"
                                            />

                                            <button
                                                className="done-button"
                                                onClick={handleCheck}
                                            >
                                                <DoneIcon />
                                            </button>

                                            {
                    
                                                <button
                                                    type='button'
                                                    onClick={() => {
                                                        setddOpen(false)
                                                        handleRemoveTimeSlot(index)
                                                    }}
                                                    className="btn btn--outline-default btn--square btn--md rounded-full"
                                                >
                                                    <DeleteIcon />
                                                </button>
                                            }
                                        </div>
                                    </div>
                                )}
                            </DropDown>
                        </div>
                    )
                    : (
                        <DropDown
                            dropDownListClasses="time-input-menu"
                            labelButton={<button
                                className="fw-500 text-14 text-normal style-normal color-secondary"
                                onClick={() => setIsOpen(!isOpen)}
                            >
                                Set time
                            </button>}
                            position="left"
                        >
                            <div
                                className="schedule-group-item__time-group"
                                key={index}
                            >
                                <div className="time-input-box-wrapper">
                                    <TimePicker
                                        error={isErrorsShown && errors?.timeSlots?.length && errors?.timeSlots[index]?.startTime}
                                        onChange={(time) => handleChangeTimeSlot(index, "startTime", time)}
                                        placeholderText="0:00pm"
                                        selected={timeSlot?.startTime}
                                        wrapperClassName="w-88"

                                    />

                                    <span className="input-delimiter" />

                                    <TimePicker
                                        error={isErrorsShown && errors?.timeSlots?.length && errors?.timeSlots[index]?.endTime}
                                        onChange={(time) => handleChangeTimeSlot(index, "endTime", time)}
                                        placeholderText="0:00pm"
                                        selected={timeSlot?.endTime}
                                        wrapperClassName="w-88"
                                    />

                                    <button
                                        className="done-button"
                                        onClick={handleCheck}
                                    >
                                        <DoneIcon />
                                    </button>

                                    {/* {
                    index === 0 ?
                        <button type='button' onClick={handleAddTimeSlot} className="btn btn--outline-default btn--square btn--md rounded-full">
                            <PlusIcon />
                        </button>
                        :
                        <button type='button' onClick={() => handleRemoveTimeSlot(index)} className="btn btn--outline-default btn--square btn--md rounded-full">
                            <DeleteIcon />
                        </button>
                } */}
                                </div>
                            </div>
                        </DropDown>
                    )


            }
        </div>
    );
};

const EventDateTimeInput = ({
    field, seminarId, event, handleChange, excludeDates, children, eventId, errors, isErrorsShown, events
}) => {
    const [date, setDate] = useState(event?.date);
    const [timeSlots, setTimeSlots] = useState([...event.timeSlots]);

    const handleAddTimeSlot = () => {
        const timeSlot = {
            startTime: "",
            endTime: "",
            date
        };

        setTimeSlots((prev) => [...prev, timeSlot]);
    };

    const handleRemoveTimeSlot = (index) => {
        const newTimeSlots = timeSlots.filter((_, i) => index !== i);

        setTimeSlots(newTimeSlots);
        const data = {
            date,
            timeSlots: newTimeSlots
        };

        handleChange && handleChange(eventId, data);
    };

    const handleChangeTimeSlot = (index, key, value) => {
        const newEvents = timeSlots.map((timeSlot, i) => {
            if (i === index) {
                return {
                    ...timeSlot,
                    [key]: value
                };
            } else {
                return timeSlot;
            }
        });

        setTimeSlots(newEvents);
        const data = {
            ...event,
            date,
            timeSlots: newEvents
        };

        handleChange && handleChange(eventId, data);
    };

    const handleChangeDate = (date) => {
        const data = {
            ...event,
            date: date,
            timeSlots
        };

        setDate(date);
        handleChange && handleChange(eventId, data);
    };

    const [open, setOpen] = useState(false);

    return (
        <div className="flex justify-between">
            <p className="fw-500">
                {
                    date
                        ? moment(date).format("DD MMM, YYYY")
                        : (
                            <>
                                <button
                                    className="fw-500 text-14 color-secondary"
                                    onClick={() => setOpen(!open)}
                                >
                                    Set date
                                </button>

                                <DatePicker
                                    error={isErrorsShown && errors?.date}
                                    excludeDates={excludeDates}
                                    inputClassName="hidden-input"
                                    minDate={moment().toDate()}
                                    onChange={(date) => handleChangeDate(date)}
                                    open={open}
                                    placeholderText="Select Event Date"
                                    selected={date}
                                />
                            </>
                        )


                }
            </p>

            <div className="col-group gap--md">
                {timeSlots.map((timeSlot, index) => (
                    <TimeSlot
                        field={field}
                        seminarId={seminarId}
                        events={events}
                        timeSlot={timeSlot}
                        index={index}
                        handleChangeTimeSlot={handleChangeTimeSlot}
                        isErrorsShown={isErrorsShown}
                        handleRemoveTimeSlot={handleRemoveTimeSlot}
                    />
                )



                )}

                <button
                    className="fw-500 text-14 color-secondary"
                    onClick={handleAddTimeSlot}
                >
                    Add Time
                </button>
            </div>
        </div>
    );
};

export const StepLocationDate = ({
    seminarDetails, currentStatus, status, link, step, requestId 
}) => {
    const [open, setOpen] = useState(false);
    const [events, setEvents] = useState([]);
    const [errors, setErrors] = useState([]);

    const addEvent = () => {
        const newEvent = {
            date: "",
            id: uuidv4(),
            timeSlots: [
                {
                    startTime: "",
                    endTime: ""
                }
            ]
        };
        const updatedEvents = [...events, newEvent];

        setEvents(updatedEvents);
    };

    useEffect(() => {
        const events = convertDatesToTimeSlot(seminarDetails?.seminarDates);

        if (events.length >= 1) {
            setEvents(convertDatesToTimeSlot(seminarDetails?.seminarDates));
        } else {
            addEvent();
        }
    }, [seminarDetails]);

    const updateEventById = (eventId, newEvent) => {
        const updatedEvents = events.map((event, index) => {
            if (index === eventId) {
                return newEvent;
            }

            return event;
        });

        setEvents(updatedEvents);
    };

    const [privateEvents, setPrivateEvents] = useState([]);
    const [privateErrors, setPrivateErrors] = useState([]);

    const addPrivateEvent = () => {
        const newEvent = {
            date: "",
            id: uuidv4(),
            timeSlots: [
                {
                    startTime: "",
                    endTime: ""
                }
            ]
        };
        const updatedEvents = [...privateEvents, newEvent];

        setPrivateEvents(updatedEvents);
    };

    useEffect(() => {
        const events = convertDatesToTimeSlot(seminarDetails?.privateSessionDates);

        if (events.length >= 1) {
            setPrivateEvents(convertDatesToTimeSlot(seminarDetails?.privateSessionDates));
        } else {
            addPrivateEvent();
        }
    }, [seminarDetails]);

    const updatePrivateEventById = (eventId, newEvent) => {
        const updatedEvents = privateEvents.map((event, index) => {
            if (index === eventId) {
                return newEvent;
            }

            return event;
        });

        setPrivateEvents(updatedEvents);
    };

    return (
        <Step
            currentStatus={currentStatus}
            label="Location & Date"
            status={status}
            eventId={seminarDetails.id}
            eventType="seminar"
            step={step}
            requestId={requestId}
        >
            <CardDetails
                isEdited={seminarDetails?.lastEdited?.includes("address") || seminarDetails?.lastEdited?.includes("location")}
                label="Location"
            >
                <p className="color-text">
                    {[
                        seminarDetails?.location,
                        seminarDetails?.address,
                        seminarDetails?.continent,
                        Country.getCountryByCode(seminarDetails?.country)?.name || seminarDetails?.country,
                        seminarDetails?.city
                    ].filter(Boolean).join(", ") || "Empty"}
                </p>
            </CardDetails>

            <CardDetails
                isEdited={seminarDetails?.lastEdited?.includes("seminarDates")}
                label="Dates & Time"
            >
                {
                    events.map((event, index) => (
                        <EventDateTimeInput
                            key={index}
                            event={event}
                            eventId={index}
                            events={events}
                            // isErrorsShown={isErrorsShown}
                            field="availableDates"
                            seminarId={seminarDetails?.id}
                            handleChange={updateEventById}
                            // excludeDates={excludeDates}
                            errors={errors?.length
                                ? errors[index]
                                : {}}
                        >
                            {
                                events.length > 1
                                    ? (
                                        <button
                                            type="button"
                                            onClick={() => removeEvent(index)}
                                            className="btn btn--outline-default btn--square btn--md rounded-full"
                                        >
                                            {/* <DeleteIcon /> */}
                                        </button>
                                    )
                                    : null
                            }
                        </EventDateTimeInput>
                    )



                    )

                }
            </CardDetails>

            <CardDetails
                isEdited={seminarDetails?.lastEdited?.includes("privateSessionDates")}
                label="Private lessons Time"
            >
                {
                    privateEvents.map((event, index) => (
                        <EventDateTimeInput
                            event={event}
                            eventId={index}
                            events={privateEvents}
                            // isErrorsShown={isErrorsShown}
                            field="privateSessionDates"
                            seminarId={seminarDetails?.id}
                            handleChange={updatePrivateEventById}
                            // excludeDates={excludeDates}
                            errors={privateErrors?.length
                                ? privateErrors[index]
                                : {}}
                        >
                            {
                                privateEvents.length > 1
                                    ? (
                                        <button
                                            type="button"
                                            onClick={() => removeEvent(index)}
                                            className="btn btn--outline-default btn--square btn--md rounded-full"
                                        >
                                            {/* <DeleteIcon /> */}
                                        </button>
                                    )
                                    : null
                            }
                        </EventDateTimeInput>
                    )



                    )

                }
            </CardDetails>

        </Step>
    );
};
