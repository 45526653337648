import { useState } from "react";
import { SeminarTemplate } from "../parts/SeminarTemplate";
import { useSelector } from "react-redux";
import { Country } from "country-state-city";
import {
    belts, languages, trainingStyle
} from "../../../constants";
import { getAllSeminarsWithFiltersFetch } from "../../../redux/thunks/seminarsThunks";

export const SeminarMain = () => {
    const { seminars, pagination, isLoading } = useSelector((state) => state.seminars);
    const [filters] = useState({
        sort_by: "",
        sort_as: "desc",
        gender: "",
        location: "",
        price: "",
        trainingStyle: "",
        belt: "",
        language: "",
        search: "",
        page: "",
        limit: ""
    });
    const allCountries = Country.getAllCountries();
    const locationOptions = allCountries?.map((item) => ({
        value: item.isoCode,
        label: item.name
    })) || [];
    const priceOptions = [
        "$$$$$",
        "$$$$",
        "$$$",
        "$$",
        "$"
    ].map((item) => ({
        label: item,
        value: item
    }));
    const trainingStyleOptions = trainingStyle.map(({ title, key }) => ({
        label: title,
        value: title
    }));
    const beltOptions = belts.map(({ title, key }) => ({
        label: title,
        value: title
    }));
    const languageOptions = languages.map(({ title, key }) => ({
        label: title,
        value: title
    }));
    const sortOptions = [
        {
            label: "Date",
            value: "id"
        }, {
            label: "Price",
            value: "seminarMinPrice"
        }
    ];
    const filtersData = [
        {
            label: "Location",
            query: "location",
            options: locationOptions,
            all: true
        },
        {
            label: "Seminar Price",
            query: "price",
            options: priceOptions,
            all: true
        },
        {
            label: "Gi or No-Gi",
            query: "trainingStyle",
            options: trainingStyleOptions,
            all: true
        },
        {
            label: "Length / Days",
            query: "lengthDays",
            options: Array.from({ length: 10 }, (_, i) => ({
                label: i + 1,
                value: i + 1
            })),
            all: true
        },
        {
            label: "Belt Rank",
            query: "belt",
            options: beltOptions
        },
        {
            label: "Language",
            query: "language",
            options: languageOptions,
            all: true
        }
    ];

    return (
        <SeminarTemplate
            baseQuery="status=active"
            fethFuntion={getAllSeminarsWithFiltersFetch}
            filterLinks={[
                {
                    label: "Seminars",
                    link: "/seminars"
                }, {
                    label: "Camps",
                    link: "/camps"
                }
            ]}
            filters={filters}
            filtersData={filtersData}

            firsScreenOptions={{
                title: "SEMINARS / Camps",
                description: "Discover the seminars currently active on our platform",
                placeholder: "What seminar do you want to find?",
                imageBg: require("../../../assets/img/current-seminars.png")
            }}
            isLoading={isLoading}

            pagination={pagination}
            seminars={seminars}
            sotrBy={sortOptions}
        />
    );
};
