import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet, useLocation } from 'react-router-dom'
import { getBreadcrumbs, setBreadCrumbs } from '../redux/slices/applicationSlice'

const BreadcrumbsProvider = () => {
    const { pathname } = useLocation()
    const dispatch = useDispatch()

    const breadcrumbs = useSelector(getBreadcrumbs)

    useEffect(() => {
        if (pathname === breadcrumbs[breadcrumbs.length - 2]) {
            const updated = [...breadcrumbs]
            
            console.log(updated, "before");
            updated.splice(-1)
            console.log(updated, "after");
            
            dispatch(setBreadCrumbs(updated))

            return;
        }
        
        if (breadcrumbs[breadcrumbs.length - 1] !== pathname) {
            dispatch(setBreadCrumbs([...breadcrumbs, pathname]))
        }
    }, [pathname])

    // useEffect(() => {
    //     console.log(breadcrumbs, "breadcrumbs");
    // }, [breadcrumbs])

    return (
        <Outlet />
    )
}

export default BreadcrumbsProvider
