import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api"; // Adjust the import path as necessary
import { toast } from "react-toastify";
export const getAllChatsFetch = createAsyncThunk("/chats/getAllChatsFetch", async (payload = null, { rejectWithValue }) => {
    try {
        const res = await api.chats.getAllChats();

        if (res.success) {
            toast.success(res.message);

            return res;
        }
    } catch (err) {
        toast.error(err);

        return rejectWithValue(err?.response?.data);
    }
});
export const getChatMessagesFetch = createAsyncThunk("/chats/getChatMessagesFetch", async (payload, { rejectWithValue }) => {
    try {
        const res = await api.chats.getChatMessages(payload);

        if (res.success) {
            toast.success(res.message);

            return res;
        }
    } catch (err) {
        toast.error(err);

        return rejectWithValue(err?.response?.data);
    }
});
export const getChatByIdFetch = createAsyncThunk("/chats/getChatByIdFetch", async (payload, { rejectWithValue }) => {
    try {
        const res = await api.chats.getChatById(payload);

        if (res.success) {
            toast.success(res.message);

            return res;
        }
    } catch (err) {
        toast.error(err);

        return rejectWithValue(err?.response?.data);
    }
});
export const sendChatMessageFetch = createAsyncThunk("/chats/sendChatMessageFetch", async (payload, { rejectWithValue }) => {
    try {
        const res = await api.chats.sendChatMessage(payload);

        if (res.success) {
            return res;
        }
    } catch (err) {
        toast.error(err);

        return rejectWithValue(err?.response?.data);
    }
});
