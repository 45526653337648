import { getImageSrc } from "../../../helpers/utils";
import { Step } from "./Step";

const { CardDetails } = require("./CardDetails");

export const StepDesign = ({
    seminarDetails, currentStatus, status, step, requestId 
}) => {
    return (
        <Step
            currentStatus={currentStatus}
            label="Design & Text"
            status={status}
            eventId={seminarDetails.id}
            eventType="seminar"
            step={step}
            requestId={requestId}
        >
            {
                seminarDetails?.photos?.length
                    ? (
                        <div className="row gutters-form-7">
                            {
                                seminarDetails?.photos.map((photo) => (
                                    <div
                                        className="col-6"
                                        key={photo?.id}
                                    >
                                        <img
                                            className="w-full"
                                            src={getImageSrc(photo?.url)}
                                            style={{ height: 96 }}
                                            alt={photo?.originalname}
                                        />
                                    </div>
                                )



                                )
                            }
                        </div>
                    )


                    : null
            }

            <CardDetails
                isEdited={seminarDetails?.lastEdited?.includes("seminarName")}
                label="Seminar Name"
            >
                <p className="color-text">
                    {seminarDetails?.seminarName || "Empty"}
                </p>
            </CardDetails>

            <CardDetails
                isEdited={seminarDetails?.lastEdited?.includes("summary")}
                label="Summary"
            >
                <p className="color-text">
                    {seminarDetails?.summary || "Empty"}
                </p>
            </CardDetails>
        </Step >
    );
};
