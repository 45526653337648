import { format } from "date-fns";
import { Link } from "react-router-dom";
import Timer from "../../../components/parts/Timer";
import { getImageSrc } from "../../../helpers/utils";

const SeminarOverviewDetail = ({ pageData, eventType }) => {

    const getStartDateTime = () => {
        let dateList;
        if (eventType === "seminar") {
            dateList = pageData?.seminarDates.map(item => {
                const [hours, min] = (item.startTime || item.start).split(":")
                const timestamp = new Date(item.date).setHours(hours, min)
                return new Date(timestamp)
            })
        } else {
            dateList = [pageData.startAt, pageData.endAt]
        }
        if (!dateList?.length) return

        return dateList.reduce((latest, current) => (current < latest ? current : latest));
    }

    return (
        <div className="form-item">
            <div className="other-data-columns">
                {pageData?.status !== "pending" && (
                    <div className="other-data-max other-data-col">
                        <div className="ticker-deadline">
                            <div className="deadline-title">
                                <p className="color-text text-sm">
                                    Min. attendance:
                                </p>

                                <div className="amount-stud-block">
                                    {
                                        pageData?.ticketsPurchased >= pageData.minimumAttendance && (
                                            <span className="ico color-secondary">
                                                <svg
                                                    fill="none"
                                                    height="19"
                                                    viewBox="0 0 18 19"
                                                    width="18"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M9 2C4.8645 2 1.5 5.3645 1.5 9.5C1.5 13.6355 4.8645 17 9 17C13.1355 17 16.5 13.6355 16.5 9.5C16.5 5.3645 13.1355 2 9 2ZM9 15.5C5.69175 15.5 3 12.8082 3 9.5C3 6.19175 5.69175 3.5 9 3.5C12.3082 3.5 15 6.19175 15 9.5C15 12.8082 12.3082 15.5 9 15.5Z"
                                                        fill="currentColor"
                                                    />

                                                    <path
                                                        d="M7.50005 10.6902L5.7758 8.96898L4.7168 10.031L7.50155 12.8097L12.531 7.78023L11.4705 6.71973L7.50005 10.6902Z"
                                                        fill="currentColor"
                                                    />
                                                </svg>
                                            </span>
                                        )
                                    }

                                    <p className="text-sm">
                                        <span>{pageData?.minimumAttendance || 0}</span>

                                        {" "}

                                        students
                                    </p>
                                </div>
                            </div>

                            <Timer expiryTimestamp={getStartDateTime()} />

                            <div className="deadline-footer">
                                <p className="text-sm">
                                    Min. attendance deadline
                                    {" "}
                                    {pageData?.minAttendanceDeadline || format(getStartDateTime(), "d MMM, yyyy")}
                                </p>
                            </div>
                        </div>
                    </div>
                )}

                <div className="other-data-full other-data-col">
                    <div className="other-data--columns">
                        {(pageData?.status !== "pending" || pageData?.user?.role === "academy") && (
                            <div className="other-data--col">
                                <h6 className="heading">
                                    Academy:
                                </h6>

                                <div className="seminar-row">
                                    <div className="ico ico--xs">
                                        <img
                                            alt=""
                                            src={pageData?.userAcademy?.academyProfile?.photo
                                                ? getImageSrc(pageData?.userAcademy?.academyProfile?.photo)
                                                : require("../../../assets/img/avatar-placeholder.svg").default}
                                        />
                                    </div>

                                    <div className="seminar-row--content">
                                        <p className="seminar-title text-sm">
                                            {pageData?.userAcademy?.academyProfile?.name}
                                        </p>

                                        <p className="seminar-desk text-sm color-text">
                                            {pageData?.userAcademy?.generalAcademyInfo?.addressLine}
                                        </p>

                                        <div className="deadline-title">
                                            <p className="color-text text-sm">
                                                Max. capacity:
                                            </p>
                                            <div className="amount-stud-block">
                                                <p className="text-sm">
                                                    <span>
                                                        {pageData?.maxGymCapacity}
                                                    </span>
                                                    {" "}
                                                    students
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {(pageData?.status !== "pending" || pageData?.user?.role === "instructor") && (
                            <div className="other-data--col">
                                <h6 className="heading">
                                    Instructors:
                                </h6>
                                <ul className="row-list">
                                    {
                                        pageData?.instructors?.map(item => {
                                            const instructor = item?.user;
                                            const { instructorProfile } = instructor

                                            return (
                                                <li key={item.id}>
                                                    <Link
                                                        to={`/instructors/${instructor.id}`}
                                                        className="card-user-block card-user-block--sm"
                                                    >
                                                        <div className="card-user-block__icon">
                                                            <img src={getImageSrc(instructorProfile?.photo)} alt={instructorProfile?.fullName} />
                                                        </div>
                                                        <div className="card-user-block__name">{instructorProfile?.fullName}</div>
                                                    </Link>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SeminarOverviewDetail;
