const base = "api/payout-methods";

export const payoutMethods = (instance) => ({
    async getMyPayoutMethods(payload) {
        const { data } = await instance.get(`${base}`);

        return data;
    },
    async createStripeAccountConnectionLink(paymentMethodId) {
        const { data } = await instance.get(`${base}/stripe/create-account/${paymentMethodId}`);

        return data;
    },
    async generateStripeAccountLink(paymentMethodId) {
        const { data } = await instance.get(`${base}/stripe/login-link/${paymentMethodId}`);

        return data;
    },
    async addPayoutMethod(payload) {
        const { data } = await instance.post(`${base}`, payload);

        return data;
    },
});
