
import {
    useCallback, useState
} from "react";
import { getImageSrc } from "../../../helpers/utils";
import { ReactComponent as GridsIcon } from "../../../assets/img/ico-grids.svg";
import SelectTemplateModal from "../../../components/modals/SelectTemplateModal";

const SeminarPhotosUpload = ({
    images = [], setImages, setCoverImage, coverImage, title = "Seminar Photo(S)", error, onDelete
}) => {
    const [coverImageIndex, setCoverImageIndex] = useState(null);
    const [selectTemplateModalOpen, setSelectTemplateModalOpen] = useState(false);

    const handleUpload = (e) => {
        const files = [...e.target.files];

        if (!files?.length) return;

        setImages([...images, ...files]);

        if (!coverImage) {
            setCoverImageIndex(0);
            setCoverImage(files[0]);
        }
    };

    const handleRemove = useCallback((e, index) => {
        e.stopPropagation();
        const updatedImages = [...images];

        if (onDelete && updatedImages[index].id) {
            onDelete(updatedImages[index].id);
        }

        updatedImages.splice(index, 1);
        setImages(updatedImages);

        if (index === coverImageIndex) {
            setCoverImageIndex(null);
            setCoverImage(null);
        }
    }, [images, coverImageIndex, setImages, setCoverImage]);

    const handleSelect = (index) => {
        setCoverImageIndex(index);
        setCoverImage(images[index]);
    };

    return (
        <>
            <div className="flex items-center flex-wrap justify-between">
                <h6 className="heading mb-32">
                    {title}
                </h6>

                <button
                    className="btn mb-32 gap--md color-secondary"
                    onClick={() => setSelectTemplateModalOpen(true)}
                >
                    <span className="ico">
                        <GridsIcon />
                    </span>

                    <span>
                        Cover Templates
                    </span>
                </button>
            </div>

            <div className="row g-8 mb-42">
                {images.map((image, index) => (
                    <div
                        key={index}
                        className="col-md-3 col-sm-4 col-6"
                    >
                        <div
                            className="seminar-gallery-item"
                            onClick={() => handleSelect(index)}
                            style={{
                                background:
                                    `linear-gradient(0deg, rgba(0, 0, 0, .8) 0%, rgba(0, 0, 0, 0) 45%, rgba(0, 0, 0, 0) 45%, rgba(0, 0, 0, 0) 100%),
                                    url(${image?.url
                        ? getImageSrc(image.url)
                        : getImageSrc(image)}) center/cover no-repeat`,
                                cursor: "pointer"
                            }}
                        >
                            <div className="seminar-gallery-item__actions">
                                <button
                                    onClick={(e) => handleRemove(e, index)}
                                    className="btn btn--secondary-2 btn--square btn--xxs rounded-full"
                                >
                                    <span className="ico">
                                        <svg
                                            width="14"
                                            height="14"
                                            viewBox="0 0 14 14"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M9.41348 12.25H4.58348C3.97273 12.25 3.46523 11.7787 3.41973 11.1691L2.89648 4.08333H11.083L10.5772 11.1662C10.5335 11.7769 10.0254 12.25 9.41348 12.25V12.25Z"
                                                stroke="currentColor"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />

                                            <path
                                                d="M7.00065 6.41667V9.91667"
                                                stroke="currentColor"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />

                                            <path
                                                d="M2.33398 4.08334H11.6673"
                                                stroke="currentColor"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />

                                            <path
                                                d="M9.91732 4.08333L9.3264 2.50717C9.15548 2.05158 8.72032 1.75 8.23382 1.75H5.76748C5.28098 1.75 4.84582 2.05158 4.6749 2.50717L4.08398 4.08333"
                                                stroke="currentColor"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />

                                            <path
                                                d="M9.00107 6.41667L8.75023 9.91667"
                                                stroke="currentColor"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />

                                            <path
                                                d="M4.99828 6.41667L5.24911 9.91667"
                                                stroke="currentColor"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                    </span>
                                </button>
                            </div>

                            <div className="seminar-gallery-item__title">
                                {coverImageIndex === index
                                    ? (
                                        <span className="color-primary">
                                            Seminar Cover
                                        </span>
                                    )
                                    : "Set as Cover"}
                            </div>
                        </div>
                    </div>
                )



                )}

                <div className="col-lg-3">
                    <label
                        className={`btn add-photo-btn ${error
                            ? "input--error"
                            : ""}`}
                        style={{ minHeight: 120 }}
                    >
                        <input
                            accept={"image/*"}
                            className="hidden"
                            hidden
                            multiple
                            onChange={handleUpload}
                            type="file"
                        />

                        <span className="ico">
                            <svg
                                fill="none"
                                height="28"
                                viewBox="0 0 28 28"
                                width="28"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M14.0013 9.33333V18.6667"
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="1.2"
                                />

                                <path
                                    d="M18.6654 14H9.33203"
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="1.2"
                                />

                                <path
                                    clipRule="evenodd"
                                    d="M14 24.5V24.5C8.2005 24.5 3.5 19.7995 3.5 14V14C3.5 8.2005 8.2005 3.5 14 3.5V3.5C19.7995 3.5 24.5 8.2005 24.5 14V14C24.5 19.7995 19.7995 24.5 14 24.5Z"
                                    fillRule="evenodd"
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="1.2"
                                />
                            </svg>
                        </span>

                        <span>
                            Add Photo
                        </span>
                    </label>
                </div>
            </div>

            {error && (
                <p className="error-input">
                    {error}
                </p>
            )}

            {
                selectTemplateModalOpen
                    ? (
                        <SelectTemplateModal
                            open={selectTemplateModalOpen}
                            handleClose={() => setSelectTemplateModalOpen(false)}
                        />
                    )


                    : null
            }
        </>
    );
};

export default SeminarPhotosUpload;
