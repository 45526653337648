import Button from "../../../components/ui/buttons/Button";
import {
    getDateRange, getTeachStyle, getTrainingStyle
} from "../../../helpers/utils";

import { useState } from "react";
import { ReactComponent as DotsIcon } from "../../../assets/img/icon-dots-horisontal.svg";
import CancelEventModal from "../../../components/modals/CancelEventModal";
import DropDown from "../../../components/ui/DropDown";
import { useSelector } from "react-redux";
import { getUser } from "../../../redux/slices/applicationSlice";

const SeminarOverviewHeader = ({ pageData }) => {
    const [cancelEventModalOpen, setCancelEventModalOpen] = useState(false)
    const user = useSelector(getUser)
    if (!pageData) {
        return null
    }

    const trainingStyle = getTrainingStyle(pageData?.traningStyle);

    
    return (
        <div className="sidebar-headers">
            <div className="sidebar-header heading-row">
                <h3 className="heading">
                    {pageData?.seminarName}
                </h3>

                <div className="row-group gap--md">
                    {/* <Button
                        className="btn btn--primary btn--md radius"
                    >
                        <span>
                            Edit Seminar
                        </span>
                    </Button> */}
                    {
                        user?.id === pageData?.user?.id && pageData.status === "active" && (
                            <DropDown
                                dropDownClasses="ml-auto card-actions"
                                dropDownListClasses="dropdown-menu"
                                labelButton={(
                                    <Button
                                        className="btn btn--square btn--md rounded-full btn-border"
                                    >
                                        <DotsIcon />
                                    </Button>
                                )}
                            >
                                <div className="dropdown-menu__body--item">
                                    <Button
                                        className="btn btn--sm btn--thin active"
                                        onClick={() => setCancelEventModalOpen(true)}
                                    >
                                        Cancel Event
                                    </Button>
                                </div>
                            </DropDown>
                        )
                    }

                </div>
            </div>

            <div className="sidebar-header heading-row">
                <div className="options">
                    {pageData?.status === "pending"
                        ? (
                            <>
                                {(pageData?.priceInstructorMin || pageData?.seminarMinPrice) && (
                                    <div className="option">
                                        <div className="price-group">
                                            <span className="color-grey mr-8 text-sm">
                                                from

                                                {" "}
                                            </span>

                                            <span className="price-group__price">
                                                {pageData?.user?.role === "academy"
                                                    ? pageData?.priceInstructorMin
                                                    : pageData?.seminarMinPrice}
                                            </span>
                                        </div>
                                    </div>
                                )}

                                <div className="option">
                                    <p className="text-sm font-500">
                                        Planned Seminar
                                    </p>
                                </div>
                            </>
                        )


                        : (
                            <div className="option">
                                <div className="price-group">
                                    <span className="price-group__price">
                                        ${pageData.minimalPriceTicket}

                                        {" "}
                                    </span>

                                    <span className="color-grey mr-8 text-sm">
                                        / ticket
                                    </span>

                                    <span className="price-group__amount">
                                        {pageData?.maxGymCapacity - pageData?.ticketsPurchased} left
                                    </span>
                                </div>
                            </div>
                        )}

                    <div className="option">
                        <span className="card-seminar__gi">
                            <span className="ico">
                                {trainingStyle[0]?.icon}
                            </span>

                            <span>
                                {trainingStyle[0]?.title}
                            </span>
                        </span>

                        <ul className="list-point-row">
                            {pageData?.seminarDates && (
                                <li>
                                    <p className="text-sm">
                                        {getDateRange(pageData?.seminarDates)}
                                    </p>
                                </li>
                            )}

                            {pageData?.teachingStyles && (
                                <li>
                                    <p className="text-sm">
                                        {getTeachStyle(pageData?.teachingStyles).join(", ")}
                                    </p>
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
            </div>
            {cancelEventModalOpen && (
                <CancelEventModal
                    open={cancelEventModalOpen}
                    handleClose={() => setCancelEventModalOpen(false)}
                    data={pageData}
                />
            )}
        </div>
    );
};

export default SeminarOverviewHeader;
