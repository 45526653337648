import classNames from "classnames";
import {
    useEffect, useState
} from "react";
import {
    useDispatch, useSelector
} from "react-redux";
import {
    Link, NavLink, useLocation, useNavigate, useParams, useSearchParams
} from "react-router-dom";
import api from "../../api";
import LogoDark from "../../assets/img/logo-black.svg";
import Logo from "../../assets/img/logo.svg";
import { getImageSrc } from "../../helpers/utils";
import {
    getUser, setAuthModalOpen, setLogOut
} from "../../redux/slices/applicationSlice";
import PlanningModal from "../modals/PlanningModal";
import AuthProvider, { AuthModals } from "../modals/singUpModals/AuthProvider";
import Button from "../ui/buttons/Button";
import DropDown from "../ui/DropDown";
import SelectOne from "../ui/formElements/SelectOne";
import { UserPart } from "./UserPart";
import LanguageSelect from "../parts/LanguageSelect";

const HeaderDefault = ({ className }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [params, setSearchParams] = useSearchParams();
    const { pathname } = useLocation();
    const isLoginParams = params.get("is-login");
    const user = useSelector(getUser);
    const role = user?.role || "";
    const { restoreToken } = useParams();

    const [scrolled, setScrolled] = useState(false);
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [planningModalOpen, setPlanningModalOpen] = useState(false);
    const [isSportsSidebarOpen, setIsSportsSidebarOpen] = useState(false)

    useEffect(() => {
        if (isLoginParams) {
            handelLoginModal();
        }
    }, []);
    useEffect(() => {
        window.addEventListener("scroll", scrollHandler);

        if (restoreToken) {
            setRestorePassModal(true);
        }

        return () => {
            window.removeEventListener("scroll", scrollHandler);
        };
    }, []);

    const scrollHandler = function (e) {
        if (this.scrollY > 50) {
            setScrolled(true);
        } else {
            setScrolled(false);
        }
    };

    const handelLoginModal = () => {
        setSearchParams("");
        dispatch(setAuthModalOpen(AuthModals.login));
    };

    const handelSingUp = () => {
        dispatch(setAuthModalOpen(AuthModals.singUp));
    };

    const menuHandler = () => {
        setMenuIsOpen((prev) => !prev);
    };

    const selectHandler = (value) => {
        setSelectLang(value);
    };

    const handleLogout = async () => {
        dispatch(setLogOut());
        await api.auth.logout();
        navigate("/");
        window.location.reload();
    };

    useEffect(() => {
        setMenuIsOpen(false);
    }, [pathname]);
    const userIs = {
        instructor: user?.role === "instructor",
        academy: user?.role === "academy",
        student: user?.role === "student"
    };
    const canCreateSeminar = userIs.academy || userIs.instructor;
    return (
        <>
            <header
                className={classNames("header", className, {
                    scrolled: scrolled,
                    "menu-is-open": menuIsOpen
                })}
            >
                <div className="container">
                    <div className="header__body">
                        <div className="row-group gap--xl">
                            <button className="btn sport-sidebar-open" onClick={() => setIsSportsSidebarOpen(true)}>
                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_7290_250466)">
                                        <path d="M3.4375 11H18.5625" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M3.4375 5.5H18.5625" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M3.4375 16.5H18.5625" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_7290_250466">
                                            <rect width="22" height="22" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </button>
                            <Link
                                className="header__logo"
                                to="/"
                            >
                                <img
                                    alt="logo"
                                    className="logo-white"
                                    src={Logo}
                                />

                                <img
                                    alt="logo"
                                    className="logo-black"
                                    src={LogoDark}
                                />
                            </Link>
                        </div>

                        <nav className={classNames("header__menu", { active: menuIsOpen })} >
                            <ul className="header__list">
                                <li>
                                    <NavLink
                                        className="header__link"
                                        to="/seminars"
                                    >
                                        Seminars / Camps
                                    </NavLink>
                                </li>

                                {(user?.role === "academy" || user?.role === "instructor") && <AcademyDropDown />}

                                <li>
                                    <NavLink
                                        className="header__link"
                                        to="/instructors"
                                    >
                                        Instructors
                                    </NavLink>
                                </li>

                                <li>
                                    <NavLink
                                        className="header__link "
                                        to="/academies"
                                    >
                                        Academies
                                    </NavLink>
                                </li>

                                <li>
                                    <NavLink
                                        className="header__link"
                                        to="/about-us"
                                    >
                                        About Us
                                    </NavLink>
                                </li>
                            </ul>

                            {
                                canCreateSeminar && (
                                    <div className="header__actions--mobile">
                                        <button
                                            className="btn btn--md btn--primary justify-between w-full"
                                            onClick={() => {
                                                setPlanningModalOpen(true);
                                            }}
                                        >
                                            <span>
                                                Plan Seminar / Camp
                                            </span>

                                            <span className="ico">
                                                <svg
                                                    fill="none"
                                                    height="14"
                                                    viewBox="0 0 14 14"
                                                    width="14"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M5.25 2.625L9.625 7L5.25 11.375"
                                                        stroke="#02020A"
                                                        strokeLinecap="square"
                                                        strokeWidth="1.6"
                                                    />
                                                </svg>
                                            </span>
                                        </button>
                                    </div>
                                )}

                            {
                                user && role && (
                                    <div className="header__actions--mobile gap--xs">
                                        <div className="row-group w-full px-36">
                                            <div className="col-group flex-auto">
                                                <h6 className="heading">
                                                    {user?.profile?.fullName || user?.profile?.name || "User"}
                                                </h6>

                                                <p className="desc-heading">
                                                    {user?.email || "your@email.com"}
                                                </p>
                                            </div>

                                            <DropDown
                                                buttonClasses="ico"
                                                closeYourSelf={true}
                                                dropDownClasses=""
                                                dropDownListClasses="dropdown-menu"
                                                labelButton={<svg
                                                    fill="none"
                                                    height="42"
                                                    viewBox="0 0 42 42"
                                                    width="42"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M23.546 18.4543C24.9519 19.8602 24.9519 22.1396 23.546 23.5455C22.1401 24.9514 19.8607 24.9514 18.4548 23.5455C17.0489 22.1396 17.0489 19.8602 18.4548 18.4543C19.8607 17.0484 22.1401 17.0484 23.546 18.4543"
                                                        stroke="#02020A"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="1.2"
                                                    />

                                                    <path
                                                        clipRule="evenodd"
                                                        d="M12.0005 21C12.0005 21.396 12.0365 21.792 12.0845 22.176L9.96714 23.832C9.49781 24.2 9.36981 24.8573 9.66848 25.3733L11.5511 28.6307C11.8485 29.1467 12.4805 29.364 13.0338 29.1427L14.9298 28.3813C15.3045 28.2307 15.7218 28.2907 16.0578 28.5133C16.3511 28.708 16.6551 28.8867 16.9698 29.0467C17.3298 29.2293 17.5911 29.556 17.6485 29.956L17.9378 31.9733C18.0218 32.5627 18.5271 33 19.1218 33H22.8778C23.4725 33 23.9778 32.5627 24.0618 31.9733L24.3511 29.9573C24.4085 29.5573 24.6725 29.228 25.0338 29.0467C25.3471 28.8893 25.6498 28.712 25.9418 28.5187C26.2805 28.2947 26.6991 28.2307 27.0751 28.3827L28.9671 29.1427C29.5191 29.364 30.1511 29.1467 30.4498 28.6307L32.3325 25.3733C32.6311 24.8573 32.5031 24.1987 32.0338 23.832L29.9165 22.176C29.9645 21.792 30.0005 21.396 30.0005 21C30.0005 20.604 29.9645 20.208 29.9165 19.824L32.0338 18.168C32.5031 17.8 32.6311 17.1427 32.3325 16.6267L30.4498 13.3693C30.1525 12.8533 29.5205 12.636 28.9671 12.8573L27.0751 13.6173C26.6991 13.768 26.2805 13.7053 25.9418 13.4813C25.6498 13.288 25.3471 13.1107 25.0338 12.9533C24.6725 12.772 24.4085 12.4427 24.3511 12.0427L24.0631 10.0267C23.9791 9.43733 23.4738 9 22.8791 9H19.1231C18.5285 9 18.0231 9.43733 17.9391 10.0267L17.6485 12.0453C17.5911 12.444 17.3285 12.772 16.9698 12.9547C16.6551 13.1147 16.3511 13.2947 16.0578 13.488C15.7205 13.7093 15.3031 13.7693 14.9285 13.6187L13.0338 12.8573C12.4805 12.636 11.8485 12.8533 11.5511 13.3693L9.66848 16.6267C9.36981 17.1427 9.49781 17.8013 9.96714 18.168L12.0845 19.824C12.0365 20.208 12.0005 20.604 12.0005 21V21Z"
                                                        fillRule="evenodd"
                                                        stroke="#02020A"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="1.2"
                                                    />
                                                </svg>}
                                                position="top"
                                            >
                                                <div className="dropdown-menu__header">
                                                    <Link to="/">
                                                        <h6 className="heading">
                                                            {user?.profile?.fullName || user?.profile?.name || "User"}
                                                        </h6>
                                                    </Link>

                                                    <p className="desc-heading">
                                                        {user?.email || "your@email.com"}
                                                    </p>
                                                </div>

                                                <div className="dropdown-menu__body">
                                                    {role !== "student" && (
                                                        <div className="dropdown-menu__body--item">
                                                            <button
                                                                className="btn btn--md btn--primary justify-between"
                                                                onClick={() => {
                                                                    setPlanningModalOpen(true);
                                                                }}
                                                            >
                                                                <span>
                                                                    Plan Seminar / Camp
                                                                </span>

                                                                <span className="ico">
                                                                    <svg
                                                                        fill="none"
                                                                        height="14"
                                                                        viewBox="0 0 14 14"
                                                                        width="14"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                        <path
                                                                            d="M5.25 2.625L9.625 7L5.25 11.375"
                                                                            stroke="#02020A"
                                                                            strokeLinecap="square"
                                                                            strokeWidth="1.6"
                                                                        />
                                                                    </svg>
                                                                </span>
                                                            </button>
                                                        </div>
                                                    )}

                                                    <div className="dropdown-menu__body--item">
                                                        <NavLink
                                                            className="btn btn--md btn--thin"
                                                            to="/my-seminars"
                                                        >
                                                            <span className="ico">
                                                                <svg
                                                                    fill="none"
                                                                    height="22"
                                                                    viewBox="0 0 22 22"
                                                                    width="22"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path
                                                                        clipRule="evenodd"
                                                                        d="M2.75 2.75H9.16667V9.16667H2.75V2.75Z"
                                                                        fillRule="evenodd"
                                                                        stroke="currentColor"
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                        strokeWidth="1.2"
                                                                    />

                                                                    <path
                                                                        clipRule="evenodd"
                                                                        d="M12.8334 2.75H19.25V9.16667H12.8334V2.75Z"
                                                                        fillRule="evenodd"
                                                                        stroke="currentColor"
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                        strokeWidth="1.2"
                                                                    />

                                                                    <path
                                                                        clipRule="evenodd"
                                                                        d="M2.75 12.833H9.16667V19.2497H2.75V12.833Z"
                                                                        fillRule="evenodd"
                                                                        stroke="currentColor"
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                        strokeWidth="1.2"
                                                                    />

                                                                    <path
                                                                        clipRule="evenodd"
                                                                        d="M12.8334 12.833H19.25V19.2497H12.8334V12.833Z"
                                                                        fillRule="evenodd"
                                                                        stroke="currentColor"
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                        strokeWidth="1.2"
                                                                    />
                                                                </svg>
                                                            </span>

                                                            <span>
                                                                {"My Seminars "}

                                                                &amp;
                                                                {" Camps"}
                                                            </span>
                                                        </NavLink>

                                                        <NavLink
                                                            className="btn btn--md btn--thin"
                                                            to="/settings/my-profile"
                                                        >
                                                            <span className="ico">
                                                                <svg
                                                                    fill="none"
                                                                    height="22"
                                                                    viewBox="0 0 22 22"
                                                                    width="22"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path
                                                                        d="M12.7501 9.2503C13.7167 10.2169 13.7167 11.7839 12.7501 12.7505C11.7836 13.717 10.2165 13.717 9.24994 12.7505C8.28339 11.7839 8.28339 10.2169 9.24994 9.2503C10.2165 8.28375 11.7836 8.28375 12.7501 9.2503"
                                                                        stroke="currentColor"
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                        strokeWidth="1.2"
                                                                    />

                                                                    <path
                                                                        clipRule="evenodd"
                                                                        d="M4.81246 11C4.81246 11.2723 4.83721 11.5445 4.87021 11.8085L3.41455 12.947C3.09188 13.2 3.00388 13.6519 3.20921 14.0067L4.50355 16.2461C4.70796 16.6008 5.14246 16.7503 5.52288 16.5981L6.82638 16.0747C7.08396 15.9711 7.37088 16.0123 7.60188 16.1654C7.80355 16.2993 8.01255 16.4221 8.22888 16.5321C8.47638 16.6577 8.65605 16.8823 8.69546 17.1573L8.89438 18.5442C8.95213 18.9493 9.29955 19.25 9.70838 19.25H12.2906C12.6995 19.25 13.0469 18.9493 13.1046 18.5442L13.3035 17.1582C13.343 16.8832 13.5245 16.6568 13.7729 16.5321C13.9883 16.4239 14.1964 16.302 14.3971 16.1691C14.63 16.0151 14.9178 15.9711 15.1763 16.0756L16.477 16.5981C16.8565 16.7503 17.291 16.6008 17.4964 16.2461L18.7907 14.0067C18.996 13.6519 18.908 13.1991 18.5854 12.947L17.1297 11.8085C17.1627 11.5445 17.1875 11.2723 17.1875 11C17.1875 10.7278 17.1627 10.4555 17.1297 10.1915L18.5854 9.053C18.908 8.8 18.996 8.34808 18.7907 7.99333L17.4964 5.75392C17.292 5.39917 16.8575 5.24975 16.477 5.40192L15.1763 5.92442C14.9178 6.028 14.63 5.98492 14.3971 5.83092C14.1964 5.698 13.9883 5.57608 13.7729 5.46792C13.5245 5.34325 13.343 5.11683 13.3035 4.84183L13.1055 3.45583C13.0478 3.05067 12.7004 2.75 12.2915 2.75H9.7093C9.30046 2.75 8.95305 3.05067 8.8953 3.45583L8.69546 4.84367C8.65605 5.11775 8.47546 5.34325 8.22888 5.46883C8.01255 5.57883 7.80355 5.70258 7.60188 5.8355C7.36996 5.98767 7.08305 6.02892 6.82546 5.92533L5.52288 5.40192C5.14246 5.24975 4.70796 5.39917 4.50355 5.75392L3.20921 7.99333C3.00388 8.34808 3.09188 8.80092 3.41455 9.053L4.87021 10.1915C4.83721 10.4555 4.81246 10.7278 4.81246 11V11Z"
                                                                        fillRule="evenodd"
                                                                        stroke="currentColor"
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                        strokeWidth="1.2"
                                                                    />
                                                                </svg>
                                                            </span>

                                                            <span>
                                                                Account Settings
                                                            </span>
                                                        </NavLink>
                                                    </div>
                                                </div>

                                                <div className="dropdown-menu__footer">
                                                    <a
                                                        className="btn btn--md btn--thin"
                                                        href="#"
                                                        onClick={handleLogout}
                                                    >
                                                        <span className="ico">
                                                            <svg
                                                                fill="none"
                                                                height="22"
                                                                viewBox="0 0 22 22"
                                                                width="22"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    d="M11.9167 11.0003H2.75"
                                                                    stroke="currentColor"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    strokeWidth="1.2"
                                                                />

                                                                <path
                                                                    d="M5.95833 7.79199L2.75 11.0003L5.95833 14.2087"
                                                                    stroke="currentColor"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    strokeWidth="1.2"
                                                                />

                                                                <path
                                                                    d="M11 2.75C15.5563 2.75 19.25 6.44365 19.25 11C19.25 15.5563 15.5563 19.25 11 19.25"
                                                                    stroke="currentColor"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    strokeWidth="1.2"
                                                                />
                                                            </svg>
                                                        </span>

                                                        <span>
                                                            Log Out
                                                        </span>
                                                    </a>
                                                </div>
                                            </DropDown>
                                        </div>
                                    </div>
                                )}

                            <div className="header__actions--mobile gap--xs">
                                {
                                    !user
                                        ? (
                                            <div className="col-group w-full gap--xs">
                                                <Button
                                                    className="btn--primary btn--md"
                                                    onClick={handelSingUp}
                                                >
                                                    Sign Up
                                                </Button>

                                                <Button
                                                    className="btn--cancel btn--md"
                                                    onClick={() => setLoginModal(true)}
                                                >
                                                    Login
                                                </Button>

                                                <LanguageSelect />
                                            </div>
                                        )


                                        : (
                                            <>

                                                <LanguageSelect />

                                                <Button
                                                    className="btn--cancel btn--md flex-auto"
                                                    onClick={handleLogout}
                                                >
                                                    Logout
                                                </Button>
                                            </>
                                        )


                                }
                            </div>
                        </nav>

                        <div className="header__actions">
                            <UserPart
                                handelLoginModal={handelLoginModal}
                                handelSingUp={handelSingUp}
                                handleLogout={handleLogout}
                                user={user}
                            />

                            <LanguageSelect />

                            {user
                                ? (
                                    <div
                                        className={classNames("header__burger-avatar", { active: menuIsOpen })}
                                        onClick={menuHandler}
                                    >
                                        <img
                                            alt="avatar"
                                            className="ico ico--md"
                                            src={getImageSrc(user?.profile?.photo, require("../../assets/img/avatar-placeholder.svg").default)}
                                        />
                                    </div>
                                )


                                : (
                                    <div
                                        className={classNames("header__burger", { active: menuIsOpen })}
                                        onClick={menuHandler}
                                    >
                                        <span />
                                    </div>
                                )}
                        </div>
                    </div>
                </div>
            </header>

            <AuthProvider />

            {
                planningModalOpen && (
                    <PlanningModal
                        handleClose={() => setPlanningModalOpen(false)}
                        open={planningModalOpen}
                    />
                )}

            <SportsSidebar isOpen={isSportsSidebarOpen} onClose={() => setIsSportsSidebarOpen(false)} />
        </>
    );
};

export default HeaderDefault;

function AcademyDropDown() {
    return (
        <li className="flex justify-lg-center">
            <DropDown
                buttonClasses="fix-for-header"
                closeYourSelf={true}
                dropDownClasses="lh-16"
                dropDownListClasses="dropdown-menu header__dropdown-menu dropdown-event-type"
                labelButton={

                    (<li>
                        <button
                            className="header__link header__dropdown-button"
                            onClick={(e) => e.preventDefault()}
                        >
                            Match & Apply

                            <span className="ico ico-20">
                                <svg
                                    fill="none"
                                    height="12"
                                    viewBox="0 0 12 12"
                                    width="12"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M1.9033 7.64344C1.93167 7.71196 1.97972 7.77054 2.04138 7.81176C2.10304 7.85298 2.17554 7.87498 2.2497 7.875L9.7497 7.875C9.82392 7.87506 9.89648 7.8531 9.9582 7.81189C10.0199 7.77069 10.068 7.71211 10.0964 7.64355C10.1248 7.57499 10.1323 7.49954 10.1178 7.42676C10.1033 7.35398 10.0675 7.28713 10.015 7.23469L6.26502 3.48469C6.23019 3.44982 6.18883 3.42216 6.14331 3.40329C6.09778 3.38442 6.04899 3.3747 5.9997 3.3747C5.95042 3.3747 5.90163 3.38442 5.8561 3.40329C5.81058 3.42216 5.76922 3.44982 5.73439 3.48469L1.98439 7.23469C1.93196 7.28716 1.89628 7.354 1.88184 7.42676C1.86741 7.49952 1.87487 7.57492 1.9033 7.64344Z"
                                        fill="currentColor"
                                    />
                                </svg>
                            </span>
                        </button>
                    </li>)

                }
            >
                <NavLink
                    className="btn btn--sm w-full"
                    to="/planed-by-insctructor"
                >
                    Seeking Academy
                </NavLink>

                <NavLink
                    className="btn btn--sm w-full"
                    to="/planed-by-academy"
                >
                    Seeking Instructor
                </NavLink>
            </DropDown>
        </li>
    );
}

function SportsSidebar({ isOpen, onClose }) {
    const [selected, setSelected] = useState(window.localStorage.getItem("sport"))
    const sports = [
        {
            icon: require("../../assets/img/jj-icon.svg"),
            label: "Jiu Jitsu"
        },
        {
            icon: require("../../assets/img/wrestling-icon.svg"),
            label: "Wrestling"
        },
        {
            icon: require("../../assets/img/judo-icon.svg"),
            label: "Judo"
        },
        {
            icon: require("../../assets/img/karate-icon.svg"),
            label: "Karate"
        },
        {
            icon: require("../../assets/img/mma-icon.svg"),
            label: "MMA"
        },
        {
            icon: require("../../assets/img/muai-thai-icon.svg"),
            label: "Muai Thai"
        },
        {
            icon: require("../../assets/img/hockey-icon.svg"),
            label: "Hockey"
        },
        {
            icon: require("../../assets/img/racing-icon.svg"),
            label: "Racing"
        },
        {
            icon: require("../../assets/img/track-and-field-icon.svg"),
            label: "Track and Field"
        },
        {
            icon: require("../../assets/img/baseball-icon.svg"),
            label: "Baseball"
        },
        {
            icon: require("../../assets/img/basketball-icon.svg"),
            label: "Basketball"
        },
        {
            icon: require("../../assets/img/football-icon.svg"),
            label: "Football"
        },
        {
            icon: require("../../assets/img/bowling-icon.svg"),
            label: "Bowling"
        },
        {
            icon: require("../../assets/img/cycling-icon.svg"),
            label: "Cycling"
        },
        {
            icon: require("../../assets/img/dance-icon.svg"),
            label: "Dance"
        },
        {
            icon: require("../../assets/img/elite-Fitness-icon.svg"),
            label: "Elite Fitness"
        },


    ]

    const handleSelect = (value) => {
        window.localStorage.setItem("sport", value)
        setSelected(value)
    }

    return (
        <div className={classNames("sports-sidebar", { "is-open": isOpen })}>
            <div className="sports-sidebar__header">
                <div className="row-group gap--xl">
                    <button className="btn" onClick={onClose}>
                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_7290_251081)">
                                <path d="M17.1875 4.8125L4.8125 17.1875" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M17.1875 17.1875L4.8125 4.8125" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                            </g>
                            <defs>
                                <clipPath id="clip0_7290_251081">
                                    <rect width="22" height="22" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    </button>
                    <Link
                        className="logo"
                        to="/"
                    >
                        <img
                            alt="logo"
                            className="logo-black"
                            src={LogoDark}
                        />
                    </Link>
                </div>
            </div>
            <div className="sports-sidebar__body">
                <h6 className="sports-sidebar__label">More sports:</h6>
                <ul className="sports-sidebar__list">
                    {
                        sports.map((item, index) => (
                            <li key={index} className={classNames({ active: selected === item.label })}>
                                <button className="btn" onClick={() => handleSelect(item.label)}>
                                    <div className="ico">
                                        <img src={item.icon.default} alt="" />
                                    </div>
                                    {item.label}
                                </button>
                            </li>
                        ))
                    }
                </ul>
            </div>
        </div>
    )
}
