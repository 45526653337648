import { Country } from "country-state-city";
import {
    useEffect
} from "react";
import {
    useDispatch, useSelector
} from "react-redux";
import { Link, useParams } from "react-router-dom";
import { ReactComponent as ArrowIcon } from "../../../assets/img/arrow-icon.svg";
import BreadCrumds from "../../../components/parts/BreadCrumds";
import SeminarsLockedAt from "../../../components/parts/SeminarsLockedAt";
import PageLoader from "../../../components/ui/PageLoader";
import { getFullLocation } from "../../../helpers/utils";
import { getSeminarDetailes } from "../../../redux/slices/seminarsSlice";
import { getHistoryFetch } from "../../../redux/thunks/historyThunks";
import { getSeminarsById } from "../../../redux/thunks/seminarsThunks";
import GalleryImages from "../parts/GalleryImages";
import SeminarSingleDefaultAsside from "../parts/SeminarSingleDefaultAsside";
import SeminarSinglePendingAsside from "../parts/SeminarSinglePendingAsside";
import SinglaPageSummary from "../parts/SinglaPageSummary";
import SinglePageInstructorsBlock from "../parts/SinglePageInstructorsBlock";
import SinglePageMapBlock from "../parts/SinglePageMapBlock";
import SinglePageTeachSyles from "../parts/SinglePageTeachSyles";
import SinglePageTitleBlock from "../parts/SinglePageTitleBlock";
import { SinglePhotoSwiper } from "../parts/SinglePhotoSwiper";


const SeminarSingle = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const pageData = useSelector(getSeminarDetailes);
    const { seminars, isLoading } = useSelector((state) => state.seminars);
    const { history, isLoading: isHistoryLoading } = useSelector((state) => state.history);

    useEffect(() => {
        dispatch(getSeminarsById(id));
        // dispatch(getAllSeminarsWithFiltersFetch())
        dispatch(getHistoryFetch("seminar"));
    }, [dispatch, id]);

    if (isLoading) return <PageLoader />;

    const seminarsLookedAt = history?.filter((item) => +item?.seminar?.id !== +id) || [];

    

    return (
        <>
            <section className="pt-28 pb-42 custom-seminar-single custom-seminar-show-swiper-block">
                <div className="container">
                    <BreadCrumds
                        breadcrumbs={[
                            {
                                label: "Seminars",
                                link: "/seminars"
                            }, { label: String(pageData?.seminarName).toLocaleLowerCase() }
                        ]}
                    />
                </div>
            </section>

            <div className="custom-seminar-show-swiper-block">
                {pageData?.photos && pageData?.photos.length > 0 && (
                    <SinglePhotoSwiper gallery={pageData?.photos} />
                )}
            </div>

            <section className="pt-28 pb-42 custom-seminar-single">
                <div className="container">
                    <div className="custom-seminar-mobile-hide-block">
                        <Link to='/seminars'><ArrowIcon className="mb-28" /></Link>
                        <BreadCrumds
                            breadcrumbs={[
                                {
                                    label: "Seminars",
                                    link: "/seminars"
                                }, { label: String(pageData?.seminarName).toLocaleLowerCase() }
                            ]}
                        />
                    </div>

                    <div className="flex flex-col">

                        <SinglePageTitleBlock
                            allowUnder18={pageData?.allowUnder18}
                            location={getFullLocation([Country.getCountryByCode(pageData?.location)?.name, pageData?.address])}
                            status={pageData?.status}
                            title={pageData?.seminarName}
                            traningStyle={pageData?.traningStyle}
                        />

                        {pageData?.photos && pageData?.photos.length > 1 && (
                            <div className="custom-seminar-mobile-hide-block">
                                <GalleryImages
                                    gallery={pageData?.photos}
                                    coverId={pageData?.coverId}

                                />
                            </div>
                        )}

                    </div>

                </div>
            </section>

            <section className="pb-42">
                <div className="container">
                    <div className="border-bottom pb-42">
                        <div className="row gx-80">
                            <div className="col-lg-7 single-sem-first">
                                {pageData?.photos && pageData?.photos.length <= 1 && (
                                    <div className="custom-seminar-mobile-hide-block pb-42">
                                        <GalleryImages
                                            wrapperClasses={"grid-imgs--single"}
                                            gallery={pageData?.photos}
                                            coverId={pageData?.coverId}
                                        />
                                    </div>
                                )}
                                {pageData?.teachingStyles &&
                                    <SinglePageTeachSyles teachingStyles={pageData?.teachingStyles} />}

                                <SinglaPageSummary summary={pageData?.summary} />

                                {
                                    pageData?.status === "pending" &&
                                    pageData?.user?.role === "academy" &&
                                    <SinglePageMapBlock />
                                }

                                {pageData?.user?.role !== "academy" &&
                                    <SinglePageInstructorsBlock data={pageData?.instructors} />}

                            </div>

                            <div className="col-lg-5 single-sem-second sticky-block">
                                {pageData?.status === "pending"
                                    ? <SeminarSinglePendingAsside pageData={pageData} />
                                    : <SeminarSingleDefaultAsside pageData={pageData} />}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {
                !isHistoryLoading && (
                    <SeminarsLockedAt
                        data={seminarsLookedAt}
                        sectionTitle="Seminars you looked at"
                    />
                )}
        </>
    );
};

export default SeminarSingle;
