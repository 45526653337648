import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { setAuthModalOpen } from "../../redux/slices/applicationSlice";
import { AuthModals } from "../modals/singUpModals/AuthProvider";
import Accordeon from "../ui/accordeons/Accordeon";
import Button from "../ui/buttons/Button";

const HowPlatformWork = ({ pageData }) => {
    const howPlanformWork = [
        {
            title: pageData?.blocks?.[1]?.blockPresets?.[3]?.value || "For instructors",
            // description: pageData?.blocks?.[1]?.blockPresets?.[4]?.value || 'Submit a request to your chosen instructor to organize a personalized sports seminar. Customize the seminar options and design an engaging cover for the upcoming event. Manage ticket sales for your seminar and extend invitations to students for your bespoke Jiu-Jitsu seminar.',
            description: <>
                <ul style={{ marginLeft: 30 }}>
                    <li style={{ listStyle: "outside" }}>
                        <span style={{ fontWeight: "bold" }}>
                            Plan and Publish:
                        </span>

                        {" "}

                        Plan your seminar/camp, and share it for academies to sign up. Connect with your desired academy and efficiently manage ticket sales for your event.
                    </li>

                    <li style={{ listStyle: "outside" }}>
                        <span style={{ fontWeight: "bold" }}>
                            Plan a tour:
                        </span>

                        {" "}

                        Manage multiple different seminars in various locations in one place. Academies from around the world can view your availability and sign up for your seminars.
                    </li>

                    <li style={{ listStyle: "outside" }}>
                        <span style={{ fontWeight: "bold" }}>
                            Private Lessons:
                        </span>

                        {" "}

                        Offer students the opportunity to request private lessons directly from you, whether at your home gym or during your seminars, depending on your availability.
                    </li>
                </ul>

                <p
                    style={{
                        fontWeight: "bold",
                        marginTop: 20
                    }}
                >
                    Steps:
                </p>

                <ol style={{ marginLeft: 30 }}>
                    <li>
                        Create Your Profile
                    </li>

                    <li>
                        Plan or accept Seminar/Camp
                    </li>

                    <li>
                        Match with academy
                    </li>

                    <li>
                        Publish and promote the seminar
                    </li>

                    <li>
                        Keep track of tickets sales, earnings, and much more
                    </li>
                </ol>
            </>,
            isActive: true
        }, {
            title: pageData?.blocks?.[1]?.blockPresets?.[1]?.value || "For BJJ Academies",
            // description: pageData?.blocks?.[1]?.blockPresets?.[2]?.value || 'Submit a request to your chosen instructor to organize a personalized sports seminar. Customize the seminar options and design an engaging cover for the upcoming event. Manage ticket sales for your seminar and extend invitations to students for your bespoke Jiu-Jitsu seminar.',
            description: <>
                <ul style={{ marginLeft: 30 }}>
                    <li style={{ listStyle: "outside" }}>
                        <span style={{ fontWeight: "bold" }}>
                            Plan and Publish:
                        </span>

                        {" "}

                        Plan your own seminar/camp as you see fit, then share it for instructors to sign up. Connect with your desired instructor and efficiently manage ticket sales for your event.
                    </li>

                    <li style={{ listStyle: "outside" }}>
                        <span style={{ fontWeight: "bold" }}>
                            Stay Informed:
                        </span>

                        {" "}

                        Turn on notifications for your favourite fighter to know exactly where and when he/she is available.
                    </li>
                </ul>

                <p
                    style={{
                        fontWeight: "bold",
                        marginTop: 20
                    }}
                >
                    Steps:
                </p>

                <ol style={{ marginLeft: 30 }}>
                    <li>
                        Create Your Profile
                    </li>

                    <li>
                        Plan or accept Seminar/Camp
                    </li>

                    <li>
                        Match with Instructor
                    </li>

                    <li>
                        Publish and promote the seminar
                    </li>

                    <li>
                        Keep track of tickets sales, earnings, and much more
                    </li>
                </ol>
            </>,
            isActive: false
        }, {
            title: pageData?.blocks?.[1]?.blockPresets?.[5]?.value || "For Students",
            // description: pageData?.blocks?.[1]?.blockPresets?.[6]?.value || 'Submit a request to your chosen instructor to organize a personalized sports seminar. Customize the seminar options and design an engaging cover for the upcoming event. Manage ticket sales for your seminar and extend invitations to students for your bespoke Jiu-Jitsu seminar.',
            description: <>
                <ul style={{ marginLeft: 30 }}>
                    <li style={{ listStyle: "outside" }}>
                        <span style={{ fontWeight: "bold" }}>
                            Browse and Book:
                        </span>

                        {" "}

                        Discover upcoming seminars, view detailed information, and secure your spot with a few clicks.
                    </li>

                    <li style={{ listStyle: "outside" }}>
                        <span style={{ fontWeight: "bold" }}>
                            Payment and Confirmation:
                        </span>

                        {" "}

                        Reserve your place by entering your credit card details. Once the minimum attendance is met, you’ll receive a seminar ticket confirming your participation.
                    </li>

                    <li style={{ listStyle: "outside" }}>
                        <span style={{ fontWeight: "bold" }}>
                            Private Lessons:
                        </span>

                        {" "}

                        Want more personalized instruction? Book private lessons with the instructor, either during the seminar or at their home gym.
                    </li>
                </ul>

                <p
                    style={{
                        fontWeight: "bold",
                        marginTop: 20
                    }}
                >
                    Steps:
                </p>

                <ol style={{ marginLeft: 30 }}>
                    <li>
                        Browse through upcoming seminars.
                    </li>

                    <li>
                        Select and reserve your spot in your desired seminar.
                    </li>

                    <li>
                        Once the minimum attendance is reached, your seminar is confirmed and you'll receive your ticket.
                    </li>

                </ol>
            </>,
            isActive: false
        }
    ];
    const dispatch = useDispatch();
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index
            ? null
            : index);
    };

    return (
        <section className="section">
            <div className="container">
                <h2
                    className="heading mb-100 text-center max-md-mb-40"
                    dangerouslySetInnerHTML={{ __html: pageData?.blocks?.[1]?.blockPresets?.[0]?.value || "HOW Platform WORKS" }}
                />

                {/* <p className='accordeon-head-title mb-36'>
                BJJ academies and instructors can easily plan and promote their seminars and camps through the platform. Whether you're an academy looking to host a top-tier instructor or an instructor aiming to share your expertise with eager students, Plan Seminars makes it happen. Here's how it works:
                </p> */}
            </div>

            <div className="how-platform-row">
                <div className="how-platform-row__faq-block">
                    <div className="container">
                        <div className="how-platform-row__faq-block-body">
                            {howPlanformWork.map((item, index) => (
                                <Accordeon
                                    className="w-full"
                                    index={index}
                                    isActive={activeIndex === index}
                                    key={index}
                                    onClick={() => toggleAccordion(index)}
                                    onlyOneIsOpen={true}
                                    title={item.title}
                                >
                                    <div className="mb-36">
                                        {item.description}
                                    </div>

                                    <Button
                                        onClick={() => dispatch(setAuthModalOpen(AuthModals.singUp))}
                                        className="btn btn--primary btn--md"
                                    >
                                        <span className="info">
                                            Create Profile
                                        </span>

                                        <span className="ico ico-14">
                                            <svg
                                                width="19"
                                                height="18"
                                                viewBox="0 0 19 18"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M7.25 3.375L12.875 9L7.25 14.625"
                                                    stroke="currentColor"
                                                    strokeWidth="2"
                                                    strokeLinecap="square"
                                                >
                                                </path>
                                            </svg>
                                        </span>
                                    </Button>

                                </Accordeon>
                            )



                            )}
                        </div>
                    </div>
                </div>

                <div className="how-platform-row-img">
                    <div className="how-platform-img h-full">
                        <div className="platform-bg-effect" />

                        <img
                            src={pageData?.blocks?.[1]?.blockPresets?.[7]?.value
                                ? process.env.REACT_APP_API_URL + pageData?.blocks?.[1]?.blockPresets?.[7]?.value
                                : require("../../assets/img/how-platform-squeeze.jpg")}
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HowPlatformWork;
