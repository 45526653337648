
import classNames from "classnames";
import { useState } from "react";
import {
    useDispatch, useSelector
} from "react-redux";
import { Link, useParams } from "react-router-dom";
import { ReactComponent as CalendarIcon } from "../../../assets/img/calendar.svg";
import { ReactComponent as DotsIcon } from "../../../assets/img/icon-dots.svg";
import RequestRefundPrivateLessonModal from "../../../components/modals/RequestRefundPrivateLessonModal";
import ReschedulePrivateLessonModal from "../../../components/modals/ReschedulePrivateLessonModal";
import Button from "../../../components/ui/buttons/Button";
import DropDown from "../../../components/ui/DropDown";
import { getUser } from "../../../redux/slices/applicationSlice";
import { getChatByIdFetch } from "../../../redux/thunks/chatsThunks";
import { acceptRequestsPrivateLessonsFetch, acceptRequestsPrivateLessonsStepFetch, rejectRequestsPrivateLessonFetch } from "../../../redux/thunks/privateLessonsThunks";
import { PLConfirmUsers } from "./PrivateLessonParts/PLConfirmUsers";
import { PLStepOne } from "./PrivateLessonParts/PLStepOne";

const SidebarHeader = ({ status, statusMap, users = [], accepts = [] }) => {
    return (
        <>
            <div className="row-group gap--sm pb-24 border-bottom">
                <span className="seminar__status">
                    Status:
                </span>

                <span
                    className={classNames("seminar__dot", {
                        "--done": ["done", "paid"].includes(status),
                        "--rejected": status === "rejected",
                    })}
                />

                {statusMap[status] && (
                    <span className="chat-item__name">
                        {statusMap[status].label}
                    </span>
                )}
            </div>
        </>
    );
};

const DropDownActions = ({ data }) => {
    const [isRefundModalOpen, setIsRefundModalOpen] = useState(false);
    const privateLesson = data?.privateLesson

    return (
        <>
            <DropDown
                dropDownClasses="ml-auto card-actions"
                dropDownListClasses="dropdown-menu"
                labelButton={(
                    <Button
                        className="btn btn--square btn--md radius"
                    >
                        <DotsIcon />
                    </Button>
                )}
            >
                <div className="dropdown-menu__body--item">
                    <Link
                        className="btn btn--sm btn--thin active"
                        to={`/instructors/${privateLesson?.instructorId}`}
                    >
                        Instructor Profile
                    </Link>
                    {
                        privateLesson?.eventId && (
                            <Link
                                className="btn btn--sm btn--thin active"
                                to={`/${privateLesson?.eventType === "plancamp" ? "camps" : "seminars"}/${privateLesson?.eventId}`}
                            >
                                Seminar Details
                            </Link>
                        )
                    }
                    {
                        privateLesson.status === "paid" && (
                            <Button
                                className="btn btn--sm btn--thin active"
                                onClick={() => setIsRefundModalOpen(true)}
                            >
                                Request a Refund
                            </Button>
                        )
                    }
                </div>
            </DropDown>
            <>
                {
                    isRefundModalOpen && (
                        <RequestRefundPrivateLessonModal
                            data={data}
                            open={isRefundModalOpen}
                            handleClose={() => setIsRefundModalOpen(false)}
                        />
                    )
                }
            </>
        </>
    )
}

export const ChatSidebarPrivateLesson = ({ chatData, academyAccept }) => {
    const dispatch = useDispatch();
    const [openRescheduleModal, setOpenRescheduleModal] = useState(false);
    const statuses = [
        "pending",
        "discussing_details",
        "confirm-step",
        "paid",
        "canceled",
        "accepted"
    ];
    const { chatId } = useParams();
    const user = useSelector(getUser);
    const privateLesson = chatData.privateLesson;
    const status = privateLesson?.status;

    const [isLoading, setIsLoading] = useState(false)

    //accept step
    const handleAccept = async () => {
        const res = await dispatch(acceptRequestsPrivateLessonsStepFetch(privateLesson?.id)).unwrap();

        // console.log('handleAccept', res)
        if (res?.success) {
            // dispatch(getChatByIdFetch({ chatId: chatData?.id }));
        }
    };

    //accept student request
    const handleApprove = async () => {
        setIsLoading(true)
        const res = await dispatch(acceptRequestsPrivateLessonsFetch(privateLesson?.id)).unwrap();

        if (res?.success) {
            dispatch(getChatByIdFetch({ chatId: chatData?.id }));
        }

        setIsLoading(false)
    };

    const handleReject = async (id) => {
        setIsLoading(true)
        const res = await dispatch(rejectRequestsPrivateLessonFetch(privateLesson.id)).unwrap();

        if (res?.success) {
            dispatch(getChatByIdFetch({ chatId: chatData?.id }));
        }

        setIsLoading(false)
    };

    const handlePay = async () => {
        if (!privateLesson?.invoice?.session?.url) return

        setIsLoading(true)
        window.location.href = privateLesson.invoice.session.url
        setTimeout(() => setIsLoading(false), 200)
    };

    const isAccepted = (user?.role === "student" && privateLesson?.isConfirmedStudent) ||
        (user?.role === "instructor" && privateLesson?.isConfirmedInstructor);

    const statusMap = {
        rejected: { label: "Request Rejected" },
        canceled: { label: "Request Canceled" },
        pending: {
            label: "Pending Request",
            actions: user?.role === "instructor" && (
                <div className="row-group gap--xs">
                    <button onClick={handleReject} className="btn btn--sm btn--default flex-1 fs-14">
                        Reject
                    </button>

                    <button
                        className="btn btn--sm btn--primary fs-14"
                        onClick={handleApprove}
                    >
                        Accept

                        Request
                    </button>
                </div>
            )
        },
        discussing_details: {
            label: "Discussing Lesson Details",
            actions: (!isAccepted && (
                <div className="flex gap-8 ">
                    <Button
                        isLoading={isLoading}
                        className="btn btn--sm btn--default fs-14"
                        onClick={() => setOpenRescheduleModal(true)}
                    >
                        <span className="ico reschedule-invert-color">
                            <CalendarIcon />
                        </span>

                        RESCHEDULE
                    </Button>

                    <Button
                        isLoading={isLoading}
                        className="btn btn--sm btn--primary flex-1 fs-14"
                        onClick={handleAccept}
                    >
                        Accept

                        Details
                    </Button>
                </div>
            ))
        },
        "confirm-step": {
            label: "Confirmation",
            actions: (
                !isAccepted && (
                    <Button
                        isLoading={isLoading}
                        className="btn btn--sm btn--primary flex-1 fs-14"
                        onClick={handleAccept}
                    >
                        Confirm
                    </Button>
                )
            )
        },
        accepted: {
            label: "Confirmed",
            actions: user?.role === "student" && (
                <Button
                    isLoading={isLoading}
                    className="btn btn--sm btn--primary flex-1 fs-14"
                    onClick={handlePay}
                >
                    Pay
                </Button>
            )
        },
        paid: {
            label: "Paid",
            actions: null
        },
        in_progress: {
            label: "In progress",
            actions: null
        },
        archived: {
            label: "Ended",
            actions: null
        },

    };

    return (
        <>
            <aside className="chat-sidebar" >
                <div className="chat-sidebar__header">
                    <h6 className="heading">
                        Private Lesson Info
                    </h6>
                    {
                        user?.role === "student" && (
                            <DropDownActions data={chatData} />
                        )
                    }
                </div>

                <div className="chat-sidebar__body">
                    <SidebarHeader
                        accepts={academyAccept?.accepts?.map((item) => item.userId)}
                        privateLesson={privateLesson}
                        status={status}
                        statusMap={statusMap}
                        users={chatData?.users}
                    />

                    <PLStepOne
                        currentStatus={status}
                        data={privateLesson}
                        status={["location", "pending", "rejected"]}
                        useStep={status === "confirm-step"}
                        statuses={statuses}
                    />

                    {["confirm-step", "accepted"].includes(status) && (
                        <PLConfirmUsers
                            users={chatData?.users}
                            isConfirmedInstructor={privateLesson.isConfirmedInstructor}
                            isConfirmedStudent={privateLesson.isConfirmedStudent}
                        />
                    )}

                    <div className="chat-sidebar__footer">
                        {statusMap[status]?.actions}
                    </div>
                </div>
            </aside>

            <ReschedulePrivateLessonModal
                data={privateLesson.dates}
                handleClose={() => setOpenRescheduleModal(false)}
                id={privateLesson.id}
                onSubmit={() => dispatch(getChatByIdFetch({ chatId: chatData?.id }))}
                open={openRescheduleModal}
            />
        </>
    );
};
