import { useState } from "react";
import VideoPlayer from "../../../components/parts/cards/VideoPlayer";

const SinglaPageSummary = ({ summary, videos = false }) => {
    const [showMore, setShowMore] = useState(false);

    return (
        <div className="border-bottom mb-42 pb-42">
            <h4 className="heading mb-32">
                Summary
            </h4>

            <div
                className={`text-description summary-description ${showMore
                    ? "show-all-text"
                    : ""}`}
            >
                {summary}
            </div>

            {summary && summary.length > 612
                ? (
                    <button
                        type="button"
                        onClick={() => {
                            setShowMore((prev) => !prev);
                        }}
                        className="fw-500 text--lg underline mt-28"
                    >
                        {showMore
                            ? "Show Less"
                            : "Show More"}
                    </button>
                )


                : null}

            {videos && videos.map((video) => (
                <div
                    className="mt-42"
                    key={video.id}
                >
                    <VideoPlayer url={video?.url} />
                </div>
            )



            )}
        </div>
    );
};

export default SinglaPageSummary;
