const base = "api/events";

export const events = (instance) => ({
    async cancelEvent({ eventId, eventType, body }) {
        const { data } = await instance.post(`${base}/cancel/${eventId}/${eventType}`, body);

        return data;
    },
    async unacceptStep({ eventId, eventType, body }) {
        const { data } = await instance.post(`${base}/undo-step/${eventId}/${eventType}`, body);

        return data;
    },

});
