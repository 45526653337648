const base = "api/tickets";

export const tickets = (instance) => ({
    async buyTicket(body) {
        const { data } = await instance.post(`${base}`, body);

        return data;
    },
    async getTicketByPublicHash(hash) {
        const { data } = await instance.get(`${base}/pub/${hash}`);

        return data;
    },
    async getTicketByEventTypeAndEventId({ eventId, eventType, query = "" }) {
        const { data } = await instance.get(`${base}/event/${eventId}/${eventType}?${query}`);

        return data;
    },
    async getMyTicketInfoById(id) {
        const { data } = await instance.get(`${base}/${id}`);

        return data;
    },
    async getAllMyTickets(query = "") {
        const { data } = await instance.get(`${base}/my?${query}`);

        return data;
    },
    async returnTicket(ticketId) {
        const { data } = await instance.get(`${base}/return-my-ticket/${ticketId}`);

        return data;
    },

});
