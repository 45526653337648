import classNames from "classnames";
import {
    format, isToday
} from "date-fns";
import FileImg from "../../../assets/img/file-placeholder-svg.svg";
import profilePlaceholder from "../../../assets/img/user-profile.svg";
import { getImageSrc } from "../../../helpers/utils";

const Message = ({ data, user, recipient }) => {
    const download = (fileName, fileUrl, mimeType) => {
        fetch(fileUrl)
            .then((response) => response.blob())
            .then((blob) => {
                const url = URL.createObjectURL(blob);
                const link = document.createElement("a");

                link.href = url;
                link.download = fileName;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(url);
            })
            .catch((error) => console.error("Download error:", error));
    };

    const handleDownloadClick = (e, fileName, fileUrl, mimeType) => {
        e.preventDefault();
        download(fileName, fileUrl, mimeType);
    };

    if (data.isSystemMessage && !data.userId) {
        return (
            <div className="system-message">
                <p className="color-text text--xs">
                    {data.message}
                </p>
            </div>
        );
    }

    return (
        <div
            className={classNames("message", user.id === data.userId
                ? "message--outcome"
                : "message--income")}
        >
            {user.id !== data.userId && (
                <div className="message__image current-chat__image">
                    <img
                        alt={recipient?.fullName}
                        className="ico ico--sm"
                        src={getImageSrc(recipient?.photo, profilePlaceholder)}
                    />
                </div>
            )}

            <div className="message__body">
                <div className="message__cloud">
                    <p className={classNames("message__cloud__message", { italic: data.isSystemMessage })}>
                        {!!data?.attachments?.length && (
                            <div>
                                {data.attachments.map((el, idx) => {
                                    if (el.mimetype.includes("image")) {
                                        return (
                                            <p
                                                key={idx}
                                                style={{ paddingBottom: "5px" }}
                                            >
                                                <img
                                                    style={{
                                                        maxWidth: "280px",
                                                        maxHeight: "280px"
                                                    }}
                                                    src={getImageSrc(el.url.slice(1))}
                                                    alt={el.filename}
                                                />
                                            </p>
                                        );
                                    }

                                    if (el.mimetype.includes("application")) {
                                        return (
                                            <p
                                                key={idx}
                                                style={{ paddingBottom: "5px" }}
                                            >
                                                <span
                                                    className={"file-show-link"}
                                                    onClick={(e) => handleDownloadClick(e, el.filename, getImageSrc(el.url.slice(1)), el.mimeType)}
                                                >
                                                    <img
                                                        src={FileImg}
                                                        alt={""}
                                                    />
                                                    {el.filename}
                                                </span>

                                            </p>
                                        );
                                    }
                                })}

                            </div>
                        )}

                        {data.message}
                    </p>
                </div>

                <div className="message__info">
                    {
                        user.id !== data.userId
                            ? (
                                <span>
                                    {recipient?.fullName}
                                </span>
                            )


                            : (
                                <span>
                                    {user?.profile?.fullName}
                                </span>
                            )


                    }

                    <span>
                        {format(data?.createdAt || new Date(), isToday(data?.createdAt || new Date())
                            ? "HH:mm"
                            : "E")}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default Message;
