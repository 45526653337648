export const CardDetails = ({ isEdited, label, afterLabel, children }) => {
    return (
        <div className="form-group input--lg">
            <label className="label row-group justify-between gap--xs">
                <div className="row-group gap--xs">
                    {label}

                    {
                        isEdited
                            ? (
                                <span className="flex">
                                    <svg
                                        fill="none"
                                        height="7"
                                        viewBox="0 0 6 7"
                                        width="6"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <rect
                                            fill="#9747FF"
                                            height="6"
                                            rx="3"
                                            width="6"
                                            y="0.5"
                                        />
                                    </svg>
                                </span>
                            )


                            : null
                    }
                </div>

                {afterLabel}
            </label>

            <div
                className={`card-details col-group ${isEdited
                    ? "--edited"
                    : ""}`}
            >
                {children}
            </div>
        </div>
    );
};
