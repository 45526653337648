import moment from "moment";
import {
    useEffect, useState
} from "react";
import {
    useDispatch, useSelector
} from "react-redux";
import {
    Link, useNavigate, useParams
} from "react-router-dom";
import { toast } from "react-toastify";
import CheckboxSwitch from "../../../components/ui/formElements/CheckboxSwitch";
import PageLoader from "../../../components/ui/PageLoader";
import {
    getDateRange, getImageSrc
} from "../../../helpers/utils";
import { getUser } from "../../../redux/slices/applicationSlice";
import { getChatByIdFetch } from "../../../redux/thunks/chatsThunks";
import {
    acceptSeminarRequestFetch, getSeminarsById
} from "../../../redux/thunks/seminarsThunks";
import { Step } from "../parts/Step";

export const CardDetails = ({ isEdited, label, children }) => {
    return (
        <div className="form-group input--lg">
            <label className="label flex items-center gap--xs">
                {label}

                {
                    isEdited
                        ? (
                            <span className="flex">
                                <svg
                                    fill="none"
                                    height="7"
                                    viewBox="0 0 6 7"
                                    width="6"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <rect
                                        fill="#9747FF"
                                        height="6"
                                        rx="3"
                                        width="6"
                                        y="0.5"
                                    />
                                </svg>
                            </span>
                        )


                        : null
                }

            </label>

            <div className="col-group gap--xs text--sm">
                {Array.isArray(children) && children.length || !Array.isArray(children) && children
                    ? children
                    : "Not specified"}
            </div>
        </div>
    );
};

const SeminarSummary = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [agree, setAgree] = useState({
        booking: false,
        terms: false
    });
    const { seminarId, chatId, requestId } = useParams();
    const user = useSelector(getUser);
    const { currentChatData, isLoading } = useSelector((state) => state.chats);

    const seminarDetails = currentChatData?.seminar

    useEffect(() => {
        if (!chatId) return;

        dispatch(getChatByIdFetch({ chatId }));
    }, [chatId]);

    const changeAgree = (type) => {
        setAgree((prev) => ({
            ...prev,
            [type]: !prev[type]
        }));
    };

    const handleSubmit = async () => {
        if (Object.values(agree).some((value) => !value)) {
            toast.warn("You must agree to the terms");

            return;
        }

        const res = await dispatch(acceptSeminarRequestFetch({
            seminarId: seminarDetails.id,
            requestId
        })).unwrap();

        if (res) {
            navigate(`/chat/${chatId}`);
        }
    };

    const preview = seminarDetails?.user?.role === "academy"
        ? `/planed-by-academy/${seminarId}`
        : `/planed-by-insctructor/${seminarId}`;

    return (
        <section className="section-42">
            <div className="container">
                {
                    !isLoading && seminarDetails
                        ? (
                            <div className="double-section flex gap-80">
                                <div className="double-section__content">
                                    <h3 className="heading">
                                        Seminar Summary
                                    </h3>

                                    <div className="row-group gap--xxl">
                                        <div className="image-wrapper --small-thumb">
                                            <img
                                                alt={seminarDetails?.seminarName}
                                                src={getImageSrc(seminarDetails?.photos?.find((photo) => photo.id === seminarDetails.coverId)?.url)}
                                            />
                                        </div>

                                        <div className="col-group flex-auto gap--lg justify-between">
                                            <p className="text--xs color-text">
                                                {getDateRange(seminarDetails?.seminarDates)}
                                            </p>

                                            <div className="seminar-label-info">
                                                <h6 className="heading">
                                                    {seminarDetails?.seminarName}
                                                </h6>
                                            </div>
                                        </div>

                                        <Link
                                            className="btn btn--default btn--sm"
                                            to={preview}
                                        >
                                            Preview
                                        </Link>
                                    </div>

                                    <div className="col-group">
                                        <Step
                                            href="./../"
                                            title="Location & Date"
                                        >
                                            <div className="row gx-16 py-24 mb-10">
                                                <div className="col-lg-6">
                                                    <CardDetails label="Country">
                                                        <p>
                                                            {seminarDetails.location}
                                                        </p>
                                                    </CardDetails>
                                                </div>

                                                <div className="col-lg-6">
                                                    <CardDetails label="Address">
                                                        <p>
                                                            {seminarDetails.address}
                                                        </p>
                                                    </CardDetails>
                                                </div>

                                                <div className="col-lg-6">
                                                    <CardDetails label="Dates & time">
                                                        {seminarDetails?.seminarDates?.map((date) => (
                                                            <p>
                                                                {moment(date.date).format("DD MMM, YYYY")}
                                                                ,{" "}

                                                                {date.startTime}

                                                                {" "}
                                                                -{date.endTime}
                                                            </p>
                                                        ))}
                                                    </CardDetails>
                                                </div>

                                                <div className="col-lg-6">
                                                    <CardDetails label="Private lessons Time">
                                                        {seminarDetails?.privateSessionDates?.map((date) => (
                                                            <p>
                                                                {moment(date.date).format("DD MMM, YYYY")}
                                                                ,{" "}

                                                                {date.startTime}

                                                                {" "}
                                                                -{date.endTime}
                                                            </p>
                                                        ))}
                                                    </CardDetails>
                                                </div>
                                            </div>
                                        </Step>

                                        <Step
                                            href="./../seminar-info"
                                            title="Seminar Details"
                                        >
                                            <div className="row gx-16 py-24 mb-10">
                                                <div className="col-12">
                                                    <CardDetails label="Teaching Styles">
                                                        {
                                                            seminarDetails.teachingStyles.map((item) => item)
                                                        }
                                                    </CardDetails>
                                                </div>

                                                <div className="col-lg-4 col-md-6">
                                                    <CardDetails label="Language">
                                                        {
                                                            seminarDetails.languages.map((item) => item)
                                                        }
                                                    </CardDetails>
                                                </div>

                                                <div className="col-lg-4 col-md-6">
                                                    <CardDetails label="Gi or NoGi">
                                                        {
                                                            seminarDetails.traningStyle
                                                        }
                                                    </CardDetails>
                                                </div>

                                                <div className="col-lg-4 col-md-6">
                                                    <CardDetails label="Max Gym Capacity">
                                                        {
                                                            seminarDetails.maxGymCapacity
                                                        }
                                                    </CardDetails>
                                                </div>

                                                <div className="col-lg-4 col-md-6">
                                                    <CardDetails label="Open to">
                                                        {
                                                            seminarDetails.targetAudience
                                                        }
                                                    </CardDetails>
                                                </div>

                                                <div className="col-lg-4 col-md-6">
                                                    <CardDetails label="Under the Age of 18">
                                                        {
                                                            seminarDetails.allowUnder18
                                                                ? "Allowed"
                                                                : "Not Allowed"
                                                        }
                                                    </CardDetails>
                                                </div>

                                                <div className="col-lg-4 col-md-6">
                                                    <CardDetails label="Min Attendance">
                                                        {
                                                            seminarDetails.minAttendance
                                                        }
                                                    </CardDetails>
                                                </div>

                                                <div className="col-lg-4 col-md-6">
                                                    <CardDetails label="Min Attendance Deadline">
                                                        {
                                                            seminarDetails.minAttendanceDeadline
                                                        }
                                                    </CardDetails>
                                                </div>
                                            </div>
                                        </Step>

                                        <Step
                                            href="./../design-text"
                                            title="Design & Text"
                                        >
                                            {
                                                seminarDetails?.photos?.length && (
                                                    <div className="row gutters-form-7">
                                                        {
                                                            seminarDetails?.photos.map((photo) => (
                                                                <div
                                                                    className="col-2"
                                                                    key={photo?.id}
                                                                >
                                                                    <img
                                                                        className="w-full"
                                                                        src={getImageSrc(photo?.url)}
                                                                        style={{ height: 96 }}
                                                                        alt={photo?.originalname}
                                                                    />
                                                                </div>
                                                            )

                                                            )
                                                        }
                                                    </div>
                                                )
                                            }

                                            <CardDetails label="Seminar Name">
                                                <p>
                                                    {seminarDetails?.seminarName}
                                                </p>
                                            </CardDetails>

                                            <CardDetails label="Summary">
                                                <p>
                                                    {seminarDetails?.summary}
                                                </p>
                                            </CardDetails>
                                        </Step>

                                        <Step
                                            href="./../booking"
                                            title="Booking Specific Terms"
                                        >
                                            <div className="terms-doc text--sm">
                                                {currentChatData?.academyAccept?.terms}
                                            </div>
                                        </Step>
                                    </div>

                                    <button
                                        className="btn btn--primary btn--lg"
                                        onClick={handleSubmit}
                                    >
                                        <span className="info">
                                            Confirm
                                        </span>
                                    </button>

                                    <div className="option-group mb-36">
                                        <CheckboxSwitch
                                            checked={agree.terms}

                                            onChange={() => changeAgree("terms")}
                                            text={
                                                <>
                                                    I have read and agreed to the

                                                    {" "}

                                                    <Link
                                                        className="color-secondary"
                                                        to="terms"
                                                    >
                                                        Terms and Conditions
                                                    </Link>

                                                    {" "}

                                                    and

                                                    {" "}

                                                    <Link
                                                        className="color-secondary"
                                                        to="privacy"
                                                    >
                                                        Privacy Policy
                                                    </Link>
                                                </>
                                            }
                                        />

                                        <CheckboxSwitch
                                            checked={agree.booking}
                                            onChange={() => changeAgree("booking")}
                                            text="I accept the Booking Specific Terms (if included)"
                                        />
                                    </div>
                                </div>

                                <div className="double-section__aside">
                                    <div className="card-shadow px-36 py-32 mb-16">
                                        <div className="instructor-profile__group">
                                            <div className="instructor-profile__image-logo">
                                                <img
                                                    alt={user?.profile?.name}
                                                    className="ico ico--md rounded-full"
                                                    src={getImageSrc(user?.profile?.photo)}
                                                />
                                            </div>

                                            <div className="instructor-profile__group-info">
                                                <p className="instructor-profile__name">
                                                    {user?.profile?.name}
                                                </p>

                                                <p className="instructor-profile__location">
                                                    {user?.email}
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card-shadow px-36 py-32">
                                        <h6 className="heading mb-28">
                                            Price Information
                                        </h6>

                                        <div className="ticket-info-body ticket-info-body--no-border">
                                            <div className="ticket-info-body__item justify-between border-bottom pb-28 mb-28">
                                                {
                                                    seminarDetails?.sharedProfit ? (
                                                        <>
                                                            <span className="color-text">
                                                                Shared Profit:
                                                            </span>
                                                            <span className="fw-500">
                                                                {seminarDetails?.sharedProfit}

                                                                {" "}

                                                                /

                                                                {100 - seminarDetails?.sharedProfit}

                                                                %
                                                            </span>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <span className="color-text">
                                                                Price range:
                                                            </span>
                                                            <span className="fw-500">
                                                                ${seminarDetails?.seminarMinPrice}

                                                                {" "}

                                                                -
                                                                {" "}

                                                                ${seminarDetails?.seminarMaxPrice}

                                                            </span>
                                                        </>
                                                    )
                                                }
                                            </div>

                                            <div className="ticket-info-body__item justify-between">
                                                <span className="color-text">
                                                    Instructor Cut:
                                                </span>

                                                <span className="fw-500">
                                                    {
                                                        seminarDetails?.sharedProfit ? (
                                                            <span className="fw-500">
                                                                $ {seminarDetails?.minimalPriceTicket * seminarDetails?.minimumAttendance * seminarDetails?.sharedProfit / 100}
                                                            </span>
                                                        ) : (
                                                            <span className="fw-500">
                                                                $ {seminarDetails?.instructorPrice}
                                                            </span>
                                                        )
                                                    }
                                                </span>
                                            </div>

                                            <div className="ticket-info-body__item justify-between">
                                                <span className="color-text">
                                                    Academy Potential Revenue:
                                                </span>

                                                {
                                                    seminarDetails?.sharedProfit ? (
                                                        <span className="fw-500">
                                                            $ {(seminarDetails?.minimalPriceTicket * seminarDetails?.minimumAttendance) - (seminarDetails?.minimalPriceTicket * seminarDetails?.minimumAttendance * seminarDetails?.sharedProfit / 100)}
                                                        </span>
                                                    ) : (
                                                        <span className="fw-500">
                                                            $ {seminarDetails?.minimalPriceTicket * seminarDetails?.minimumAttendance - seminarDetails?.instructorPrice}
                                                        </span>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )


                        : isLoading
                            ? <PageLoader />
                            : (
                                <h2 className="heading">
                                    Seminar is not found
                                </h2>
                            )


                }
            </div>
        </section>
    );
};

export default SeminarSummary;
