import { useState } from "react";
import ModalTemplate from "./ModalTemplate";

import jj from '../../assets/img/sports/jj.jpg';
import judo from '../../assets/img/sports/judo.jpg';
import karate from '../../assets/img/sports/karate.jpg';
import mma from '../../assets/img/sports/mma.jpg';
import thai from '../../assets/img/sports/thai.jpeg';
import wrestling from '../../assets/img/sports/wrestling.jpg';

const SelectSportModal = () => {
    const [open, setOpen] = useState(window.location.pathname === "/")

    const sports = [
        {
            key: "bjj",
            photo: jj,
            isActive: true
        },
        {
            key: "judo",
            photo: judo,
            isActive: false
        },
        {
            key: "karate",
            photo: karate,
            isActive: false
        },
        {
            key: "mma",
            photo: mma,
            isActive: false
        },
        {
            key: "thai",
            photo: thai,
            isActive: false
        },
        {
            key: "wrestling",
            photo: wrestling,
            isActive: false
        },
    ]

    const handleClose = () => {
        setOpen(false)
    }

    const handleSubmit = (template) => {
        localStorage.setItem("sport", template)
        handleClose && handleClose();
    };

    if(!open) {
        return null
    }

    return (
        <ModalTemplate
            modalSize="modal__dialog--xl"
            onClick={handleClose}
            open={open}
        >
            <div className="modal__body p-0">
                <form className="form">
                    {/* <div className="form-header modal__header">
                        <div className="heading-block">
                            <h3 className="heading">
                                Select Cover Template
                            </h3>

                            <p className="text--md desk-heading">
                                Select one of ready cover templates to use
                            </p>
                        </div>

                        <button
                            className={`btn btn--primary btn--md gap-10 ${!selectedTemplate
                                ? "disabled"
                                : ""}`}
                            onClick={handleSubmit}
                            type="button"
                        >
                            <span className="info">
                                Submit Template
                            </span>

                            <span className="ico ico-18">
                                <svg
                                    fill="none"
                                    height="18"
                                    viewBox="0 0 19 18"
                                    width="19"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g>
                                        <path
                                            d="M7.25 3.375L12.875 9L7.25 14.625"
                                            stroke="currentColor"
                                            strokeLinecap="square"
                                            strokeWidth="2"
                                        />
                                    </g>

                                    <defs>
                                        <clipPath>
                                            <rect
                                                fill="white"
                                                height="18"
                                                transform="translate(0.5)"
                                                width="18"
                                            />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </span>
                        </button>
                    </div> */}

                    <div className="form-body sports-modal modal__body scroll-custom">
                        <div className="row g-16 gutters-form-6">
                            {
                                sports.map((item) => (
                                    <div
                                        className="col-md-4 col-sm-6 col-12 flex justify-center"
                                        key={item.key}
                                        style={{
                                            overflow: "hidden",
                                            pointerEvents: !item.isActive ? "none" : "all",
                                            opacity: !item.isActive ? "0.7" : "1"
                                        }}
                                    >
                                        <div className="sport-card relative" onClick={() => handleSubmit(item.key)}>
                                            <img src={item.photo} alt="" />
                                            <div className="sports-card__text col-group">
                                                <h2 className="heading ttu">{item.key}</h2>
                                                {!item.isActive && <h4 className="heading ttu">Comming Soon</h4>}
                                            </div>
                                        </div>
                                    </div>
                                )



                                )
                            }
                        </div>
                    </div>
                </form>
            </div>
        </ModalTemplate>
    );
};

export default SelectSportModal;
